
const routes = [
	{name: "landing", path: "/"},
	{name: "data", path: "/data", children: [
		{name: "general", path: "/general"},

		{name: "posters", path: "/posters", children: [
			{name: "view", path: "/:slug"}
		]},

		{name: "essays", path: "/essays", children: [
			{name: "view", path: "/:slug"}
		]},
		{name: "images", path: "/images", children: [
			{name: "view", path: "/:name"}
		]}
	]},
	{name: "layout", path: "/layout"},
	{name: "superindex", path: "/index"},

	{name: "info", path: "/info"},

	{name: "critter", path: "/poster", children: [
		{name: "view", path: "/:id"}
	]},

	{name: "landscape", path: "/world", children: [
		{name: "view", path: "/:id"}
	]},

	{name: "infrastructure", path: "/modes", children: [
		{name: "view", path: "/:id"},
		// {name: "viewModal", path: "/:id?test"}
	]},



];

export default routes