import React, {Component, PureComponent} from 'react'
import {toJS} from 'mobx'

import {
	getUrlVars,
	slugify
} from '../../../helpers'




const sizes = [800,1000,1200,1400,1600,1800];


class ResponsiveImage extends PureComponent {
	constructor(props) {
		super(props);

		this.q = props.q || 80;
		this.multiplier = props.multiplier || 1;
	}

	_getSizes() {
		return ""
	}

	_getSrcSet() {
		const {image, className, style} = this.props;
		// const {image, sizes, className, style} = this.props;
		
		const activeSizes = this.props.useSizes ? this.props.sizes : sizes;

		const src = `${slugify(image.fields.title)}`;
		const srcs = activeSizes.map((size) => {
			return `//${window.location.host}/images/archive-images/${src}-${size}.jpg ${size / this.multiplier}w`
		})
		// const src = image.fields.file.url;
		// const srcs = sizes.map((size) => {
		// 	return `https:${src}?&w=${size}&q=${this.q} ${size / this.multiplier}w`
		// });

		return srcs.join(", ")
	}

	render() {
		const {image, className, style} = this.props;
		if (!image) return null

		// const src = image.fields.file.url;
		const src = `${slugify(image.fields.title)}`;

		const srcSet = this._getSrcSet();
		const sizes = this._getSizes();


		return (
			<div className="rsp-img-wrap">
				<img
					onMouseOver={this.props.onMouseOver || null}
					onMouseLeave={this.props.onMouseLeave || null}
					key={src} ref="img" style={style} className={className}
					srcSet={srcSet}
					src={`//${window.location.host}/images/archive-images/${src}-${1000}.jpg`} alt=""/>
				<div className="rsp-img-wrap__overlay"></div>
			</div>
		)
	}
}


export default ResponsiveImage;
