import React from 'react'

import get from 'lodash/get'

const InlineAudio = (props) => {
	const audioSrc = get(props.data, "target.fields.media.fields.file.url")
	const credits = get(props.data, "target.fields.attribution")
	const caption = get(props.data, "target.fields.caption");

	return (
		<div className="poster__body__inline-image-wrap">
			<audio src={audioSrc} controls></audio>
			<div className="poster__body__block__caption">
				<h6 dangerouslySetInnerHTML={{__html: window.marked(credits || "", {renderer: window.markedRenderer})}}></h6>
				<h6 dangerouslySetInnerHTML={{__html: window.marked(caption || "", {renderer: window.markedRenderer})}}></h6>
			</div>
		</div>
	)
}

export default InlineAudio
