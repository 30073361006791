import routerStore from '../stores/RouterStore.js'

// Tell MobX which page we're on
export function mobxRouterPlugin(router) {
  return {
    onTransitionError: (toState) => {
      // TODO handle that.
      if (toState === null) {
         toState = {name: '404'};
      } 

      routerStore.setCurrent(toState)
    },

    onTransitionSuccess: (toState, fromState) => {

      //check if navigating to a new page...
      if (routerStore.current && toState.name === routerStore.current.name && (fromState.params.cd || toState.params.cd)) {
        // if moving to content drawer do it immediately
          // routerStore.fetchRouteData(toState).then(() => {
          //   routerStore.setCurrent(toState)
          // });

          routerStore.fetchRouteData(toState)
          routerStore.setCurrent(toState)

      } else {
        routerStore.startTransition(toState).then(() => {
          routerStore.setCurrent(toState)
        });
      }

    },
  };
}