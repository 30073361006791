import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {CritterCarousel} from '../../../components/slideshow'

@inject("store") @observer
class InfrastructureOtherCritters extends Component {
	render() {
		const {siteDataStore: {posterDataByInfrastructure}, routerStore: {current}} = this.props.store;


		// const landscape = activeCritter.fields.landscape[0].fields.name.toLowerCase();
		return (
			<div className="infrastructure__other-critters">
				<h3 className="">Explore this Tipper</h3>
				<CritterCarousel posters={posterDataByInfrastructure[current.params.id]} location={current.params.id}/>
			</div>
		)
	}
}

export default InfrastructureOtherCritters
