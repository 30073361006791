import React, {Component} from 'react'
import {
	LeftArrow,
	RightArrow
} from './subcomponents'

class Slideshow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeIdx: 0
		}
	}

	_increment = () => {
		let activeIdx = this.state.activeIdx + 1;

		if (activeIdx >= this.props.images.length) {
			activeIdx = 0;
		}

		this.setState({
			activeIdx
		})
	}

	_decrement = () => {
		let activeIdx = this.state.activeIdx - 1;

		if (activeIdx < 0) {
			activeIdx = this.props.images.length - 1;
		}

		this.setState({
			activeIdx
		})
	}

	
	render() {
		const {images} = this.props;
		if (!images) return null

		const {activeIdx} = this.state;
		const imageSrc = images[activeIdx].fields.media.fields.file.url;

		return (
			<div className={`slideshow ${this.props.className || ""}`}>
				<LeftArrow className="slideshow__decrement" onClick={this._decrement}/>
				<img className="slideshow__image" src={imageSrc} alt=""/>
				
				<RightArrow className="slideshow__increment" onClick={this._increment}/>
			</div>
		)
	}
}

export default Slideshow