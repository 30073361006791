import { observable, computed, action } from 'mobx';

import {toJS} from 'mobx'

class UIStore {
	constructor(rootStore) {
		this.rootStore = rootStore;
	}



	//set as a default for now
	@observable activePoster = "";

	@observable transitioning = false;
	@observable transitioning__stage1 = null;
	@observable transitioning__stage2 = null;


	@observable drawer__previewHeight = false;
	@observable drawer__openStage1 = false;
	@observable drawer__openStage2 = false;
	@observable drawer__showReadingRoom= false;
	@observable drawer__activeBodyText = null;
	@observable drawer__activeModalText = null;


	@observable windowWidth = window.innerWidth;
	@observable windowHeight = window.innerHeight;

	registeredPages = {
		landscape: {},
		infrastructure: {},
		poster: {}
	};

	landscapeImageWidths = {
		capital: 3000,
		invasion: 4000,
		empire: 2000,
		acceleration: 4000
	}

	@observable si__activeCritter = null;
	@observable si__activeHeaders = {};

	@observable landscape__activePoster = null;
	@observable landscape__posterLocation = null;
	@observable landscape__activePoster__displayName = null;

	@observable landing__activePoster = null;
	@observable poster__activeFQs =  [];
	@observable poster__activeFQ =  false;
	@observable poster__activeEditorsNotes =  [];
	@observable poster__activeEditorsNote =  false;
	@observable poster__showReferences = false;
	@observable poster__showFQReferences = false;
	@observable poster__referenceScrollLocation = null;
	@observable poster__FQreferenceScrollLocation = null;
	@observable poster__activeModalContent = null;

	@observable poster__activePosterLightbox = false;
	@observable poster__activeLightboxContent = null;

	@observable poster__updatedposition= null;
	@observable poster__allfqs = [];
	@observable poster__clickedfq = null;

	@observable essay__referenceScrollLocation = null;
	essay__contentWrap = null;

	@action updatefqposition(position) {
		this.poster__updatedposition = position;
	}

	@action.bound getallfqs(all) {
		this.poster__allfqs.push(all);
	}

	@action addfqtolist(name) {
		this.poster__clickedfq = name;
	}

	@action checkForDupes(name) {
		const hasDupe = this.poster__allfqs.filter(item => item == name).length;
		if (hasDupe > 3) {
			return name
		}
	}

	@action.bound togglePosterLightbox(caption) {
		this.poster__activePosterLightbox = !this.poster__activePosterLightbox;
		const activeCaption = caption;
		toJS(activeCaption)
		this.poster__activeLightboxContent = activeCaption;
	}

	@action.bound toggleDrawerURL() {
		const routerStore = this.rootStore.routerStore;
		const {params, name} = routerStore.current;

		if (params.cd) {
			delete params.cd;
		} else {
			params.cd = !!!params.cd;
		}

		if (params.cdex) {
			delete params.cdex;
		}

		if (params.text) {
			delete params.text;
		}

		if (params.ttype) {
			delete params.ttype;
		}

		this.rootStore.routerStore.router.navigate(name, params)
	}

	@action.bound handleResize() {
		this.windowWidth = window.innerWidth;
		this.windowHeight = window.innerHeight;
	}

	@action.bound setContentDrawerPreviewHeight(height) {
		this.drawer__previewHeight = height;
	}

	@action.bound toggleDrawer() {
		this.drawer__openStage1 = !this.drawer__openStage1;

		if (this.drawer__openStage2) {
			this.closeDrawerStageTwo()
		}

		if (this.drawer__activeBodyText) {
			this.removeActiveDrawerBodyText()
		}

		if (this.drawer__activeModalText) {
			this.removeActiveDrawerModalText()
		}

		if (this.drawer__showReadingRoom) {
			this.hideReadingRoom()
		}
	}

	@action.bound toggleDrawerStageTwo() {
		this.drawer__openStage2 = !this.drawer__openStage2;
	}

	@action.bound openDrawerStageTwo() {
		this.drawer__openStage2 = true;
		const posterWrapper = document.getElementsByClassName('page--poster')[0];
		if (posterWrapper) {
			posterWrapper.classList.add('fixed-scrolling');
		}
	}

	@action.bound setActiveDrawerBodyText(textSlug)  {
		this.drawer__activeBodyText = textSlug;
	}

	@action removeActiveDrawerBodyText() {
		this.drawer__activeBodyText = null;
	}

	@action.bound showReadingRoom() {
		this.openDrawerStageTwo()
		this.drawer__showReadingRoom = true;
	}

	@action hideReadingRoom() {
		this.drawer__showReadingRoom = false;
	}

	@action setActiveDrawerModalText(data)  {
		this.drawer__activeModalText = data
		const posterWrapper = document.getElementsByClassName('page--poster')[0];
		if (posterWrapper) {
			posterWrapper.classList.add('fixed-scrolling');
		}
	}

	@action.bound removeActiveDrawerModalText() {
		this.drawer__activeModalText = null;
		const posterWrapper = document.getElementsByClassName('page--poster')[0];
		if (posterWrapper) {
			posterWrapper.classList.remove('fixed-scrolling');
		}
	}

	@action closeDrawerStageTwo() {
		this.drawer__openStage2 = false;
	}

	@action closeDrawerStageOne() {
		this.drawer__openStage1 = false;
		const posterWrapper = document.getElementsByClassName('page--poster')[0];
		if (posterWrapper) {
			posterWrapper.classList.remove('fixed-scrolling');
		}
	}

	@action setActivePoster(poster) {
		this.activePoster = poster
	}

	registerPageVisit(type, slug) {
		if (!type || !slug) return

		if (!this.registeredPages[type][slug]) {
			this.registeredPages[type][slug] = {}
		}

		this.registeredPages[type][slug].visited = true;

	}

	registerData(type, slug) {
		this.registeredPages[type][slug] = {
			visited: false,
			dependencies: {
				landscape: {},
				infrastructure: {}
			}
		};
	}

	registerDependency(type, slug, depType, depName){
		this.registeredPages[type][slug].dependencies[depType][depName] = true;
	}

	@action setActiveSuperIndexCritter(critterSlug) {
		this.si__activeCritter = critterSlug;
	}

	@action setActiveSuperIndexHeader(type, slug) {
		this.si__activeHeaders[type] = slug;
	}

	@action setActiveLandscapePoster(posterSlug, displayName, clicID) {
		this.landscape__activePoster = posterSlug;
		this.landscape__activePoster__displayName = displayName;
		this.landscape__activePoster__clicID = clicID;
	}
	
	@action setActiveCLIC(clicID) {
		this.landscape__activePoster__clicID = clicID;
	}
	
	@action setActiveLandscapePosterLocation(location) {
		this.landscape__posterLocation = location;
	}

	@action.bound setActiveLandingPoster(posterSlug) {
		this.landing__activePoster = posterSlug
	}

	@action toggleFeralQualityText(id) {
		this.poster__activeFQs.clear();
		this.poster__activeFQs.push(id);
		this.poster__activeFQ = true;
	}

	@action toggleEditorsNote(id) {
		if (this.poster__activeEditorsNote === true) {
			this.poster__activeEditorsNote = false;
		} else {
			this.poster__activeEditorsNotes.clear();
			this.poster__activeEditorsNotes.push(id);
			this.poster__activeEditorsNote = true;
		}
	}

	@action.bound togglePosterReferences() {
		if (this.poster__showReferences) {
			this.closePosterReferences()
		} else {
			this.openPosterReferences()
		}
	}
	@action openPosterReferences() {
		this.poster__showReferences = true;
	}
	@action.bound closePosterReferences() {
		this.poster__showReferences = false;
	}

	@action.bound togglePosterFQReferences() {
		if (this.poster__showFQReferences) {
			this.closePosterFQReferences()
		} else {
			this.openPosterFQReferences()
		}
	}
	@action openPosterFQReferences() {
		this.poster__showFQReferences = true;
	}
	@action.bound closePosterFQReferences() {
		this.poster__showFQReferences = false;
	}

	@action.bound openAndScrollToFQRef() {
		this.poster__showFQReferences = true;
		// perhaps get position that we
		// were at before we scrollto
		// so we can scroll back...

		const el = document.getElementById("fq-refs");
		this.setPosterFQReferenceScrollLocation(window.scrollY);

		if (!el) return

		const options = {
			behavior: "smooth",
			block: "start",
		};

		el.scrollIntoView(options);
	}


	@action.bound scrollToFQReferenceScrollLocation() {
		const loc = this.poster__FQreferenceScrollLocation;
		if (!loc) return

		window.scroll({
			top: loc,
			behavior: "smooth"
		});

		// not sure if we need this but let's do it for now
		setTimeout(this.closePosterFQReferences, 500)
	}



	@action.bound openAndScrollToRef() {
		this.poster__showReferences = true;
		// perhaps get position that we
		// were at before we scrollto
		// so we can scroll back...

		const el = document.getElementById("refs");
		this.setPosterReferenceScrollLocation(window.scrollY);

		if (!el) return

		const options = {
			behavior: "smooth",
			block: "start",
		};

		el.scrollIntoView(options);
	}

	setEssayContentWrapEl(el) {
		this.essay__contentWrap = el;
	}

	@action.bound openAndScrollToRefEssays() {
		if (!this.essay__contentWrap) return

		let el;
		if (this.rootStore.routerStore.current.params.text) {
			el = document.getElementById("cd-essay-refs-modal");
		} else {
			el = document.getElementById("cd-essay-refs");
		}
		this.setEssayReferenceScrollLocation(this.essay__contentWrap.scrollTop);

		if (!el) return

		const options = {
			behavior: "smooth",
			block: "start",
		};

		el.scrollIntoView(options);
	}


	@action.bound setEssayReferenceScrollLocation(scrollLocation) {
		this.essay__referenceScrollLocation = scrollLocation;
	}

	@action.bound setPosterReferenceScrollLocation(scrollLocation) {
		this.poster__referenceScrollLocation = scrollLocation;
	}

	@action.bound setPosterFQReferenceScrollLocation(scrollLocation) {
		this.poster__FQreferenceScrollLocation = scrollLocation;
	}

	@action clearEssayReferenceScrollLocation() {
		this.essay__referenceScrollLocation = null;
	}


	@action clearReferenceScrollLocaation() {
		this.poster__referenceScrollLocation = null;
	}

	@action clearFQReferenceScrollLocaation() {
		this.poster__FQreferenceScrollLocation = null;
	}

	@action.bound scrollToEssayReferenceScrollLocation() {
		const loc = this.essay__referenceScrollLocation;
		if (!loc) return

		this.essay__contentWrap.scrollTop = loc;

		// not sure if we need this but let's do it for now
		setTimeout(this.closePosterReferences, 500)
	}

	@action.bound scrollToReferenceScrollLocation() {
		const loc = this.poster__referenceScrollLocation;
		if (!loc) return

		window.scroll({
			top: loc,
			behavior: "smooth"
		});

		// not sure if we need this but let's do it for now
		setTimeout(this.closePosterReferences, 500)
	}


	@action.bound startTransition() {
		this.transitioning = true;

		setTimeout(this.startTransitionStageOne, 50)
		setTimeout(this.startTransitionStageTwo, 500)
		setTimeout(this.endTransition, 1600)
	}

	@action.bound startTransitionStageOne() {

		this.transitioning__stage1 = true;

	}

	@action.bound startTransitionStageTwo() {

		this.transitioning__stage2 = true;

	}

	@action.bound endTransition() {
		this.transitioning = false;
		this.transitioning__stage1 = false;
		this.transitioning__stage2 = false;
	}
}

export default UIStore
