import React, {Component} from 'react'

import {
	LandscapeCoordinateValues,
	LandscapeDropdown,
	LandscapeImage,
	LandscapePositionMarker,
	LandscapeSaveCoordsTooltip
	
} from './subcomponents'

class LandscapePositioning extends Component {
	render() {
		return (
			<div className="positioning-app">
				<LandscapeSaveCoordsTooltip></LandscapeSaveCoordsTooltip>
				<LandscapeCoordinateValues/>
				<LandscapeDropdown></LandscapeDropdown>
				<LandscapeImage></LandscapeImage>
				<LandscapePositionMarker></LandscapePositionMarker>
			</div>
		)
	}
}

export default LandscapePositioning