import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {Link} from '../../../components/general/link'

import {
	slugify
} from '../../../helpers'

import get from 'lodash/get'

const PosterFooter = (props) => {
	return (
		<div className="poster__footer">
		<FooterImage></FooterImage>
			<div className="poster__footer__inner">
				<div className="poster__footer__credit">
					<h6>
						Artwork by Emmy Lingscheit
					</h6>
				</div>
				<div>
					<h5 className="poster__footer__copy">This is merely one field report in the Feral Atlas</h5>
					
					<Link to="landing">
						<div className="poster__footer__revert">
							Revert at your own risk
						</div>
					</Link>

					<div className="poster__footer__explore"> 
						Or explore the <Link to="superindex"><span className="underline">Super Index</span></Link> 
					</div>
				</div>
			</div>
		</div>
	)
}

@inject("store") @observer
class FooterImage extends Component {

	render() {
		const {data: {globalSiteData}} = this.props.store.siteDataStore;
		const src = slugify(get(globalSiteData, "fields.revertAtYourOwnRiskImage.fields.title", ""));

		const backgroundStyles = {
			backgroundImage: `url('/images/archive-images/${src}-1800.jpg')`,
			backgroundSize: "cover",
			backgroundPositionY: "25%"
		}

		return (
			<div className="poster__footer__image" style={backgroundStyles} ></div>
		)

	}
}

export default PosterFooter