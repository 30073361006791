import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {Link} from '../general/link'

import get from 'lodash/get'

import {
	slugify
} from '../../helpers'

@inject("store") @observer
class GatedLinkHOC extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			hovered: false
		}
	}

	_onMouseLeave = () => {
		if (this.props.onMouseLeave) {
			this.props.onMouseLeave()
		}

		this.setState({
			hovered: false
		})
	}

	_onMouseEnter = () => {
		if (this.props.onMouseEnter) {
			this.props.onMouseEnter()
		}

		this.setState({
			hovered: true
		})
	}

	_getLinks() {
		// getting link to poster only for now

		const landscape = get(this.props.linkTo, "fields.landscape[0].fields.name", "")
		const infrastructure = get(this.props.linkTo, "fields.infrastructure[0].fields.name", "")
		const poster = get(this.props.linkTo, "fields.title", "");

		return {
			landscape, 
			infrastructure,
			poster
		}

	}

	_renderModal() {
		const {landscape, infrastructure, poster} = this._getLinks()

		return (
			<div className="g-l__modal">
				<Link to="landscape.view" params={{id: slugify(landscape)}}>
					<h6 className="g-l__link">Landscape: {landscape}</h6>
				</Link>
				
				<Link to="infrastructure.view" params={{id: slugify(infrastructure)}}>
					<h6 className="g-l__link">Infrastructure: {infrastructure}</h6>
				</Link>

				<Link to="critter.view" params={{id: slugify(poster)}}>
					<h6 className="g-l__link">Poster</h6>
				</Link>
			</div>
		)
	}

	render() {
		const {hovered} = this.state;

		return (
			<span 
			className="g-l" 
			onMouseEnter={this._onMouseEnter}
			onMouseLeave={this._onMouseLeave}>
				{hovered ? this._renderModal() : null}

				{this.props.children}
			</span>
		)
	}
}



export default GatedLinkHOC