import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'
import {Link} from '../../../components/general/link'
@inject("store") @observer
class BackButton extends Component {
	render() {
		return (
			<Link to="landing" className="s-i__back">

					Revert At Your Own Risk

			</Link>
		)
	}
}

export default BackButton
