import React from 'react'

const ContentDrawerStanfordFooter = (props) => {
	return (
		<div className="cd__stanford-footer">
			<div className="l">
				<h6>© 2020 Stanford University. All rights reserved.</h6>
			</div>

			<div className="c">
				<h6>ISBN 9781503615045 | DOI 10.21627/2020fa</h6>
			</div>

			<div className="r">
				<h6>Published by <a href="https://www.sup.org" target="_blank">STANFORD UNIVERSITY PRESS</a></h6>
				<img className="sup-logo" src="/images/logo_stanford-university-press.svg" alt=""/>
			</div>
		</div>
	)
}

export default ContentDrawerStanfordFooter