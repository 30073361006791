import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'


import {
	slugify
} from '../../../helpers'

import get from 'lodash/get'
import {
	QueryParamLink,
	Link
} from '../link'
@inject("store") @observer
class FeralQualityInline extends Component {
	_setFQPosition = (element) => {
		 if (element) {
			let offset = element.getBoundingClientRect().top;

			this.fqposition = offset;
		 }
	};


	_toggleFQ = () => {
		const id = this.props.data.sys.id;
		this.props.store.uiStore.toggleFeralQualityText(id);

		this.props.store.uiStore.addfqtolist(this.props.data.fields.name);
	}

	_renderClosed() {
		const feralQuality = get(this.props.data, "fields.feralQuality.fields.name", "") || get(this.props.data, "fields.name", "")
		const c = get(this.props.data, "fields.color", {});
		const color = {
			color: `rgb(${c.r}, ${c.g}, ${c.b})`,
			borderColor: `rgb(${c.r}, ${c.g}, ${c.b})`
		}

		return (
			<div className="fq-section" onClick={this._toggleFQ}>
				<h3 style={color} className="fq-section__name"><span>Feral Quality</span></h3>
			</div>
		)
	}

	_renderOpen() {
		const onlyShowLink = this.props.showLinkOnly ? true : false;
		const getDescriptionRichText = get(this.props.data, "fields.shortDescriptionRichText");
		const onlyShowLinkInPoster = get(this.props.data, "fields.onlyShowLinkInPoster");
		let description = get(this.props.data, "fields.shortDescription");
		const feralQuality = get(this.props.data, "fields.name", "");
		const c = get(this.props.data, "fields.color", {});
		const color = {
			color: `rgb(${c.r}, ${c.g}, ${c.b})`,
			borderColor: `rgb(${c.r}, ${c.g}, ${c.b})`
		};
		let inlineColor = {color: 'inherit'};
		let descriptionRichText = [];

		if (getDescriptionRichText) {
			description = getDescriptionRichText.content.map((desc) => {

				desc.content.forEach((node, idx) => {
					let markClasses = '';
					if (node.marks && node.marks.length) {
					   markClasses = node.marks.map((mark) => {
							 inlineColor = {color: 'inherit'};
							if (mark.type === "bold") {
								inlineColor = color;
							}
							return mark.type
						})

						markClasses.join(" ");
					}

					if (this.props.isDuplicate) {
						if (node.nodeType === "entry-hyperlink") {
							let fqslug = slugify(node.content[0].value);
							inlineColor = color;
							if (fqslug) {
								descriptionRichText.push(<QueryParamLink key={idx} to="modal" params={{text: `fq-${fqslug}`, ttype: 'essay', rr: 'true'}}><span style={inlineColor}>{node.content[0].value}</span></QueryParamLink>)
							} else {
								descriptionRichText.push(<span key={`${idx}-link`} style={inlineColor} className={`${markClasses}`}>[link for {node.content[0].value}  is temporarily broken]</span>);
							}
						}
					} else {
						if (node.nodeType === "entry-hyperlink") {
							// const type = node.data.target.sys.contentType.sys.id;
							// if (type === "poster") {
							// 	const slug = node.data.target.fields.slug;
							// 	inlineColor = color;
							//
							// 	descriptionRichText.push(<Link to="critter.view" params={{id: slug}} style={inlineColor} className={`${markClasses}`}><span >{node.content[0].value}</span></Link>)
							// } else if (type === "essay") {
							// 	inlineColor = color;
							// 	const title = get(node.data, "target.fields.displayTitle", "");
							// 	const slug = get(node.data, "target.fields.slug", "");
							// 	descriptionRichText.push(<QueryParamLink key={idx} to="modal" params={{text: slug, ttype: type}} style={inlineColor}><span>{title}</span> </QueryParamLink>)
							// }
							inlineColor = color;
							let fqslug = slugify(node.content[0].value);
							if (fqslug) {
								descriptionRichText.push(<QueryParamLink key={idx} to="modal" params={{text: `fq-${fqslug}`, ttype: 'essay', rr: 'true'}}><span style={inlineColor}>{node.content[0].value}</span></QueryParamLink>)
							} else {
								descriptionRichText.push(<span key={`${idx}-link`} style={inlineColor} className={`${markClasses}`}>[link for {node.content[0].value}  is temporarily broken]</span>);
							}
						}

						descriptionRichText.push(<span key={idx} style={inlineColor} className={`${markClasses}`} dangerouslySetInnerHTML={{__html: window.marked(node.value || "", {renderer: window.markedRenderer})}}></span>)
					}

				})
			})
		} else {
			descriptionRichText = description;
		}

		return (
			<div className="fq-section">
				<h3 style={color} className="fq-section__name"></h3>
				{descriptionRichText}
			</div>
		)
	}


	render() {
		const contents = this.props.store.uiStore.poster__clickedfq === this.props.data.fields.name ? this._renderOpen() : this._renderClosed();
		const finalPos = Math.floor(this.props.pos - this.fqposition);

		return (
			<div className="poster__body__fq" ref={this._setFQPosition} style={{marginTop: finalPos}}>
				{contents}
			</div>
		)
	}

}


export default FeralQualityInline
