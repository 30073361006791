import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'
import {
	ResponsiveImage
} from '../image'
import {
	Credits
} from '../text'
import get from 'lodash/get'

@inject("store") @observer
class BlockImage extends Component {
	_toggleLB = (caption) => {
		this.props.store.uiStore.togglePosterLightbox(caption);
	}

	render() {
		const imageSrc = get(this.props.data, "target.fields.media.fields.file.url")
		const image = get(this.props.data, "target.fields.media")
		const size = get(this.props.data, "target.fields.embeddedImageSize");
		const breakAlignment = get(this.props.data, "target.fields.breakEmbeddedAlignment");
		const isExpandable = get(this.props.data, "target.fields.isExpandable");
		let expandableContent = isExpandable ? ['', image] : '';

	return (
		<div className="poster__body__block">
			<div className={`${breakAlignment ? "break-align" : ""}`}>
				<div className={`poster__body__block__image-wrap ${size ? `poster__body__block__image-wrap--${size}` : ""}`}>
					<span class="image-wrapper">
						{isExpandable &&
							<div>
								<div className="toggle" onClick={() => this._toggleLB(expandableContent)}>
									<ResponsiveImage className="poster__body__block__image" image={this.props.data.target.fields.media} sizes={[600, 800, 1000, 1200, 1400]} multiplier={.5} q={90}/>
								</div>
								<Credits data={this.props.data} type="target" location="body" />
							</div>
						}

						{!isExpandable &&
							<span className="image-wrapper">
								<ResponsiveImage className="poster__body__block__image" image={this.props.data.target.fields.media} sizes={[600, 800, 1000, 1200, 1400]} multiplier={.5} q={90}/>
								<Credits data={this.props.data} type="target" location="body" />
							</span>
						}
					</span>
				</div>
			</div>
		</div>
		)
	}
}

export default BlockImage
