import React, {Component} from 'react'
import {toJS} from 'mobx'

import {
	InlineAudio,
	InlineImage,
	FeralQualityInline,
	FeralQualityInlineWithText,
	FeralQualityHighlightText,
	FeralQualityBlock
} from './'

import {
	QueryParamLink,
	Link
} from '../link'

import {
	slugify
} from '../../../helpers'

import get from 'lodash/get'

import {inject, observer} from 'mobx-react'

@inject("store") @observer
class RichTextEssay extends Component {
	constructor(props) {
		super(props);

		this.isDupe = false;
		this.fqsonpage = [];
		this.executed = false;
		this.state = {
			position: 0
		}
	}


	_setFQPosition = (position) => {
		this.setState({position: position})
	};

	_checkForDupes = (fqName) => {
    if (!this.executed) {
			this.executed = true;
				if (this.props.store.uiStore.poster__allfqs.includes(fqName)) {
					this.isDupe = true;
				} else {
					this.props.store.uiStore.getallfqs(fqName);
				}
    }
	};

	render() {
		const data = [];
		let fqFlag = this.props.faFlag || false;
		let refFlag = false;
		let fqData = [];
		let feralQuality = null;
		let inlineImage = null;
		let inlineContent = [];
		let currentSections = {};
		let isBlockQuote = '';
		let key;

		const paragraph = this.props.data.content.forEach((node, idx) => {
			let markClasses = [];
			if (node.marks && node.marks.length) {
				 markClasses = node.marks.map((mark) => {
					return mark.type
				})
			}
			if (node.nodeType === "text") {
				if (!node.value) return
				if (fqFlag) {
					if (refFlag) {
						data.push(<ReferenceTag key={idx} node={node}/>)
					} else {
						data.push(<FeralQualityHighlightText key={idx} text={node.value} fq={fqFlag} setFQPosition={this._setFQPosition} />)
					}
				} else if (refFlag) {
					data.push(<ReferenceTag key={idx} node={node}/>)
				} else {
					data.push(<span className={`${markClasses}`} key={idx} dangerouslySetInnerHTML={{__html: window.marked(node.value || "", {renderer: window.markedRenderer})}}></span>)
				}
			} else if (node.nodeType === "entry-hyperlink") {
				const type = node.data.target.sys.contentType.sys.id;
				if (type === "poster") {
					const slug = node.data.target.fields.slug;
					data.push(<Link to="critter.view" params={{id: slug}}><span>{node.content[0].value}</span></Link>)
				} else if (type === "essay") {
					const slug = get(node.data, "target.fields.slug", "");
					data.push(<QueryParamLink key={idx} to="modal" params={{text: slug, ttype: type}}><span>{node.content[0].value}</span></QueryParamLink>)
				}
			}
		})

		return (
			<div>
				{data.map(item => {
					if (item.props.dangerouslySetInnerHTML && item.props.dangerouslySetInnerHTML.__html.indexOf("<blockquote>") !== -1) {
						isBlockQuote = 'is-blockquote';
					}
				})}

				<div id={this.props.id} className={`essay__body__text ${isBlockQuote}`}>
					{data.map(item => {
						return item;
					})}
				</div>
			</div>
		)
	}
}


@inject("store") @observer
class ReferenceTag extends Component {
	render() {
		const {node} = this.props;

		return (
			<sup onClick={this.props.store.uiStore.openAndScrollToRef} className="poster__body__reference" dangerouslySetInnerHTML={{__html: window.marked(node.value || "", {renderer: window.markedRenderer})}}></sup>
		)
	}
}


export default RichTextEssay
