import React, {Component, Fragment} from 'react'
import {inject, observer} from 'mobx-react'

@inject("store") @observer
class TransitionEl extends Component {
	render() {
		const {transitioning__stage1, transitioning__stage2} = this.props.store.uiStore;

		return (
			<div className={`tr-el ${transitioning__stage1 ? "tr-el--st-1" : ""} ${transitioning__stage2 ? "tr-el--st-2" : ""}`}></div>
		)
	}
}

export default TransitionEl