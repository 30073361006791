import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import get from 'lodash/get'

@inject("store") @observer
class ContentDrawerReferences extends Component {
	// _renderEmpty() {
	// 	return (
	// 		<div id="refs" className="poster__body__section poster__body__references">
	// 			<h1 className="poster__body__references__button" onClick={this.props.store.uiStore.togglePosterReferences}>View References</h1>
	// 		</div>
	// 	)
	// }

	render() {
		const {uiStore: {essay__referenceScrollLocation}} = this.props.store;
		// const activeEssay = essayDataByName[drawer__activeBodyText];
		// const references = get(activeEssay, "fields.references");
		const {references, isModal} = this.props;


		return (
			<div id={isModal ? "cd-essay-refs-modal" : "cd-essay-refs"}>
				{!!essay__referenceScrollLocation ?
					 <h3 className="poster__body__references__back" onClick={this.props.store.uiStore.scrollToEssayReferenceScrollLocation}>Return To Text</h3>
					 : null }
				<div dangerouslySetInnerHTML={{__html: window.marked(references || "", {renderer: window.markedRenderer})}}></div>
			</div>

		)
	}
}

export default ContentDrawerReferences