import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	ContentDrawerHeader,
	ContentDrawerMenu,
	ContentDrawerPreview,
	ContentDrawerBody,
	ContentDrawerArticleModal,
	ContentDrawerReadingRoomButton,
	ContentDrawerStanford
} from './subcomponents'

import {
	ReadingRoom
} from '../readingroom'


@inject("store") @observer
class ContentDrawer extends Component {


	_renderReadingRoom() {
		return <ReadingRoom/>
	}

	_renderGeneralContent() {
		const {drawer__openStage2} = this.props.store.uiStore;

		return [
			// <ContentDrawerPreview key="cd-pre"/>,
			drawer__openStage2 ? <ContentDrawerBody key="cd-body"/> : null
		]
	}

	_getHeightStyles() {
		const {drawer__openStage1,drawer__openStage2,drawer__previewHeight, windowHeight} = this.props.store.uiStore;

		var transformAmt = drawer__openStage1 ? -1 : windowHeight - 88;
		if (drawer__openStage2) {
			transformAmt = 0;
		}

		const style ={
			transform: `translate3d(0, ${transformAmt}px, 0)`
		};

		return style
	}

	render() {
		const {drawer__openStage1, drawer__showReadingRoom} = this.props.store.uiStore;
		const generalContent = this._renderGeneralContent();

		const heightStyle = this._getHeightStyles();

		return (
			<div style={heightStyle} className={`cd  ${drawer__openStage1 ? "cd--preview" : ""}`}>
				<ContentDrawerHeader/>
				<ContentDrawerStanford/>
				<div className="cd__inner">
					<div className={drawer__showReadingRoom  || this.props.store.routerStore.currentName === "superindex" ? "cd__slide-content cd__slide-content--slide-left" : "cd__slide-content"}>
						<ContentDrawerReadingRoomButton/>

						{drawer__openStage1 ? <ContentDrawerPreview /> : null}

						<div className="cd__content-wrap">
							{generalContent}
						</div>

						<ReadingRoom/>
					</div>

					<ContentDrawerArticleModal/>
				</div>
			</div>
		)
	}
}


export default ContentDrawer
