import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import get from 'lodash/get'

@inject("store") @observer
class PosterReferences extends Component {
	_renderEmpty() {
		return (
			<div id="refs" className="poster__body__section poster__body__references">
				<h1 className="poster__body__references__button" onClick={this.props.store.uiStore.togglePosterReferences}>View References</h1>
			</div>
		)
	}
	render() {
		const {pageData, uiStore: {poster__showReferences}} = this.props.store;
		const references = get(pageData, "fields.references");
		
		if (!references) return null
		if (!poster__showReferences) return this._renderEmpty();

		return (
			<div>
				<div id="refs" className="poster__body__section poster__body__references">
					{this.props.store.uiStore.poster__referenceScrollLocation ?
						 <h3 className="poster__body__references__back" onClick={this.props.store.uiStore.scrollToReferenceScrollLocation}>Return To Text</h3>
						 : null }
					<div dangerouslySetInnerHTML={{__html: window.marked(references || "", {renderer: window.markedRenderer})}}></div>
				</div>
			</div>

		)
	}
}

export default PosterReferences