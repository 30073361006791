import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import get from 'lodash/get'

@inject("store") @observer
class Credits extends Component {
  state = {
    pins: {}
  }

  _pin = (section, e) => {
    e.stopPropagation();
    e.preventDefault();

    const pins = JSON.parse(JSON.stringify(this.state.pins));
    pins[section] = !!!pins[section];

    this.setState({
      pins
    });
  }

	_renderCredits() {
    let type = this.props.type;
    let location;
    let dataAtt = '';
    if (this.props.type) dataAtt = this.props.type;
    if (this.props.location) location = this.props.location;
    const credits = get(this.props.data, `${dataAtt}.fields.attribution`);
    let caption = get(this.props.data, `${dataAtt}.fields.caption`);
    let richCaption = [];
    let sourceRichCaption = [];
		let attribution = [];
    const hoverModal = [];

    const sourceHoverModal = [];
		const attributionHoverModal = [];
    const isExpandable = get(this.props.data, `${dataAtt}.fields.isExpandable`);
    const expandableCaptionPresent = get(this.props.data, `${dataAtt}.fields.expandableCaption`) ? true : false;
    const expandableCaption = get(this.props.data, `${dataAtt}.fields.expandableCaption`);
    const expandableSourceCaptionPresent = get(this.props.data, `${dataAtt}.fields.expandableSourceCaption`) ? true : false;
    const expandableSourceCaption = get(this.props.data, `${dataAtt}.fields.expandableSourceCaption`);
		const expandableAttributionPresent = get(this.props.data, `${dataAtt}.fields.expandableAttribution`) ? true : false;
		const expandableAttribution = get(this.props.data, `${dataAtt}.fields.expandableAttribution`);
    let expandableContent = expandableCaptionPresent ? [expandableCaption.content, ''] : '';
    let expandableSourceContent = expandableSourceCaptionPresent ? [expandableSourceCaption.content, ''] : '';
		let expandableAttributionContent = expandableAttributionPresent ? [expandableAttribution.content, ''] : '';
    let cutoffPoint = false;
    let sourceCutoffPoint = false;
		let attributionCutoffPoint = false;


    let match;
    let moreModalHoverText = "";
    let matched;
    const findMoreText = /\{(.*?)\}/g;

    if (expandableCaptionPresent) {
      expandableContent = [expandableCaption.content];
    } else if (isExpandable && !expandableCaptionPresent) {
      expandableContent = ['']
    }

    if (expandableSourceCaptionPresent) {
      expandableSourceContent = [expandableSourceCaption.content];
    } else if (isExpandable && !expandableSourceCaptionPresent) {
      expandableSourceContent = ['']
    }

		if (expandableAttributionPresent) {
      expandableAttributionContent = [expandableAttribution.content];
    } else if (isExpandable && !expandableAttributionPresent) {
      expandableAttributionContent = ['']
    }

		if (expandableAttribution && expandableAttribution.content) {
			expandableAttribution.content.map((cap) => {
				cap.content.forEach((idx) => {
					if (idx.nodeType === "hyperlink") {
						attributionHoverModal.push(<span className={`poster__${location}__caption__credit`} dangerouslySetInnerHTML={{__html: idx.content[0].value || "" }}/>)
					}
					if (idx.nodeType === "embedded-entry-inline") {
						attributionCutoffPoint = true;
					} else if (attributionCutoffPoint) {
						attributionHoverModal.push(<span className={`poster__${location}__caption__credit`} dangerouslySetInnerHTML={{__html: window.marked(idx.value || "", {renderer: window.markedRenderer})}}></span>)
					} else {
						if (idx.nodeType === "text") {
						 attribution.push(<span className={`poster__${location}__credits__caption`} dangerouslySetInnerHTML={{__html: window.marked(idx.value || "", {renderer: window.markedRenderer})}} />)
						}
						if (idx.nodeType === "hyperlink") {
							attribution.push(<span className={`poster__${location}__credits__caption`}><span dangerouslySetInnerHTML={{__html: idx.content[0].value || "" }}/></span>)
						}
					}
				})
			})
		}

    if (expandableCaption && expandableCaption.content) {
      expandableCaption.content.map((cap) => {
        cap.content.forEach((idx) => {
					if (idx.nodeType === "hyperlink") {
						hoverModal.push(<span className={`poster__${location}__caption__credit`} dangerouslySetInnerHTML={{__html: idx.content[0].value || "" }}/>)
					}
          if (idx.nodeType === "embedded-entry-inline") {
            cutoffPoint = true;
          } else if (cutoffPoint) {
						hoverModal.push(<span className={`poster__${location}__caption__credit`} dangerouslySetInnerHTML={{__html: window.marked(idx.value || "", {renderer: window.markedRenderer})}}></span>)
          } else {
            if (idx.nodeType === "text") {
              const findMoreText = /\{\{(.*?)\}\}/;
              let moreModalInnerText = idx.value;
              let matches = moreModalInnerText.match(findMoreText);

              if (!matches) {
                richCaption.push(<span className={`poster__${location}__credits__caption`} dangerouslySetInnerHTML={{__html: window.marked(idx.value || "", {renderer: window.markedRenderer})}} />)
              } else {
                moreModalInnerText = moreModalInnerText.replace(findMoreText, `<span class="reference-link more">More.<span class="hover-caption">${matches[1]}</span></span>`)
                richCaption.push(<span className={`poster__${location}__credits__caption`} dangerouslySetInnerHTML={{__html: window.marked(moreModalInnerText || "", {renderer: window.markedRenderer})}} />)
              }
            }
						if (idx.nodeType === "hyperlink") {
							richCaption.push(<span className={`poster__${location}__credits__caption`}><span dangerouslySetInnerHTML={{__html: idx.content[0].value || "" }}/></span>)
						}
          }
        })
      })
    }

    if (expandableSourceCaption && expandableSourceCaption.content) {
      expandableSourceCaption.content.map((cap) => {
        cap.content.forEach((idx) => {
					if (idx.nodeType === "hyperlink") {
						sourceHoverModal.push(<span className={`poster__${location}__caption__credit`} dangerouslySetInnerHTML={{__html: idx.content[0].value || ""}}/>)
					}
          if (idx.nodeType === "embedded-entry-inline") {
            sourceCutoffPoint = true;
          } else if (sourceCutoffPoint) {
            sourceHoverModal.push(<span className={`poster__${location}__caption__credit`} dangerouslySetInnerHTML={{__html: window.marked(idx.value || "", {renderer: window.markedRenderer})}}></span>)
          } else {
            if (idx.nodeType === "text") {
             sourceRichCaption.push(<span className={`poster__${location}__credits__caption`} dangerouslySetInnerHTML={{__html: window.marked(idx.value || "", {renderer: window.markedRenderer})}} />)
					 }
					 if (idx.nodeType === "hyperlink") {
						 sourceRichCaption.push(<span className={`poster__${location}__credits__caption`}><span dangerouslySetInnerHTML={{__html: idx.content[0].value || "" }}/></span>)
					 }
          }
        })
      })
    }

    return (
      <div className={`poster__${location}__credits`}>
				<span className={`poster__${location}__credits__credit`}>
					{attribution.length < 1 &&
		        <span dangerouslySetInnerHTML={{__html: window.marked(credits || "", {renderer: window.markedRenderer})}} />
					}
					{attribution.length > 0 &&
						<span>
						{attribution.map(item => {
							return item;
						})}
						</span>
					}
					{attributionCutoffPoint &&
						 <span className="reference-link r-l-1">
               <span  className="reference-link" onClick={this._pin.bind(this, "r-l-1")}>Source</span>

							 <span className="hover-caption">
								 {attributionHoverModal.map(item => {
									 return item;
								 })}
							 </span>
						 </span>
					}
				</span>
        {!richCaption.length &&
          <span className={`poster__${location}__credits__caption`} dangerouslySetInnerHTML={{__html: window.marked(caption || "", {renderer: window.markedRenderer})}} />
        }
        {richCaption &&
          <span>
          {richCaption.map(item => {
            return item;
          })}
          </span>
        }
        {cutoffPoint &&
           <span className="reference-link  r-l-2">
             <span  className="reference-link" onClick={this._pin.bind(this, "r-l-2")}>Reference</span>

             <span className="hover-caption">
               {hoverModal.map(item => {
                 return item;
               })}
             </span>
           </span>
        }
        {sourceRichCaption &&
          <span>
          {sourceRichCaption.map(item => {
            return item;
          })}
          </span>
        }
        {sourceCutoffPoint &&
           <span className="reference-link  r-l-3">
             <span className="reference-link" onClick={this._pin.bind(this, "r-l-3")}>Source</span>

             <span className="hover-caption">
               {sourceHoverModal.map(item => {
                 return item;
               })}
             </span>
           </span>
        }
      </div>
    )
	};

	render() {
    const credits = this._renderCredits();

    const pinnedClasses = Object.keys(this.state.pins).map(key => {
      if (!this.state.pins[key]) return ''

      return `pinned--${key}`
    }).join(" ")

		return (
      <div className={pinnedClasses}>{credits}</div>
		)
	}
}


export default Credits
