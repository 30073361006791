import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {VideoCarousel} from '../../../components/slideshow'

import {
	shuffle
} from '../../../helpers'

@inject("store") @observer
class InfrastructureVideos extends Component {
	render() {
		return (
			<div className="infrastructure__videos">
				<VideoCarousel description={this.props.description} videos={shuffle(this.props.videos)}/>
			</div>
		)
	}
}

export default InfrastructureVideos
