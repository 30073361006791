import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

@inject("store") @observer
class ContentModal extends Component {
	render() {
		const {poster__activeModalContent} = this.props.store.uiStore;

		if (!poster__activeModalContent) return null

			
		return (
			<div className="content-modal">
				<div className="content-modal__copy">
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni impedit, iste dicta adipisci, aliquam dolores quaerat suscipit esse obcaecati voluptatibus aperiam. Ut, saepe quas omnis ducimus alias consectetur natus ipsum.
					</p>

					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni impedit, iste dicta adipisci, aliquam dolores quaerat suscipit esse obcaecati voluptatibus aperiam. Ut, saepe quas omnis ducimus alias consectetur natus ipsum.
					</p>

					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni impedit, iste dicta adipisci, aliquam dolores quaerat suscipit esse obcaecati voluptatibus aperiam. Ut, saepe quas omnis ducimus alias consectetur natus ipsum.
					</p>

					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni impedit, iste dicta adipisci, aliquam dolores quaerat suscipit esse obcaecati voluptatibus aperiam. Ut, saepe quas omnis ducimus alias consectetur natus ipsum.
					</p>
				</div>

				<div className="content-modal__image">
					<img src="/images/critter_1.jpg" alt=""/>
				</div>
			</div>
		)
	}
}

export default ContentModal