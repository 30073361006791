import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import get from 'lodash/get'

@inject("store") @observer
class FeralQualityHighlightText extends Component {
	_setFQPosition = (element) => {
		 if (element) {
			let offset = element.getBoundingClientRect().top;
			this.props.setFQPosition(offset);
		 }
	};

	render() {
		const {poster__activeFQ} = this.props.store.uiStore;
		const posters = this.props.store.uiStore.poster__activeFQs;
		let name;
		const c = get(this.props.fq, "fields.color") || get(this.props.fq, "fields.feralQuality.fields.color");
		let red = 0;
		let green = 0;
		let blue = 0;
		if (c) red = c.r;
		if (c) green = c.g;
		if (c) blue = c.b;

		const color = {
			color: `rgb(${red}, ${green}, ${blue})`
		}


		if (this.props.fq.fields.feralQuality) {
			name = this.props.fq.fields.feralQuality.fields.name
		}
		if (!this.props.fq.fields.feralQuality) {
			name = this.props.fq.fields.name
		}

		return (
			<span ref={this._setFQPosition} style={this.props.store.uiStore.poster__clickedfq === name ? color : {}} className={this.props.store.uiStore.poster__clickedfq === name ? "fq fq--active" : "fq"} dangerouslySetInnerHTML={{__html: window.marked(this.props.text || "", {renderer: window.markedRenderer})}}></span>
		)
	}
}

export default FeralQualityHighlightText
