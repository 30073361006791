import React from 'react'

export const LeftArrow = (props) => {
	return (
		<img className={`arrow arrow--left ${props.className}`} onClick={props.onClick} src="/images/arrow-left-cropped.svg" alt=""/>
	)
}

export const RightArrow = (props) => {
	return (
		<img className={`arrow arrow--right ${props.className}`} onClick={props.onClick} src="/images/arrow-right-cropped.svg" alt=""/>
	)
}