import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	ContentDrawerMenu,
	ContentDrawerBody
} from './'

import {
	slugify
} from '../../../helpers'

import {
	Link
} from '../../general/link'

import {
	RichTextEssays
} from '../../general/text'

import get from 'lodash/get'

@inject("store") @observer
class ContentDrawerPreview extends Component {

	componentDidMount() {
		this._measureHeight()
	}

	_measureHeight() {
		const {preview} = this.refs;
		if (!preview) return

		const {height} = preview.getBoundingClientRect();

		this.props.store.uiStore.setContentDrawerPreviewHeight(height);
	}

	render() {
		if (this.props.store.uiStore.drawer__openStage2) return null

		return (
			<div ref="preview" className="cd__preview">
				<ContentDrawerMenu/>
				<ContentDrawerPreviewSummary/>
			</div>
		)
	}
}



@inject("store") @observer
class ContentDrawerPreviewSummary extends Component {
	componentDidMount() {
		this.props.store.uiStore.setEssayContentWrapEl(this.refs.contentWrap);
	}
	
	componentDidUpdate() {
		if (!this.refs.contentWrap) return
			
		this.refs.contentWrap.scrollTop = 0;
		// this.props.store.uiStore.setEssayContentWrapEl(this.refs.contentWrap);
	}

	render() {
		const {data: {globalSiteData}} = this.props.store.siteDataStore;
		const {uiStore: {drawer__activeBodyText}, siteDataStore: {essayDataByName}} = this.props.store;
		let description = get(this.props.store.pageData, "fields.longDescriptionRichText");
		const landingDesc = get(globalSiteData, "fields.landingPreviewDescription");
		const fieldReportDesc = get(globalSiteData, "fields.fieldReportPreviewDescription");
		let activeEssay = essayDataByName[drawer__activeBodyText];

		if (activeEssay) {
			return (
				<div ref="contentWrap" className="cd__content-wrap">
					<ContentDrawerBody />
				</div>
			)
		} else if (this.props.store.routerStore.current.name === "landing") {
			return (
				<div ref="contentWrap" className="cd__content-wrap landing">
					<RichTextEssays data={landingDesc.content[0]}/>
				</div>
			)
		} else if (this.props.store.routerStore.current.name === "critter.view") {
			return (
				<div ref="contentWrap" className="cd__content-wrap landing">
					<RichTextEssays data={fieldReportDesc.content[0]}/>
				</div>
			)
		} else if (description) {
			let previewText = description.content.map((data) => {
				return <RichTextEssays data={data} />
			})
			return (
				<div ref="contentWrap" className="cd__content-wrap">
					{previewText}
				</div>
			)
		} else {
			return (
				<div ref="contentWrap" className="cd__content-wrap">

				</div>
			)
		}

	}

}

@inject("store") @observer
class OtherPosterSection extends Component {
	render() {
		const {pageData, siteDataStore: {posterDataByCritter}} = this.props.store;
		const critterSlug = slugify(get(pageData, "fields.critter.fields.name", ""));
		const posters = posterDataByCritter[critterSlug];
		if (!posters || posters.length === 1) return null
		return (
			<span>
				{posters.map(poster => {
					if (poster.fields.title === pageData.fields.title) return null
					return  (
						<div>
							<div style={{height: "auto", padding: "20px"}}>
								<h1>Related Field Reports:</h1>
								<Link key={`cd-op-${poster.fields.slug}`} to={"critter.view"} params={{id: poster.fields.slug}}>
									<h2 style={{textDecoration: "underline"}}>{poster.fields.title}</h2>
								</Link>
							</div>
						</div>
					)
				})}
			</span>
		)
	}
}

export default ContentDrawerPreview
