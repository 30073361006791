import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	ContentDrawerBodySection,
	ContentDrawerSubMenu
} from './'

import {
	QueryParamLink
} from '../../general/link'

import {
	// FQReferencesSection,
	// ReferencesSection,
	AcknowledgmentsSection,
	RichTextEssays
} from '../../general/text'

import {
	ContentDrawerReferences
} from './'

import {
	CritterCarousel
} from '../../slideshow'

import {
	slugify
} from '../../../helpers'


import get from 'lodash/get'

@inject("store") @observer
class ContentDrawerArticleModal extends Component {
	render() {
		const active = this.props.store.uiStore.drawer__activeModalText

		return (
			<div className={active ? "cd__article-modal cd__article-modal--active" : "cd__article-modal"}>
				<ContentDrawerModalInner></ContentDrawerModalInner>
			</div>
		)
	}
}

@inject("store") @observer
class ContentDrawerModalInner extends Component {
	componentDidMount() {
		if (this.refs.contentWrap) {
			this.props.store.uiStore.setEssayContentWrapEl(this.refs.contentWrap);
		}
	}

	componentDidUpdate() {
		if (!this.refs.contentWrap) return

		this.refs.contentWrap.scrollTop = 0;
		this.props.store.uiStore.setEssayContentWrapEl(this.refs.contentWrap);

	}

	_renderText() {
		const {essayDataModal} = this.props.store;

		const data = get(essayDataModal, "fields.text.content", []);
		return data.map((section) => {
			return <ContentDrawerBodySection data={section}/>
		})
	}


	_renderContributer() {
		const {uiStore: {drawer__activeModalText}, siteDataStore: {contributorDataByName}} = this.props.store;
		const activeContributor = contributorDataByName[drawer__activeModalText.slug];
		const name = get(activeContributor, "fields.name");
		let bio = get(activeContributor, "fields.bio");
		
		
		if (activeContributor) {
			if (activeContributor.fields.bioSketch) {
				bio = ( <RichTextEssays data={activeContributor.fields.bioSketch.content[0]}/> )
			}
			return (
				
					<p>
						{bio}
					</p>
				)
		}


	}
	render() {

		if (!this.props.store.uiStore.drawer__activeModalText) return null
		const {routerStore: {current}, uiStore: {drawer__activeModalText}, siteDataStore: {posterDataByFeralQuality, essayDataByName}} = this.props.store;

		let text, activeEssay, references, acknowledgments, title, authors;
		let authorLinks  = [];

		switch (drawer__activeModalText.type) {
			case "essay":
				const {essayDataModal} = this.props.store;
				activeEssay = essayDataModal;
				references = get(activeEssay, "fields.references", null);
				acknowledgments = get(activeEssay, "fields.acknowledgments", null);
				title = get(activeEssay, "fields.displayTitle") || get(activeEssay, "fields.title");
				let authorNames = get(activeEssay, "fields.author");
				if (authorNames) {
					authorNames.map((author, i) => {
						if (i === authorNames.length - 2) {
							authorLinks.push(<span><QueryParamLink key={i} to="modal" params={{ttype: 'contributor', text: `${author.fields.slug}`, name: author.fields.slug}}><span>{`${author.fields.name}`}</span></QueryParamLink> & </span>)
						} else if (i === authorNames.length - 1) {
							authorLinks.push(<span><QueryParamLink key={i} to="modal" params={{ttype: 'contributor', text: `${author.fields.slug}`, name: author.fields.slug}}><span>{`${author.fields.name}`} </span></QueryParamLink> </span>)
						} else {
							authorLinks.push(<span><QueryParamLink key={i} to="modal" params={{ttype: 'contributor', text: `${author.fields.slug}`, name: author.fields.slug}}><span>{`${author.fields.name}`}</span></QueryParamLink>, </span>)
						}
					})
				}
				text = this._renderText().concat([<ContentDrawerReferences isModal={true} references={references}/>,<AcknowledgmentsSection acknowledgments={acknowledgments}/>]);
				break;
			case "contributor":
				text = this._renderContributer();
				break;
		}

		const isFeralQualityEssay = get(activeEssay, "fields.isFeralQualityEssay");

		return (
			<div className="cd__article-modal__inner">
				{current.name === "superindex" ? null : <QueryParamLink remove={["text", "ttype"]}><span className="cd__article-modal__close">Close</span></QueryParamLink>}
				<ContentDrawerSubMenu/>
				<div ref="contentWrap" className="cd__content-wrap">
					<h3 className="poster__subtitle">{title}</h3><br/>
					<h3 className="poster__author">{authorLinks}</h3>
					{text}

					{isFeralQualityEssay ? 
						<div className="cd__fq-carousel-section">
							<h3 className="poster__subtitle">Explore this Feral Quality</h3><br/>
							 <CritterCarousel posters={posterDataByFeralQuality[slugify(title)]}/> 
						</div>
					: null}
				</div>
			</div>
		)
	}
}
export default ContentDrawerArticleModal
