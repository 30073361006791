import React, {Component} from 'react'

import {
	SuperIndexGraph,
	SuperIndexCritterCatalogue,
	BackButton
} from './subcomponents'

import {
	ReadingRoom
} from '../../components/readingroom'

class SuperIndex extends Component {
	render() {
		return (
			<div className="s-i">
				<BackButton/>
				<SuperIndexGraph/>
				<ReadingRoom/>
				<SuperIndexCritterCatalogue/>
			</div>
		)
	}
}






export default SuperIndex
