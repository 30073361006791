import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	ResponsiveImage
} from '../image'

import {
	Credits
} from '../text'

import get from 'lodash/get'


@inject("store") @observer
class InlineImage extends Component {
	_toggleLB = (caption) => {
		this.props.store.uiStore.togglePosterLightbox(caption);
	}

	render() {
		const image = get(this.props.data, "target.fields.media")
		const isExpandable = get(this.props.data, "target.fields.isExpandable");
		let expandableContent = isExpandable ? ['', image] : '';

		return (
			<div className="poster__body__inline-image-wrap">
				{isExpandable &&
					<div className="toggle" onClick={() => this._toggleLB(expandableContent)}>
						<ResponsiveImage className="poster__body__inline-image" image={image} sizes={[800]} q={90}/>
						<Credits data={this.props.data} type="target" location="body" />
					</div>
				}

				{!isExpandable &&
					<span className="image-wrapper">
						<ResponsiveImage className="poster__body__inline-image" image={image} sizes={[800]} q={90}/>
						<Credits data={this.props.data} type="target" location="body" />
					 </span>
					}
			</div>
		)
	}


}

export default InlineImage
