import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'


import {
	Link,
	QueryParamLink
} from '../../../components/general/link'

import {
	slugify
} from '../../../helpers'

import get from 'lodash/get'

class SuperIndexGraph extends Component {


	render() {
		return (
			<div className="s-i__graph">
				<GraphUnderlay/>
				<GraphHeaderLandscapes/>
				<GraphHeaderInfrastructures/>
				<GraphHeaderFeralQualities/>
				<GraphCritters/>
			</div>
		)
	}
}

@inject("store") @observer
class GraphUnderlay extends Component{
	ITs = ["DRAIN", "NET", "COUNT", "SWITCH", "DRILL"];
	FQs = ["Moves with fire", "Carried on roads", "Released in storms"]
	FEs = [
					"Avian flu",
					"Lead",
					"Zinc",
					"Mercury",
					"Manure",
					"Tailings",
					"PCB",
					"DDT",
					"BPA",
					"Brown tree snake",
					"Burmese python",
					"Cogon grass",
					"Cheatgrass",
					"Depleted uranium",
					"Pathogenic E. coli",
					"Mink",
					"Woody adelgid",
					"Garlic mustard",
					"Zebra mussel",
					"Lionfish",
					"Black cherry",
					"White man's footstep",
					"Harmful algal blooms",
					"Eucalyptus",
					"Radiata pine",
					"Microplastics",
					"Pine wilt nematode",
					"Toxoplasmosis",
					"White-nose syndrome"
				];

	componentDidMount() {
		this._activateRandomCritter();
	}
	componentWillUnmount() {
		if (this.timer) {
			clearTimeout(this.timer)
		}
	}
	_activateRandomCritter() {
		const critterLength = this.FEs.length;

		const rand1 = Math.floor(Math.random() * critterLength);
		const rand2 = Math.floor(Math.random() * critterLength);
		const rand3 = Math.floor(Math.random() * critterLength);

		this.refs[`fe-${rand1}`].activate();
		this.refs[`fe-${rand2}`].activate();
		this.refs[`fe-${rand3}`].activate();

		const fqLength = this.FQs.length;
		const fq1 = Math.floor(Math.random() * fqLength);

		this.refs[`fq-${fq1}`].activate();

		const itLength = this.ITs.length;
		const it1 = Math.floor(Math.random() * itLength);

		this.refs[`it-${it1}`].activate();


		this.refs['swarm'].activate();

	 	this.timer = setTimeout(this._activateRandomCritter.bind(this), 13000)
	}

	render() {


		const WIDTH = this.props.store.uiStore.windowWidth;
		const HEIGHT = this.props.store.uiStore.windowHeight;

		return (
			<div className="graph__underlay">
				{this.ITs.map((item, idx) => {
					return <GraphUnderlayCritter ref={`it-${idx}`} x={Math.random() * WIDTH} y={Math.random() * HEIGHT} name={item} w={WIDTH} h={HEIGHT}/>
				})}

				{this.FEs.map((item, idx) => {
					return <GraphUnderlayCritter ref={`fe-${idx}`} x={Math.random() * WIDTH} y={Math.random() * HEIGHT} name={item} w={WIDTH} h={HEIGHT}/>
				})}


				{this.FQs.map((item, idx) => {
					return <GraphUnderlayCritter ref={`fq-${idx}`} x={Math.random() * WIDTH} y={Math.random() * HEIGHT} name={item} w={WIDTH} h={HEIGHT} color="red"/>
				})}

				<GraphUnderlaySwarm
					ref="swarm"
					w={WIDTH}
					h={HEIGHT}
					x={400}
					y={500}
					name="Domestication"
					components={["Tuberculosis","Sparrows","Fleas","Avian Flu","Oats"]}/>

			</div>
		)
	}
}

class GraphUnderlayCritter extends Component {
	constructor(props) {
		super(props);

		const x = Math.random() * this.props.w;
		const y = Math.random() * this.props.h;

		this.state = {
			x: x,
			y: y,
			active: false

		}
	}

	componentDidMount() {
		this.timer = setTimeout(this.setLocations.bind(this), 500)
	}

	componentWillUnmount() {
		if (this.timer) {
			clearTimeout(this.timer)
		}
	}

	activate() {
		this.setState({
			active: true
		});


		setTimeout(this.setLocations.bind(this), 50);
		setTimeout(this.removeSelf.bind(this), 11000)
	}

	removeSelf() {
		this.setState({
			active: false
		});
	}

	setLocations() {

		const x = Math.random() * this.props.w;
		const y = Math.random() * this.props.h;

		this.setState({
			x,
			y
		});

	}

	render() {
		if (!this.state.active) return null;

		const x = this.state.x || this.props.x;
		const y = this.state.y || this.props.y;

		const style = {
			transform: `translate3d(${x}px, ${y}px, 0)`,
			color: this.props.color || "#000"
		};

		return (
			<h6 ref="item" style={style} className={`underlay__critter underlay-tr ${this.state.hide ? "underlay-hide" : ""}`}>{this.props.name}</h6>
		)
	}

}

class GraphUnderlaySwarm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			locations: [],
			x: null,
			y: null,
			active: true
		}
	}

	componentDidMount() {
		this.setLocations()

		this.timer = setTimeout(this.setLocations.bind(this), 500)
	}

	componentWillUnmount() {
		if (this.timer) {
			clearTimeout(this.timer)
		}
	}

	removeSelf() {
		this.setState({
			active: false
		});
	}

	activate() {
		this.setState({
			active: true
		});


		setTimeout(this.setLocations.bind(this), 50);
		setTimeout(this.removeSelf.bind(this), 11000)
	}

	setLocations() {
		const locations = [];

		for (var i = 0; i < 5; i++) {
			const x = Math.random() * 60 - 30;
			const y = Math.random() * 60 - 30;

			locations.push({x,y})
		}

		const x = Math.random() * this.props.w;
		const y = Math.random() * this.props.h;

		this.setState({
			locations,
			x,
			y
		});

		// this.timer = setTimeout(this.setLocations.bind(this), 10000)
	}

	_renderCritters() {
		if (!this.state.locations.length) return null;

		return this.props.components.map((item, idx) => {
			const {x,y} = this.state.locations[idx];
			const style = {
				transform: `translate(${x}px, ${y}px)`
			};

			return <h5 className="swarm__critter underlay-tr-sub" key={`sw-${idx}`} style={style}>{item}</h5>
		})
	}

	render() {
		const x = this.state.x || this.props.x;
		const y = this.state.y || this.props.y;

		const swarmStyle = {
			transform: `translate3d(${x}px, ${y}px, 0)`
		};

		const critters = this._renderCritters();

		return (
			<div style={swarmStyle} className="underlay__swarm underlay-tr">
				<h6 className="underlay__swarm__name">{this.props.name}</h6>
				<div className="underlay__swarm__critters">
					{critters}
				</div>
			</div>
		)


	}

}

@inject("store") @observer
class GraphCritters extends Component {


	_renderCritters() {
		const critterChunks = {};
		const critters = this.props.store.siteDataStore.crittersByName;
		Object.keys(critters).forEach(name => {
			const firstLetter = name[0];
			if (!critterChunks[firstLetter]) {
				critterChunks[firstLetter] = []
			}

			critterChunks[firstLetter].push(critters[name]);
		})


		return Object.keys(critterChunks).sort().map((letter) => {
			return <GraphSection letter={letter} critters={critterChunks[letter]}/>

		})
	}


	render() {
		const critters = this._renderCritters();

		return (
			<div className="graph__critters">
				{critters}
			</div>
		)
	}
}


const GraphSection = (props) => {
	const alphabetizedCritters = props.critters.sort((a, b) => (a[0].fields.critter.fields.name > b[0].fields.critter.fields.name) ? 1 : -1)
	return (
		<div className="s-i__critter-catalogue__section">
			{alphabetizedCritters.map((critter) => {
				return <GraphCritter data={critter}/>
			})}
		</div>
	)
}

@inject("store") @observer
class GraphCritter extends Component {
	_onMouseEnter = () => {
		this.props.store.uiStore.setActiveSuperIndexCritter(slugify(this.props.data[0].fields.critter.fields.name));
	}

	_onMouseLeave = () => {
		this.props.store.uiStore.setActiveSuperIndexCritter(null)
	}

	render() {
		const {siteDataStore: {posterDataByCritter}} = this.props.store;
		const name = get(this.props.data, "[0].fields.critter.fields.name");


		const critterInfrastructure = this.props.data.map(critter => {
			return get(critter, "fields.infrastructure", [])
		}).flat();

		const critterLandscape = this.props.data.map(critter => {
			return get(critter, "fields.landscape", [])
		}).flat()

		const critterFeralQuality = this.props.data.map(critter => {
			return get(critter, "fields.feralQuality", [])
		}).flat()


		const {si__activeHeaders: {infrastructure, landscape, feralQuality}} = this.props.store.uiStore;

		const a1 = critterInfrastructure.map(infrastructure => infrastructure.fields.name).includes(infrastructure)
		const a2 = critterLandscape.map(landscape => landscape.fields.name).includes(landscape)
		const a3 = critterFeralQuality.map(feralQuality => feralQuality.fields.name).includes(feralQuality)


		const critter = get(this.props.data, "fields.critter");
		const critterSlug = slugify(get(this.props.data, "[0].fields.critter.fields.name", ""));
		const seeMorePosters = posterDataByCritter[critterSlug];

		return (
			<div className="critter-graph-item">
				<h2
					className={a1 || a2 || a3 ? "graph__critter graph__critter--active" : `graph__critter ${infrastructure || landscape || feralQuality ? "graph__critter--hide" : ""}`}
					onMouseEnter={this._onMouseEnter}
					onMouseLeave={this._onMouseLeave}
					dangerouslySetInnerHTML={{__html: window.marked(name || "")}}></h2>
					<div className="authors">
						{seeMorePosters.map((poster,i) => {
							let authors = poster.fields.author[0].fields.name;

							if (poster.fields.author.length > 1) {
								authors = poster.fields.author[0].fields.name + " & " + poster.fields.author[1].fields.name
							}
							if (poster.fields.author.length > 2) {
								authors = poster.fields.author[0].fields.name + " et al."
							}
							if (poster.fields.slug === "coronavirus-stories-are-still-emerging") {
								authors = "Jericho Brown; Lyle Fearnley and Christos Lynteris; Audrey T. Lin and Morgan Myers; James Hassell et al."
							}

							return (
								<span>
									<Link
										to="critter.view" params={{id: poster.fields.slug}}
										onMouseEnter={this._onMouseEnter}
										onMouseLeave={this._onMouseLeave}
									>
										{authors}
									</Link>
								</span>
							)
						})}
				</div>
			</div>
		)
	}
}


@inject("store") @observer
class GraphHeaderLandscapes extends Component {
	render() {
		return (
			<div className="graph-header graph-header--landscapes">
				<Link to="landscape.view" params={{id: "invasion"}}><GraphHeaderLabel type="landscape" name="Invasion"/></Link>
				<Link to="landscape.view" params={{id: "empire"}}><GraphHeaderLabel type="landscape" name="Empire"/></Link>
				<Link to="landscape.view" params={{id: "capital"}}><GraphHeaderLabel type="landscape" name="Capital"/></Link>
				<Link to="landscape.view" params={{id: "acceleration"}}><GraphHeaderLabel type="landscape" name="Acceleration"/></Link>
			</div>
		)
	}
}

@inject("store") @observer
class GraphHeaderInfrastructures extends Component {
	render() {
		return (
			<div className="graph-header graph-header--infrastructures">
				<Link to="infrastructure.view" params={{id: "crowd"}}><GraphHeaderLabel type="infrastructure" name="Crowd"/></Link>
				<Link to="infrastructure.view" params={{id: "take"}}><GraphHeaderLabel type="infrastructure" name="Take"/></Link>
				<Link to="infrastructure.view" params={{id: "grid"}}><GraphHeaderLabel type="infrastructure" name="Grid"/></Link>
				<Link to="infrastructure.view" params={{id: "dump"}}><GraphHeaderLabel type="infrastructure" name="Dump"/></Link>

				<Link to="infrastructure.view" params={{id: "smooth-speed"}}><GraphHeaderLabel type="infrastructure" name="Smooth / Speed"/></Link>
				<Link to="infrastructure.view" params={{id: "burn"}}><GraphHeaderLabel type="infrastructure" name="Burn"/></Link>
				<Link to="infrastructure.view" params={{id: "pipe"}}><GraphHeaderLabel type="infrastructure" name="Pipe"/></Link>

			</div>
		)
	}
}


@inject("store") @observer
class GraphHeaderFeralQualities extends Component {
	render() {
		return (
			<div className="graph-header graph-header--feral-qualities">
				{Object.keys(this.props.store.siteDataStore.fqDataByName).map(key => {
					const data = this.props.store.siteDataStore.fqDataByName[key];
					const {r,g,b} = data.fields.color;

					return (

						<QueryParamLink to={"modal"} params={{text: `fq-${slugify(data.fields.name)}`, ttype: "essay"}}><GraphHeaderLabel style={{color: `rgb(${r}, ${g}, ${b})`}} type="feralQuality" name={data.fields.name}/></QueryParamLink>

					)
				})}
			</div>
		)
	}
}


@inject("store") @observer
	class GraphHeaderLabel extends Component {
	_onMouseLeave = () => {
		this.props.store.uiStore.setActiveSuperIndexHeader(this.props.type, null);
	}

	_onMouseEnter = () => {
		this.props.store.uiStore.setActiveSuperIndexHeader(this.props.type, this.props.name);
	}

	render() {
		let active;
		const {crittersByName} = this.props.store.siteDataStore;

		const posters = crittersByName[this.props.store.uiStore.si__activeCritter] || [];
		if (this.props.store.uiStore.si__activeCritter && this.props.type === "feralQuality") {
			const names = posters.map(poster => {
				return get(poster, "fields.feralQuality", []).map((item) => item.fields.name);
			}).flat()

			active = names.includes(this.props.name)
		} else if (this.props.store.uiStore.si__activeCritter) {

			const names = posters.map(poster => {
				return get(poster, `fields.[${this.props.type}]`, []).map((item) => item.fields.name)
			}).flat()

			active = names.includes(this.props.name);
			// posters.forEach(poster => {
			// 	active = get(poster, `fields.[${this.props.type}]`, []).map((item) => item.fields.name).includes(this.props.name);
			// });
		}

		const style = this.props.style || {};

		return <h5
				style={style}
				className={active ? `graph-header__label graph-header__label--${this.props.type} graph-header__label--active` : `graph-header__label ${this.props.store.uiStore.si__activeCritter ? "graph-header__label--hide" : ""}`}
				onMouseEnter={this._onMouseEnter}
				onMouseLeave={this._onMouseLeave}>
					{this.props.name}
				</h5>
	}
}

export default SuperIndexGraph
