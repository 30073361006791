import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'
import get from 'lodash/get'

@inject("store") @observer
class PosterAcknowledgments extends Component {
	render() {
		const {pageData} = this.props.store;
		const acknowledgments = get(pageData, "fields.acknowledgments");
		if (!acknowledgments) return null

		return (
			<div className="poster__body__section poster__body__acknowledgments" dangerouslySetInnerHTML={{__html: window.marked(acknowledgments || "", {renderer: window.markedRenderer})}}>
			</div>	
		)
	}
}

export default PosterAcknowledgments