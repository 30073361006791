import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	PosterHeader,
	PosterSubheader,
	PosterBody,
	PosterFooter
} from './subcomponents'

import {
	ContentModal
} from '../../components/content_modal'
import {
	PosterLightbox
} from '../../components/poster_lightbox'

@inject("store") @observer
class Poster extends Component {
	componentDidMount() {
		this.props.store.uiStore.registerPageVisit("poster", this.props.store.routerStore.current.params.id);
	}

	render() {
		return (
			<div className="page--poster">
				<ContentModal/>
				<PosterLightbox />
				<PosterHeader/>
				<PosterSubheader/>
				<PosterBody/>
				<PosterFooter/>
			</div>
		)
	}
}



export default Poster
