import { observable, computed, action } from 'mobx';

import {slugify} from '../helpers'
import get from 'lodash/get'

class PositioningStore {
	constructor(rootStore) {
		this.rootStore = rootStore;

		this.tooltipTimeout = null;
	}

	@observable activeLandscape = null;
	@observable mouseCoordinates = {x: 0, y: 0};
	@observable toolTipSavedPositions = {x: 0, y: 0}
	@observable showSavedTooltip = false;

	@computed get imageDimensions() {
		if (!this.activeLandscape) return {};


		const {landscapeDataByName} = this.rootStore.siteDataStore;
		const activeLandscapeData = landscapeDataByName[this.activeLandscape];

		return get(activeLandscapeData, "fields.map.fields.file.details.image", {})

	}

	@computed get mouseCoordPercentages() {

		const imageDimensions = this.imageDimensions;
		return {x: ((this.mouseCoordinates.x / imageDimensions.width) * 100).toFixed(2), y: ((this.mouseCoordinates.y / imageDimensions.height) * 100).toFixed(2)};

	}

	@action setActiveLandscape(landscape) {
		this.activeLandscape = slugify(landscape)
	}

	@action.bound setMouseCoordinates(coords) {
		const {x,y} = coords;

		this.mouseCoordinates.x = x;
		this.mouseCoordinates.y = y;
	}

	@action.bound copyDataToClipboard() {
		navigator.clipboard.writeText(`{"x": ${this.mouseCoordPercentages.x}, "y": ${this.mouseCoordPercentages.y}}`);

		if (this.tooltipTimeout) {clearTimeout(this.tooltipTimeout)}
		this.showTooltip();

		this.tooltipTimeout = setTimeout(this.removeTooltip, 2000)

	}

	@action.bound showTooltip() {

		this.showSavedTooltip = true;

		this.toolTipSavedPositions.x = this.mouseCoordPercentages.x;
		this.toolTipSavedPositions.y = this.mouseCoordPercentages.y;

	}

	@action.bound removeTooltip() {
		this.showSavedTooltip = false;

	}
}


export default PositioningStore