import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import get from 'lodash/get'

import {
	slugify
} from '../../../helpers'

import {
	BlockVideo,
	BlockImage,
	FQRichTextSection
} from './'
import {
	QueryParamLink,
	Link
} from '../link'

@inject("store") @observer
class FeralQualityInlineWithText extends Component {

	_setFQPosition = (element) => {
		 if (element) {
			let offset = element.getBoundingClientRect().top;

			this.fqposition = offset;
		 }
	};

	_toggleFQ = () => {
		const id = this.props.data.sys.id;

		this.props.store.uiStore.toggleFeralQualityText(id);

		this.props.store.uiStore.addfqtolist(this.props.data.fields.feralQuality.fields.name);
	}

	_toggleLB = (caption) => {
		this.props.store.uiStore.togglePosterLightbox(caption);
	}

	_renderClosed() {
		const feralQuality = get(this.props.data, "fields.feralQuality.fields.name", "") || get(this.props.data, "fields.name", "");

		const c = get(this.props.data, "fields.feralQuality.fields.color", {});
		const color = {
			color: `rgb(${c.r}, ${c.g}, ${c.b})`,
			borderColor: `rgb(${c.r}, ${c.g}, ${c.b})`
		};

		return (
			<div className="fq-section" onClick={this._toggleFQ}>
				<h3 style={color} className="fq-section__name"><span>Feral Quality</span></h3>
			</div>
		)
	}

	_renderOpen() {
		const expandableCaptionPresent = get(this.props.data, "fields.expandableAnalysisText") ? true : false;
		const expandableCaption = get(this.props.data, "fields.expandableAnalysisText");
		const getDescriptionRichText = get(this.props.data, "fields.feralQuality.fields.shortDescriptionRichText");
		let description = get(this.props.data, "fields.feralQuality.fields.shortDescription");
		const analysis = get(this.props.data, "fields.analysisText");
		const feralQuality = get(this.props.data, "fields.feralQuality.fields.name", "");
		const c = get(this.props.data, "fields.feralQuality.fields.color", {});
		const color = {
			color: `rgb(${c.r}, ${c.g}, ${c.b})`,
			borderColor: `rgb(${c.r}, ${c.g}, ${c.b})`
		};

		let inlineColor = {color: 'inherit'};
		let expandableAnalysisMedia = "";
		let analysisText = [];
		let cutoff = false;
		let descriptionRichText = [];

		if (getDescriptionRichText) {
			description = getDescriptionRichText.content.map((desc) => {
				desc.content.forEach((node, idx) => {
					let markClasses = '';
					if (node.marks && node.marks.length) {
					   markClasses = node.marks.map((mark) => {
							inlineColor = {color: 'inherit'};
							if (mark.type === "bold") {
								inlineColor = color;
							}
							return mark.type
						})
						markClasses.join(" ");
					} else {
						inlineColor = {color: 'inherit'};
					}

					if (node.nodeType === "entry-hyperlink") {
						// const type = node.data.target.sys.contentType.sys.id;
						// if (type === "poster") {
						// 	const slug = node.data.target.fields.slug;
						// 	inlineColor = color;
						// 	descriptionRichText.push(<Link to="critter.view" style={inlineColor} params={{id: slug}}><span>{node.content[0].value}</span></Link>)
						// } else if (type === "essay") {
						// 	const title = get(node.data, "target.fields.displayTitle", "");
						// 	const slug = get(node.data, "target.fields.slug", "");
						// 	inlineColor = color;
						// 	descriptionRichText.push(<QueryParamLink key={idx} to="modal" params={{text: slug, ttype: type}}><span style={inlineColor}>{title}</span></QueryParamLink>)
						// }

						let fqslug = slugify(node.content[0].value);
						inlineColor = color;
						if (fqslug) {
							descriptionRichText.push(<QueryParamLink key={idx} to="modal" params={{text: `fq-${fqslug}`, ttype: 'essay', rr: 'true'}}><span style={inlineColor}>{node.content[0].value}</span></QueryParamLink>)
						} else {
							descriptionRichText.push(<span key={`${idx}-link`} style={inlineColor} className={`${markClasses}`}>[link for {node.content[0].value}  is temporarily broken]</span>);
						}
					}

					if (!this.props.isDuplicate) {
						descriptionRichText.push(<span key={idx} style={inlineColor} className={`${markClasses}`} dangerouslySetInnerHTML={{__html: window.marked(node.value || "", {renderer: window.markedRenderer})}}></span>)
					}

				})
			})
		} else {
			descriptionRichText = description;
		}

		if (expandableCaption) {
			expandableCaption.content.map((section, i) => {
				if (section.nodeType === "embedded-entry-block") {

					if (section.data.target) {
						const type = section.data.target.sys.contentType.sys.id;
						switch (type) {
							case "mediaFile":
								const contentType = section.data.target.fields.media.fields.file.contentType;
								analysisText.push(<BlockImage key={`${i}-img`} data={section.data}/>)
						}
					}
				} else if (section.nodeType === "paragraph") {
					analysisText.push(<FQRichTextSection key={`${i}-fqr`} data={section}/>)
				}
			})
		}


		return (
			<div className="fq-section">
				<h3 style={color} className="fq-section__name"></h3>
				{descriptionRichText} {analysisText}
				{cutoff && <span className="toggle see-more" onClick={() => this._toggleLB(expandableCaption.content)}> See more...</span> }
			</div>
		)
	}


	render() {
		const {poster__activeFQ} = this.props.store.uiStore;
		const posters = this.props.store.uiStore.poster__activeFQs;
		const contents = this.props.store.uiStore.poster__clickedfq === this.props.data.fields.feralQuality.fields.name ? this._renderOpen() : this._renderClosed();
		let finalPos = "0px"
		if (this.props.pos && this.fqposition) finalPos = Math.floor(this.props.pos - this.fqposition);

		return (
			<div className="poster__body__fq" ref={this._setFQPosition} style={{marginTop: finalPos}}>
				{contents}
			</div>
		)
	}
}

export default FeralQualityInlineWithText
