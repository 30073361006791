import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	Link
} from '../general/link'

@inject("store") @observer
class Key extends Component {


	render() {
		if (this.props.store.routerStore.currentName === "superindex") return null
			
		return (
			<Link to="superindex">
				<video ref="video" className="key" src="/images/fa-key-300.mp4" autoPlay loop muted></video>
			</Link>
		)
	}
}

export default Key