import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	QueryParamLink
} from '../../general/link'

// import {
// 	ContentDrawerTextLink
// } from './'

import get from 'lodash/get'

@inject("store") @observer
class ContentDrawerMenu extends Component {

	_renderMenuItems(){
		const {pageData, routerStore} = this.props.store;
		let linkedTexts = get(pageData, "fields.linkedTexts");
		let categoryOne = [];
		let categoryTwo = [];
		let categoryThree = [];
		let categoryFour = [];

		if (routerStore.current.name === "landing" || routerStore.current.name === "critter.view" || routerStore.current.name === "infrastructure.view") {
			linkedTexts = this.props.store.siteDataStore.data.essay
		}
		if (!linkedTexts) return null;

		if (routerStore.current.name === "landing") {
			linkedTexts.map((text) => {
				if (text.fields.textCategory === "Introductory Essays") {
					categoryOne.push(<QueryParamLink to="body_text" params={{bdtext: text.fields.slug}}><h5 className="cd__menu__link">{text.fields.readingRoomDisplayTitle ? text.fields.readingRoomDisplayTitle : text.fields.displayTitle}</h5></QueryParamLink>);
				}
			})
		} else if (routerStore.current.name === "critter.view") {
			const texts = [];
			linkedTexts.map((text) => {
				if (text.fields.textCategory === "Feral Qualities") {
					texts.push(text);
				}
			})
			texts.sort((a, b) => {
				if (a.fields.slug < b.fields.slug) {
					return -1
				} else if (a.fields.slug > b.fields.slug) {
					return 1
				}

				return 0
			})

			texts.forEach(text => {
				categoryOne.push(<QueryParamLink to="body_text" params={{bdtext: text.fields.slug}}><h5 className="cd__menu__link">{text.fields.readingRoomDisplayTitle ? text.fields.readingRoomDisplayTitle : text.fields.displayTitle}</h5></QueryParamLink>);
			})

			const fqEssay = categoryOne.splice(2,1);
			categoryOne.unshift(fqEssay);
			
		} else if (pageData.sys && pageData.sys.contentType.sys.id === "landscape") {
			linkedTexts.map((text) => {
				if (text.fields.textCategory === "Luminary Essays") {
					categoryOne.push(<QueryParamLink to="body_text" params={{bdtext: text.fields.slug}}><h5 className="cd__menu__link">{text.fields.readingRoomDisplayTitle ? text.fields.readingRoomDisplayTitle : text.fields.displayTitle}</h5></QueryParamLink>);
				} else if (text.fields.textCategory === "Anthropocene Detonators" || text.fields.textCategory === "Introductory Essays") {
					categoryTwo.push(<QueryParamLink to="body_text" params={{bdtext: text.fields.slug}}><h5 className="cd__menu__link">{text.fields.readingRoomDisplayTitle ? text.fields.readingRoomDisplayTitle : text.fields.displayTitle}</h5></QueryParamLink>);
				} else if (text.fields.textCategory === "Feral Atlas: The Art of Transdisciplinary Storytelling" || text.fields.textCategory === "Artist Statements") {
					categoryThree.push(<QueryParamLink to="body_text" params={{bdtext: text.fields.slug}}><h5 className="cd__menu__link">{text.fields.readingRoomDisplayTitle ? text.fields.readingRoomDisplayTitle : text.fields.displayTitle}</h5></QueryParamLink>);
				}
			})
		} else if (pageData.sys && pageData.sys.contentType.sys.id === "infrastructure") {
				// Object.keys(this.props.store.siteDataStore.infrastructureDataByName).forEach(key => {
				// 	categoryOne.push(<QueryParamLink to="body_text" params={{bdtext: key}}><h5 className="cd__menu__link">{key}</h5></QueryParamLink>);
				// })
				linkedTexts.map((text) => {
					if (text.fields.textCategory === "Tippers") {
						categoryOne.push(<QueryParamLink to="body_text" params={{bdtext: text.fields.slug}}><h5 className="cd__menu__link">{text.fields.readingRoomDisplayTitle ? text.fields.readingRoomDisplayTitle : text.fields.displayTitle}</h5></QueryParamLink>);
					} else if (text.fields.textCategory === "Feral Atlas: The Art of Transdisciplinary Storytelling" || text.fields.textCategory === "Artist Statements") {
						// categoryTwo.push(<QueryParamLink to="body_text" params={{bdtext: text.fields.slug}}><h5 className="cd__menu__link">{text.fields.readingRoomDisplayTitle ? text.fields.readingRoomDisplayTitle : text.fields.displayTitle}</h5></QueryParamLink>);
					}
				})
		} else {
				linkedTexts.map((text) => {
					return <QueryParamLink to="body_text" params={{bdtext: text.fields.slug}}><h5 className="cd__menu__link">{text.fields.readingRoomDisplayTitle ? text.fields.readingRoomDisplayTitle : text.fields.displayTitle}</h5></QueryParamLink>
				})
		}

		return (
			<div>
				{categoryOne.length > 0 &&
					<div className="drawer-menu-section">{categoryOne}</div>
				}
				{categoryTwo.length > 0 &&
					<div className="drawer-menu-section">{categoryTwo}</div>
				}
				{categoryThree.length > 0 &&
					<div className="drawer-menu-section">{categoryThree}</div>
				}
			</div>
		)
	}

	render() {
		const {pageData} = this.props.store;
		const menuItems = this._renderMenuItems();
		if (!menuItems) return null

		return (
			<div className="cd__menu">
				<div className="cd__menu__inner">
					{menuItems}
				</div>
			</div>
		)
	}
}

export default ContentDrawerMenu
