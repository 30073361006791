import React, { Component } from 'react';
import {inject} from 'mobx-react'

import Surface from './Surface.js';
import get from 'lodash/get'
// import './App.css';
import {slugify} from '../../../../helpers'


@inject("store")
class App extends Component {
  constructor(props){
  	super(props);

  	this.state = {
  		surfaceList : [],
      drawerList : []
  	}

    this.shuffleArray = this.shuffleArray.bind(this);
  }

  shuffleArray(critters){
    const array = critters;
    if(array.length === 0 ) {return [] }
    let i = array.length, k , temp;
    while(--i > 0){
      k = Math.floor(Math.random() * (i+1));
      temp = array[k];
      array[k] = array[i];
      array[i] = temp;
    }
    // shuffled array:
    this.setState({surfaceList : array}, () => {
      this.alphaArray(critters)
    })
  } 

  alphaArray(critters){
    const array = critters;
    array.sort(function(a, b) {
      var titleA = a.title.toUpperCase();
      var titleB = b.title.toUpperCase();
      if (titleA < titleB) { return -1; }
      if (titleA > titleB) { return 1; }
      return 0;
    });

    // alphabetical array
    this.setState({ drawerList: array })
  }

  // fetchCritters(){
  	// fetch('/files/critters.json')
 		// .then(r => r.json())
 		// .then(critters => {
   //    if(!Array.isArray(critters)){ return; }
      
   //    this.shuffleArray(critters)
 		// })
  // }

  componentDidMount(){
  	this.generateCritters()
  }

  generateCritters() {
    const array = [];

    this.props.store.siteDataStore.data.poster.forEach((poster, i) =>  {
      const imageSrc =slugify(get(poster, "fields.critter.fields.image.fields.title", ""));
      const critterName = get(poster, "fields.critter.fields.name");
      // const posterT = get(poster, "fields.title");

      const landscapeName = get(poster, "fields.landscape[0].fields.name", "");
      const slug = get(poster, "fields.slug");
      const title = get(poster, "fields.title");


      const item = {
        "title" : critterName,
        "category" : landscapeName,
        "slug": slug,
        "imagepath": `/images/archive-images/${imageSrc}-300.png`
      };

      array.push(item);
    });
    
    this.shuffleArray(array);

    // this.setState({
    //   surfaceList: array
    // })
  }

  render() {


    return (
      <div className="App">
        <Surface critters={this.state.surfaceList} shuffleArray={this.shuffleArray} />
      </div>
    );
  }
}

export default App;
