import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {Link} from '../../../components/general/link'

import get from 'lodash/get'

@inject("store") @observer
class TitleCard extends Component {
	render() {
		const {landing__activePoster} = this.props.store.uiStore;

		if (!landing__activePoster) return null

		return (
			<div className="landing__title-card">
					<TitleCardCritter/>
					<TitleCardLandscapes/>
			</div>
		)
	}
}

@inject("store") @observer
class TitleCardLandscapes extends Component {
	_renderLandscapes() {
		const {posterDataByName} = this.props.store.siteDataStore;
		const {landing__activePoster} = this.props.store.uiStore;

		const landscapeName = get(posterDataByName[landing__activePoster], "fields.landscape[0].fields.name", "");

		return this.props.store.siteDataStore.data.landscape.map((landscape, i) => {
			return <h5 key={i} className={landscapeName === landscape.fields.name ? "landing__title-card__landscape landing__title-card__landscape--active" : "landing__title-card__landscape"}>{landscape.fields.name}</h5>
		})
	}

	render() {
		const landscapes = this._renderLandscapes();

		return (
			<div className="landing__title-card__landscapes">
				{landscapes}
			</div>
		)
	}

}

@inject("store") @observer
class TitleCardCritter extends Component {
	render() {
		const {posterDataByName} = this.props.store.siteDataStore;
		const {landing__activePoster} = this.props.store.uiStore;

		const critterName = get(posterDataByName[landing__activePoster], "fields.critter.fields.name", "");

		return (
			<h2 className="landing__title-card__critter">
				{critterName}
			</h2>
		)
	}
}

export default TitleCard
