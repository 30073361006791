import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import times from 'lodash/times';
import Draggable from 'react-draggable'; // The default

import {
	PointOfInterestOverlayLocal,
	LandscapeMapCanvas
} from './subcomponents'

import {Link} from '../../components/general/link'

import get from 'lodash/get';
import {slugify} from '../../helpers';


@inject("store") @observer
class Landscape extends Component {

	componentWillMount() {
		this.props.store.uiStore.setActiveLandscapePoster(null);
	}

	componentDidMount() {
		this.props.store.uiStore.registerPageVisit("landscape", this.props.store.routerStore.current.params.id);
	}

	render() {
		// const landscape = this.props.store.siteDataStore.landscapeDataByName['capital'];
		// const image = landscape.fields.map.fields.file.url

		return (
			<div className="landscape">
				<ExplosionOverlay/>
				<LandscapeMapCanvas key={Math.random()}/>
				<PointOfInterestOverlayLocal/>
			</div>
		)
	}
}

class ExplosionOverlay extends Component {
	state = {
		show: true,
		fade: false
	}

	triggerRemoval = () => {
		setTimeout(this.fade, 1400)

		setTimeout(this.hide, 3400)
	}

	fade = () => {
		this.setState({
			fade: true
		});
	}

	hide = () => {
		this.setState({
			show: false
		});
	}

	render() {
		if (!this.state.show) return null
				// <video  onPlay={this.triggerRemoval} src="/images/bomb-test.mp4" autoPlay muted></video>

		return (	 
			<div className={this.state.fade ? "landscape__explosion fade" : "landscape__explosion"}>
				<video  onPlay={this.triggerRemoval} src="/images/explosion-final.mp4" autoPlay muted></video>

			</div>
		)
	}
}
export default Landscape