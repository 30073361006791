import React from 'react'

import get from 'lodash/get'

import {
	Credits
} from '../text'

const BlockVideo = (props) => {
	const videoSrc = get(props.data, "target.fields.media.fields.file.url")
	const credits = get(props.data, "target.fields.attribution")
	const caption = get(props.data, "target.fields.caption");
	const size = get(props.data, "target.fields.embeddedImageSize");

	return (
		<div className="poster__body__block">
			<div className={`poster__body__block__image-wrap ${size ? `poster__body__block__image-wrap--${size}` : ""}`}>
				<video className="poster__body__block__image" src={videoSrc} controls></video>
				<Credits data={props.data} type="target" location="body" />
			</div>
		</div>
	)
}

export default BlockVideo
