import React, {Component} from 'react'

import {
	TitleCard,
	CritterCanvasWebGL
} from './subcomponents'

import LukasAndRahul from './subcomponents/lukas-and-rahul-landing/LukasAndRahul.js'

class Landing extends Component {
	render() {
		return 	<LukasAndRahul></LukasAndRahul>

		return (
			<div className="">
				<TitleCard/>
				<CritterCanvasWebGL/>
			</div>
		)
	}
}



export default Landing