import React, {Component} from 'react'
import {inject} from 'mobx-react'

@inject('store')
class Link extends Component {
  render() {
    const {to, params, store} = this.props;

    const href = this.props.external ? to : store.routerStore.router.buildPath(to, params);
    
    if (href === null) {
      console.error("<Link> Couldn't make URL for", to, params);
    }

    return (
      <a style={this.props.style} onMouseEnter={this.props.onMouseEnter || null} onMouseLeave={this.props.onMouseLeave || null} href={href} target={this.props.external ? "_blank" : null} rel={this.props.external ? "noreferrer" : null} className={this.props.className} onClick={this.onClick.bind(this)}>
        {this.props.children}
      </a>
    )
  }

  onClick(event) {


    if (!this.props.external) {
      event.preventDefault();

      if (this.props.activePoster) {
        this.props.store.uiStore.setActiveLandingPoster(this.props.activePoster)
      }

      if (this.props.activeClic) {
        this.props.store.uiStore.setActiveCLIC(this.props.activeClic);
      }

      const {to, params, options, store} = this.props;
      store.routerStore.router.navigate(to, params, options);
    }
  }
}



export default Link;