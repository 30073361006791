import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

@inject("store") @observer
class LandscapeCoordinateValues extends Component {
	render() {
		if (!this.props.store.positioningStore.activeLandscape) return null

		const {x,y} = this.props.store.positioningStore.mouseCoordinates
		const mouseCoordPercentages = this.props.store.positioningStore.mouseCoordPercentages
		return (
			<div className="positioning-app__coords">
				<h3>{x}, {y}</h3>
				<h3>{mouseCoordPercentages.x}%, {mouseCoordPercentages.y}%</h3>
			</div>
		)
	}
}

export default LandscapeCoordinateValues