import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'
import {Link} from '../../../components/general/link'

import {
	InfrastructureBodyImage
} from './'

import {
	ResponsiveImage
} from '../../../components/general/image'

import {
	slugify
} from '../../../helpers'

import get from 'lodash/get'


@inject("store") @observer
class InfrastructureActivePosterLinks extends Component {

	render() {
		const {pageData, uiStore: {activePoster}, siteDataStore: {posterDataByName}} = this.props.store;
		if (!activePoster) return null

		const activePosterData= posterDataByName[activePoster];
		const critter = this.props.store.siteDataStore.posterDataByName[activePoster];
		const landscapeConnection = get(critter, "fields.critterLandscapeInfrastructureConnection");
		let seealso = landscapeConnection && landscapeConnection[1] ? landscapeConnection[1].fields.infrastructure.fields.name : '';
		let activeClic = landscapeConnection && landscapeConnection[1] ? landscapeConnection[1].sys.id : null;

		const infrastructureName = get(pageData,"fields.name");

		if (landscapeConnection && landscapeConnection[1]) {
			if (infrastructureName === landscapeConnection[1].fields.infrastructure.fields.name) {
				seealso = landscapeConnection[0].fields.infrastructure.fields.name
				activeClic = landscapeConnection[0].sys.id
			}
		}

		return (
			<div className="infrastructure__active-poster-links">
				{seealso &&
					<Link to="infrastructure.view" params={{id: slugify(seealso)}} activeClic={activeClic}>
						<div className="infrastructure__see-also">
							<h5><span className="see-also">See Also</span> <span style={{textTransform: "uppercase"}}>{seealso}</span> <img src="/images/arrow-right-cropped.svg" alt=""/></h5>
						</div>
					</Link>
				}

				<Link to="critter.view" params={{id: activePoster}}>
					<div className="infrastructure__continue">
						<h5><span className="infrastructure__continue-dot"></span><span>Continue to Field Report</span> <img src="/images/arrow-right-cropped.svg" alt=""/></h5>
					</div>
				</Link>
			</div>
		)
	}
}



export default InfrastructureActivePosterLinks
