import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	WebGL2DContext
} from './'

import {
	getRand,
	slugify
} from '../../../helpers'

import get from 'lodash/get'


@inject("store") @observer
class CritterCanvasWebGL extends Component {
	constructor(props) {
		super(props);

		this.gl2D = null;
		this.image = new Image();
		this.image.crossOrigin = "anonymous";
		this.texture = null;
		this.image.src = "https://images.ctfassets.net/zj7ld17eklg3/1uiFrtSinkFA1eSnroxJBC/26c2d8d966f689a750ae5498919ec791/argentine_ants.png?&fm=png&w=256&q=80"

		this.x = 10;
		this.y = 0;
		this.textures = [];
		this.slugs = [];
		this.critterManagers = [];

		const x = .91 * 1.5;
		const y = .42 * 1.5;
		
		const x2 = .6 * 1.2;
		const y2 = .8 * 1.2;	
	
		this.initialPositions = [
			[x,y],
			[x, -y],
			[-x, y],
			[-x, -y],

			[x2,y2],
			[x2, -y2],
			[-x2, y2],
			[-x2, -y2],

			[0,.8],
			[0,-.8],
			[.8,0],
			[-.8,0]

		];

		this.numReady = 0;
		this.imgCount = 0;
		this.image.onload = () => {
			this.texture = this.gl2D.textureFromImage(this.image);
		}
 
	}

	handleImageLoad(image, slug) {
		this.numReady++;
		const texture = this.gl2D.textureFromImage(image);
		this.textures.push(texture);
		this.slugs.push(slug);

		if(this.numReady === this.imgCount) {
			this._init()
		}

	}

	componentDidMount() {
		this._setup();
	}


	_setup() {
		let canvas = this.refs.canvas;
		this.gl2D = new WebGL2DContext(canvas);
		
		this.props.store.siteDataStore.data.poster.forEach((poster, i) =>  {
			const imageSrc = get(poster, "fields.critter.fields.image.fields.file.url");
			const slug = get(poster, "fields.slug")
			if (imageSrc) {
				this.imgCount++;

				const image = new Image();
				image.crossOrigin = "anonymous";
				image.src = `${imageSrc}?fm=png&w=150`;
				image.onload = this.handleImageLoad.bind(this, image, slug)
			}

		})
	}

	_init() {

		for (var i = 0; i < this.initialPositions.length; i++) {
			const pos = this.initialPositions[i];

			const critterManager = new CritterManager(new Vector(pos[0], pos[1]), 200, this.textures, this.slugs);
			this.critterManagers.push(critterManager);
		}


		this.loop();
	}

	_handleMouseMove = (e) => {
		e.persist();

		var i, hit;
		for (i = 0; i < this.critterManagers.length; i++) {
			hit = this.critterManagers[i].checkHit({x: e.clientX, y: e.clientY});
			if (hit) {
				this.props.store.uiStore.setActiveLandingPoster(hit.slug)
				return
			}
		}

		this.props.store.uiStore.setActiveLandingPoster(null)
	}

	_handleMouseDown = () => {
		const {landing__activePoster} = this.props.store.uiStore;

		if (landing__activePoster) {
			const {posterDataByName} = this.props.store.siteDataStore;

			const landscape = get(posterDataByName[landing__activePoster], "fields.landscape[0].fields.name", "");

			this.props.store.routerStore.router.navigate("landscape.view", {id: slugify(landscape)},{});

		}
	}

	update() {
		var i;
		for (i = 0; i < this.critterManagers.length; i++) {
			this.critterManagers[i].update()
		}
	}

	draw() {
		var i;
		for (i = 0; i < this.critterManagers.length; i++) {
			this.critterManagers[i].draw(this.gl2D)
		}
	}

	loop() {
		//clear the buffer
		this.gl2D.startScene();
		
		this.update()
		this.draw()

		//bind buffers
		this.gl2D.endScene();
		
		//render images
		this.gl2D.renderScene();
		this.x++;

		requestAnimationFrame(this.loop.bind(this))
	}

  	
	drawImage(texture, x,y) {
		this.gl2D.drawImage(texture,[0,0,150,150],[x,y ,150,150],[255,255,255,255]);
	}

	render() {
		return (
			<canvas 
				onMouseMove={this._handleMouseMove} 
				onMouseDown={this._handleMouseDown} 
				className={this.props.store.uiStore.landing__activePoster ? "critter-canvas critter-canvas--cl" : "critter-canvas"} 
				ref="canvas" 
				width={this.props.store.uiStore.windowWidth} 
				height={this.props.store.uiStore.windowHeight}>
			</canvas>
		)
	}
}

class Critter {
	constructor(texture, _x, _y, _dir, _slug) {
		this.texture = texture;

		this.x = _x;
		this.y = _y;
		this.dim = 150;
		this.slug = _slug;

		const dirNormalized = _dir.getNormalized()
		this.speed = new Vector(dirNormalized.x, dirNormalized.y)

		this.replacementSpawned = false;
	}

	update() {
		this.x += this.speed.x / 2;
		this.y += this.speed.y / 2;
	}

	getX() {
		return this.x - (this.dim / 2)
	}

	getY() {
		return this.y - (this.dim / 2)
	}

	getCoords() {
		return {
			x: this.getX(),
			y: this.getY()
		}
	}

	checkHit(coords) {
		// this.x , this.x + dim;
		// this.y, this.y + dim
		if (coords.x > this.getX() && coords.x < this.getX() + this.dim && coords.y > this.getY() && coords.y < this.getY() + this.dim ) {
			return true
		}

		return false
	}

	draw(context) {
		context.drawImage(this.texture,[0,0,this.dim,this.dim],[this.getX(),this.getY(),this.dim,this.dim],[255,255,255,255]);
	}

	isOutsideBoundaries(width, height) {
		const {x,y} = this.getCoords()

		if (x < 0 || x > width || y + (this.dim / 2 ) < 0 || y + (this.dim / 2) > height) {
			return true
		}

		return false
	}

	isOutsideSpawnRadius(spawnPos, spawnRadius) {
		const {x,y} = this.getCoords()
		

		const distance = Math.sqrt(Math.pow(x + (this.dim / 2 ) - spawnPos.x, 2) + Math.pow(y + (this.dim / 2 ) - spawnPos.y, 2))   

		if (distance > spawnRadius) {
			return true
		}

		return false;
	}

}

class CritterManager {
	constructor(_dir, _magnitude, _textures, _slugs) {
		const windowWidth = window.innerWidth;
		const windowHeight = window.innerHeight;

		const x = (windowWidth / 2) + (_dir.x * _magnitude);
		const y = (windowHeight / 2) + (_dir.y * _magnitude);

		this.pos = new Vector(x, y);
		this.dir = _dir;
	
		this.textures = _textures;
		this.slugs = _slugs
		this.critters = [];

		this.spawnRadius = _magnitude / 3;
		this.totalRadius = _magnitude * 1.5;

		this.windowWidth = windowWidth;
		this.windowHeight = windowHeight;
		
		this.createCritter()
	}

	update() {
		var i, length;
		var length = this.critters.length;

		for (i = 0; i < length; i++) {
			this.critters[i].update();
			
			if (
				!this.critters[i].replacementSpawned && 
				this.critters[i].isOutsideSpawnRadius(this.pos, this.totalRadius)) {

				this.critters[i].replacementSpawned = true;
				this.createCritter()

			}

			if (this.critters[i].isOutsideBoundaries(this.windowWidth, this.windowHeight)) {
				this.critters.splice(i, 1);
				length--;
				i--;
			}
		}
	}

	draw(context) {
		var i;
		for (i = 0; i < this.critters.length; i++) {
			this.critters[i].draw(context);
		}
	}

	drawBounds() {

	}

	checkHit(coords) {
		var i, hit;
		for (i = 0; i < this.critters.length; i++) {
			hit = this.critters[i].checkHit(coords);
			if (hit) {
				return this.critters[i]
			}
		}

		return false
	}

	createCritter() {
		const critterIdx = Math.floor(getRand(0,this.textures.length))
		const newCritter = new Critter(this.textures[critterIdx], this.pos.x, this.pos.y, this.dir, this.slugs[critterIdx])
		this.critters.push(newCritter);
	}

	deleteCritter() {
		// perhaps instead of deleting critters we should just enque them
	}

	registerCritter() {
		// to make sure no duplicates?
		// not sure if it's tiny tho....
	}

	unregisterCritter() {
		// to ensure less duplication?
		// what if it's huge
	}

	toggleBounds() {

	}
}

class Vector {
	constructor(_x,_y) {
		this.x = _x;
		this.y = _y;
	}

	mag() {
		return Math.sqrt(this.x*this.x + this.y*this.y);
	}

	normalize() {
		var m = this.mag();
		if (m > 0) {
		 	this.div(m);
		}
	}

	getNormalized() {
		return new Vector(this.x,this.y);
	}
}

export default CritterCanvasWebGL