import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'
import {
	QueryParamLink
} from '../../general/link'

@inject("store") @observer
class ContentDrawerHamburger extends Component {

	render() {
		const {drawer__openStage1} = this.props.store.uiStore;

		return (
			<QueryParamLink to={drawer__openStage1 ? null : "content_drawer"}>
				<div className="cd__hamburger">
					<div></div>
					<div></div>
					<div></div>
				</div>
			</QueryParamLink>
		)
	}
}

export default ContentDrawerHamburger