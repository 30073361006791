import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import get from 'lodash/get'

@inject("store") @observer
class LandscapeImage extends Component {

	_handleMouseMove = (e) => {
		// this.props.store.positioningStore.setMouseCoordinates({x: e.pageX, y: e.pageY});
		// console.log(e.offsetX, e.pageY, e)

		requestAnimationFrame(this.props.store.positioningStore.setMouseCoordinates.bind(null, {x: e.pageX, y: e.pageY}))
	}

	_handleMouseDown = () => {
		
		this.props.store.positioningStore.copyDataToClipboard()
		
	}

	render() {
		if (!this.props.store.positioningStore.activeLandscape) return null

		const {landscapeDataByName} = this.props.store.siteDataStore;
		const activeLandscapeData = landscapeDataByName[this.props.store.positioningStore.activeLandscape];

		const imageSrc = get(activeLandscapeData, "fields.map.fields.file.url");

		return (
			<div className="positioning-app__landscape-image" onMouseMove={this._handleMouseMove} onMouseDown={this._handleMouseDown}>
				<img className="positioning-app__landscape-image" src={imageSrc} alt=""/>
			</div>
		)
	}
}

export default LandscapeImage