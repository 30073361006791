import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'
import {
	FQPosterReferences,
	PosterReferences,
	PosterAcknowledgments
} from './'

import {
	BlockImage,
	BlockVideo,
	InlineImage,
	InlineAudio,
	FeralQualityBlock,
	RichTextSection,
	EditorsNote
} from '../../../components/general/text'

import {slugify} from '../../../helpers'
import {CritterCarousel} from '../../../components/slideshow'
import get from 'lodash/get'
import {toJS} from 'mobx'

@inject("store") @observer
class PosterBody extends Component {
	_renderContentSections(allfqs) {
		const {pageData} = this.props.store;
		const data = get(pageData, "fields.textContent.content", [])

		return data.map((section, idx) => {
			if (section.nodeType === "embedded-entry-block") {

				const type = section.data.target.sys.contentType.sys.id;
				if (section.data.target.fields) {
					switch (type) {
						case "mediaFile":
							if (section.data.target.fields.media) {
								const contentType = section.data.target.fields.media.fields.file.contentType;
								if (contentType === "video/mp4") {
									return <BlockVideo key={`${idx}-${section.nodeType}`} data={section.data}/>
								} else {
									return <BlockImage key={`${idx}-${section.nodeType}`} data={section.data}/>
								}
							}
						case "editorsNote":
							return <EditorsNote key={`${idx}-${section.nodeType}`} data={section.data}/>
						case "feralQuality":
							return <FeralQualityBlock key={`${idx}-${section.nodeType}`} data={section.data}/>
						case "embeddedFeralQuality":
							return <FeralQualityBlock key={`${idx}-${section.nodeType}`} data={section.data}/>
					}
				}

			}

			return <RichTextSection key={`${idx}-${section.nodeType}`} data={section}/>
		})

	}

	render() {
		const contents = this._renderContentSections(this.props.store.uiStore.poster__allfqs);

		return (
			<div className="poster__body">
				{contents}
				<div className="poster-footer-content">
					<PosterAcknowledgments/>
					<PosterReferences/>
					<FQPosterReferences/>
				</div>
			</div>
		)
	}
}









export default PosterBody
