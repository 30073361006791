import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

@inject("store") @observer
class QueryParamLink extends Component {
	constructor(props) {
		super(props);

		this.schema = props.schema || {
			"content_drawer": {dependencies: [], params: ["cd"]},
			"content_drawer.expanded": {dependencies: [], params: ["cdex"]},
			"reading_room": {dependencies: ["cd", "cdex"], params: ["rr"]},
			"body_text": {dependencies: ["cd"], params: ["bdtext"]},
			"modal": {dependencies: ["cd"], params: ["text", "ttype"]}
		}
	}

	onClick() {
		const {to, remove} = this.props;
		const {routerStore: {current: {name, params: currentParams}}} = this.props.store;
		if (name === "superindex" && Object.keys(currentParams).length === 0 && to === "content_drawer" ) return


		if (remove) {
			this.props.store.routerStore.clearQueryParams(remove);
			return
		}

		if (to === null) {
			this.props.store.routerStore.clearQueryParams();
			return
		}

		const params = this.props.params || {};

		try {
			const queryParams = {};

			this.schema[to].params.forEach(param => {
				queryParams[param] = params[param] || true;
			})

			this.schema[to].dependencies.forEach(param => {
				queryParams[param] = true;
			})

			Object.keys(params).forEach(param => {
				if (!queryParams[param]) {
					queryParams[param] = true;
				}
			})

			this.props.store.routerStore.setQueryParams(queryParams);

		} catch(e) {
			console.error(e)
		}


	}

	render() {
		return (
			<span style={this.props.style} className={`qp-link ${this.props.className || ""}`} onClick={this.onClick.bind(this)}>{this.props.children}</span>
		)
	}
}

export default QueryParamLink
