export function slugify(text) {
  return text.toString().toLowerCase()
    .replace("/", "-")              // NEW for multi-colored items
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}

window.slugify = slugify;

export function getUrlVars(url) {
    var vars = {};
    var parts = url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}

export function getRand(min, max) {

    return Math.random() * (max - min) + min;

};

export function promiseAllReflect(promises = []) {  
  const reflect = promise => promise.then(
    value => ({ value, status: 'fulfilled' }),
    error => ({ error, status: 'rejected' }),
  );

  return Promise.all(promises.map(reflect));
}

export function executeAtLeast(time, func, funcArgs = []) {  
  return promiseAllReflect([
    new Promise(resolve => setTimeout(resolve, time)), 
    func(...funcArgs)
  ]);
}

export function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}