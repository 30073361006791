import React from 'react';
import './CritterMovement.css';
import './Critter.css';

import {Link} from '../../../../components/general/link'

const Critter = (props) => {
  let { startPosition, side, leaning, bobbing, speed, delay, bobbingSpeed, bobbingDelay } = props.animationValues;
  // list item gets one class for animation keyframes
	const sideArray = [["top horizontal", "down"], ["bottom horizontal", "up"], ["left vertical", "right"], ["right vertical", "left"]];
	// if seek is true, it means initial load places item in center
	if(props.seek){ delay = (-1 * delay) - 13 }

  const category = props.item.category.toLowerCase();

  return <li
		className={"item " + sideArray[side][0] + startPosition}
		style={{animation: `${sideArray[side][1]}${leaning} ${speed}s linear ${delay}s `}}
		onAnimationEnd={(e) => { if(e.animationName.includes("bobbing")){ return; } props.onAnimationEnd(props.index) } }
		onMouseOver={props.onMouseOver.bind(null, props.item.slug, category)}
		id={"item-" + props.index}>
		<Link to="landscape.view" params={{id: category}}>
			<img
				loading="lazy"
				className="item_image"
				alt={props.item.title}
				src={props.item.imagepath}
				style={{animation: "bobbing" + bobbing + " " + bobbingSpeed +"s ease-in-out " + bobbingDelay + "s infinite normal none running"}}
				/>
			<aside className="text_container">
				<h1 className="item_title item_text" dangerouslySetInnerHTML={{__html: window.marked(props.item.title || "", {renderer: window.markedRenderer})}}></h1>
				<div className={"categories"}>
					<span className={category === "invasion" ? "item_category active_category" : "item_category"}>Invasion</span>
					<span className={category === "empire" ? "item_category active_category" : "item_category"}>Empire</span>
					<span className={category === "capital" ? "item_category active_category" : "item_category"}>Capital</span>
					<span className={category === "acceleration" ? "item_category active_category" : "item_category"}>Acceleration</span>
				</div>
			</aside>
		</Link>
	</li>;
}

export default Critter;
