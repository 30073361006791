import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'


@inject("store") @observer
class LandscapePositionMarker extends Component {
	render() {
		return null
		// if (!this.props.store.positioningStore.activeLandscape) return null
		// const {x,y} = this.props.store.positioningStore.mouseCoordinates
	
		// const yStyles = {
		// 	transform: `translate3d(0,${y}px,0)`
		// };

		// const xStyles = {
		// 	transform: `translate3d(${x}px,0,0)`
		// };

		// return (
		// 	<div className="">
		// 		<div style={yStyles} className="landscape__posX"></div>
		// 		<div style={xStyles} className="landscape__posY"></div>
		// 	</div>
		// )
	}
}

export default LandscapePositionMarker