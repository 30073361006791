import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	ResponsiveImage
} from '../../../components/general/image'

import {
	QueryParamLink,
	Link
} from '../../../components/general/link'

import {
	slugify
} from '../../../helpers'

import get from 'lodash/get'

@inject("store") @observer
class PosterSubheader extends Component {
	render() {
		const {pageData, siteDataStore: {posterDataByCritter}} = this.props.store;

		const title = pageData.fields.thoughtBomb || pageData.fields.title;
		const authorNames = get(pageData, "fields.author", []);
		let authors  = [];

		authorNames.map((author, i) => {
			if (i === authorNames.length - 2) {
				authors.push(<span><QueryParamLink key={i} to="modal" params={{ttype: 'contributor', text: `${author.fields.slug}`}}><span>{`${author.fields.name}`}</span></QueryParamLink> & </span>)
			} else if (i === authorNames.length - 1) {
				authors.push(<span><QueryParamLink key={i} to="modal" params={{ttype: 'contributor', text: `${author.fields.slug}`}}><span>{`${author.fields.name}`}</span></QueryParamLink> </span>)
			} else {
				authors.push(<span><QueryParamLink key={i} to="modal" params={{ttype: 'contributor', text: `${author.fields.slug}`}}><span>{`${author.fields.name}`}</span></QueryParamLink>, </span>)
			}
		})

		const image = slugify(get(pageData, "fields.critter.fields.image.fields.title", ""));

		const type = title.length > 45 ? "poster__subheader--type-1" : "poster__subheader--type-2";

		const critter = get(pageData, "fields.critter");
		const critterName = get(pageData, "fields.critter.fields.name");
		const critterSlug = slugify(get(pageData, "fields.critter.fields.name", ""));
		const seeMorePosters = posterDataByCritter[critterSlug];

		return (
			<div className={`poster__subheader ${type}`}>
				<div className="image-and-see-more">
					<img className="poster__subheader__critter" src={`/images/archive-images/${image}-300.png`} alt=""/>
					{seeMorePosters.length > 1 &&
						<h5>See also:</h5>
					}
					{seeMorePosters.length > 1 && seeMorePosters.map((poster,i) => {
							if (poster.fields.title !== pageData.fields.title) {
								const etAl = poster.fields.author.length > 1 ? "et al." : "";

								return (
									<span key={i}>{poster.fields.author[0].fields.name} {etAl} for another report on <a key={i} style={{textTransform:"uppercase"}}href={poster.fields.slug}>{critterName}</a></span>
								)
							}
						})
					}
				</div>

				<div className="titles">
					<h3 className="poster__subtitle poster">
						{title}
					</h3>

					<h3 className="poster__author poster">
						{authors}
					</h3>
				</div>
			</div>
		)
	}
}


export default PosterSubheader
