import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	ResponsiveImage
} from '../general/image'

import {
	Link
} from '../general/link'

import {
	LeftArrow,
	RightArrow
} from './subcomponents'

import {
	GatedLinkHOC
} from '../gatedlink'

import {
	slugify
} from '../../helpers'

import get from 'lodash/get'

@inject("store") @observer
class CritterCarousel extends Component {

	constructor(props) {
		super(props);

		this.state = {
			index: 0,
			hovered: false
		};

		this.w = 100;
		this.maxLength = this.props.posters ? this.props.posters.length - 5 : 0;
	}


	_decrement = () => {
		var index  = this.state.index - 1;

		const min = 0
		if (index < min) {
			index = min
		};


		this.setState({
			index
		})
	}

	_increment = () => {
		var index  = this.state.index + 1;


		if (index > this.maxLength) {
			index = this.maxLength
		};

		this.setState({
			index
		})
	}

	_turnOnHover = () => {
		this.setState({
			hovered: true
		})
	}

	_turnOffHover = () => {
		this.setState({
			hovered: false
		})
	}

	_renderCritters() {
		const {posters} = this.props;
		const {index: carouselIndex} = this.state;

		return posters.map((poster, idx) => {
			const critterImage = slugify(get(poster, "fields.critter.fields.image.fields.title", ""));
			const critterName = get(poster, "fields.critter.fields.name", "");
			const slug = poster.landscapeConnection ? poster.landscapeConnection : get(poster, "fields.landscape[0].fields.slug");

			const posterSlug = get(poster, "fields.slug")
			const visited = true;

			return (
				<div key={idx} className={`critter__carousel__image-wrap ${idx > carouselIndex + 2 || idx < carouselIndex ? "critter__carousel__image-wrap--hidden" : ""} ${visited ? "visited" : ""}`}>
					<Link to="landscape.view" params={{id: slug}} activePoster={posterSlug}>
						<img src={`/images/archive-images/${critterImage}-300.png`} alt=""/>
					</Link>

					<h6 className="critter__carousel__critter-name" dangerouslySetInnerHTML={{__html: window.marked(critterName || "", {renderer: window.markedRenderer})}}></h6>
				</div>
			)
		})
	}



	render() {
		if (!this.props.posters) return null
		const {index, hovered} = this.state;

		const critters = this._renderCritters();

		const style = {
			transform: `translate3d(-${this.w * this.state.index}px,0,0)`,
			width: this.props.posters.length * this.w
		};

		return (
			<div className="wrapper critter__carousel-wrapper">
				<LeftArrow className={`${index === 0 ? "hide" : ""} critter__carousel__arrow critter__carousel__arrow--left`} onClick={this._decrement}/>
				<div className={hovered ? "critter__carousel critter__carousel--hovered" : "critter__carousel"}>
					<div style={style} className="critter__carousel__inner">
						{critters}
					</div>
				</div>
				<RightArrow className={`${index === this.maxLength ? "hide" : ""} critter__carousel__arrow critter__carousel__arrow--right`} onClick={this._increment}/>
			</div>
		)
	}
}


export default CritterCarousel
