import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Landing,
  Landscape,
  Infrastructure,
  Poster,
  SuperIndex,
  LandscapePositioning,
  FourOhFour,
  DataGeneral,
  DataImageList,
  DataImageView,
  DataPoster,
  DataPosterList,
  DataEssayList
} from './pages'

import {
  ContentDrawer
} from './components/content_drawer'

import {
  TransitionEl
} from './components/transition_el'


import {
  Key
} from './components/key'

import debounce from 'lodash/debounce'
import './App.css';


@inject('store') @observer
class App extends Component {
  componentDidMount() {
    this.props.store.siteDataStore.fetchSiteData();

    window.onresize = debounce(this.props.store.uiStore.handleResize, 200);
  }

  render() {
    const {store} = this.props;

    if (!store.routerStore.current) return null;
    if (!this.props.store.siteDataStore.allDataReady) return null;

    const routeName = store.routerStore.current ? store.routerStore.current.name : "404"; 
    let component = null;
    
    switch (routeName) {
      case "landing":
        component = <Landing/>;
        break;
      case "landscape.view":
        component = <Landscape/>;
        break;
      case "infrastructure.view":
        component = <Infrastructure/>;
        break;
      case "critter.view":
        component = <Poster key={`ps-${store.routerStore.current.params.id}`}/>
        break;
      case "superindex":
        component = <SuperIndex/>;
        break;
      case "layout":
        component = <LandscapePositioning/>;
        break;
      case "data.general":
        return <DataGeneral/>
        break;
      case "data.images":
        return <DataImageList/>
        break;
      case "data.posters":
        return <DataPosterList/>
        break;
      case "data.essays":
        return <DataEssayList/>;
        break;

      default:
        component = <FourOhFour/>
    }

    return (
      <div>
        <Key/>
        {component}
        <ContentDrawer/>
        {this.props.store.uiStore.transitioning ? <TransitionEl/> : null}
      </div>
    )
  }
}

export default App;
