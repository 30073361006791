import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import {Provider} from 'mobx-react';
import RootStore from './stores/RootStore'

import {toJS} from 'mobx'

const marked = require('marked');
const markedRenderer = new marked.Renderer();
markedRenderer.paragraph = ( text ) => `<span>${text}</span>`;
markedRenderer.sup = (text) => `<h1>{text}</h1>`;
markedRenderer.link = (href, title, text) => `<span>${text}</span>`

window.marked = marked;
window.markedRenderer = markedRenderer;

window.toJS = toJS;

const ToRender = () => {
	const store = new RootStore()

	return (
		<Provider store={store}>
			<App></App>
		</Provider>
	)
}

ReactDOM.render(<ToRender></ToRender>, document.getElementById('root'));
