import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {AudioPlayer} from '../../../components/audioplayer'
import {Slideshow} from '../../../components/slideshow'

import get from 'lodash/get'
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

import {
	ResponsiveImage
} from '../../../components/general/image'

import {
	Credits
} from '../../../components/general/text'

import {
	slugify
} from '../../../helpers'

@inject("store") @observer
class PosterHeader extends Component {

	_renderFlowMap() {
		const {pageData} = this.props.store;
		const videoSrc = slugify(get(pageData, "fields.flowMapMedia[0].fields.media.fields.title"), "");
		const altMediaSrc = slugify(get(pageData, "fields.flowMapMedia[1].fields.media.fields.title", ""));
		const credits = this._renderFlowMapCredits();
		// if (!videoSrc) return null

		var flowMap;

		switch(pageData.fields.flowMapType) {
			case "Full Screen Video":
			return 	(
					<div className="poster__media-wrapper--no-scale">
						<video className="poster__video" src={`/images/archive-images/${videoSrc}.mp4`} alt="" autoPlay controls controlsList="nodownload"/>
						{credits}
					</div>
				)
			case "Full Screen Image":
				return 	(
					<div className="poster__media-wrapper--no-scale">
						<ResponsiveImage className="" image={get(pageData, "fields.flowMapMedia[0].fields.media")} sizes={[1400, 1600, 1800]} q={85}/>
						{credits}
					</div>
				)

			case "Fit Screen Image":
				flowMap = (
					<div className="poster__media poster__media--container">
						<ResponsiveImage className="poster__media__image" image={get(pageData, "fields.flowMapMedia[0].fields.media")} sizes={[800, 1200, 1400, 1600, 1800]} q={85}/>
						{credits}
					</div>
				)

				return this._wrapMapWithTween(flowMap)

			case "Fit Screen Image Window Height":
				flowMap = (
					<div className="poster__media poster__media--container">
						<ResponsiveImage className="poster__media__image height" image={get(pageData, "fields.flowMapMedia[0].fields.media")} sizes={[800, 1200, 1400, 1600, 1800]} q={85}/>
						{credits}
					</div>
				)

				return this._wrapMapWithTweenMaxHeight(flowMap)

			case "Fit Screen Video":
				flowMap = (
					<div className="poster__media poster__media--container">
						<video className="poster__media" src={`/images/archive-images/${videoSrc}.mp4`} alt="" autoPlay controls controlsList="nodownload"/>
						{credits}
					</div>

				)

				return this._wrapMapWithTween(flowMap)

			case "Image and Audio":
				return (
					<div className="poster__media-wrapper--no-scale">
						<div className="wrapper">
							<ResponsiveImage className="poster__media__image" image={get(pageData, "fields.flowMapMedia[0].fields.media")} sizes={[800, 1200, 1400, 1600, 1800]} q={85}/>
							<AudioPlayer className="poster__media__audio" file={`/images/archive-images/${altMediaSrc}.mp3`}/>
						</div>
						{credits}
					</div>
				);

				// flowMap = (
				// 	<div className="poster__media poster__media--container">
				// 		<ResponsiveImage className="poster__media__image" image={get(pageData, "fields.flowMapMedia[0].fields.media")} sizes={[800, 1200, 1400, 1600, 1800]} q={85}/>
				// 		<AudioPlayer className="poster__media__audio" file={altMediaSrc}/>
				// 		{credits}
				// 	</div>
				// );

				// return this._wrapMapWithTween(flowMap)
			case "Image Slideshow":

				flowMap = (
					<div className="poster__media poster__media--container">
						<Slideshow images={pageData.fields.flowMapMedia}/>
						{credits}
					</div>
				)

				return this._wrapMapWithTween(flowMap)
		}

		return null
	}

	onEnd() {
	}

	_wrapMapWithTween(flowMap) {
		return (
			<Tween
				from={{ scale: '1', x: '0', y: '0'}}
				to={{ scale: `${(window.innerWidth - 540) / window.innerWidth}`, x: '520', y: '20'}}
				ease="Quad.easeOut"
				duration={".4"}
				position={".25"}>
				{flowMap}
			</Tween>
		)
	}

	_wrapMapWithTweenMaxHeight(flowMap) {
		const height = window.innerHeight - 47;
		return (
			<Tween
				from={{ height: '100%', transformOrigin:"50% 50%" }}
				to={{ height: `${height}px`, transformOrigin:"50% 50%"}}
				ease="Quad.easeOut"
				duration={".4"}
				position={".25"}>
				{flowMap}
			</Tween>
		)
	}

	_renderFlowMapCredits() {
		const {pageData} = this.props.store;
		return <Credits data={pageData} type="fields.flowMapMedia[0]" location="header" />
	}

	render() {
		const {pageData} = this.props.store;
		const imageSrc = get(pageData, "fields.flowMapMedia[0].fields.media.fields.file.url");
		const title = pageData.fields.title;

		const flowMap = this._renderFlowMap();

		const {width, height} = get(pageData, "fields.flowMapMedia[0].fields.media.fields.file.details.image", {});

		const style = {height: "auto"};

		if (width && height) {
			const ratio = width / height;
			style.minHeight = this.props.store.uiStore.windowWidth / ratio;
		}

		return (

					<div  id="posterHeader" className="poster__header panel">
						<Controller>
							<Scene
							  triggerHook="onLeave"
							  duration="200%"
							  pin>

								{(progress, event) => (
										<div style={style} id="pinContainer">
										        <Timeline
										          totalProgress={progress}
										          paused>
										          	<EventHandler event={event} endHandler={this.onEnd}/>
													<Tween
														from={{ y: '0', opacity: 1}}
														to={{ y: '-100vh', opacity: 0 }}
														position={"0"}>

														<h3 className="poster__title">
															{title}
														</h3>
													</Tween>

													<Tween
														from={{ opacity: '1' }}
														to={{ opacity: '0' }}
														position={"0"}>

														<div className="poster__header__sheet"></div>
													</Tween>


													{flowMap ? flowMap : <div></div>}

												</Timeline>
										</div>

								)}

							 </Scene>

						</Controller>

					</div>

		)
	}
}

const EventHandler = (props) => {
	if (props.event.state === "AFTER") {
		props.endHandler()
	}

	return null
}

export default PosterHeader
