import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	Link
} from '../../../components/general/link'

import {
	ResponsiveImage
} from '../../../components/general/image'

import get from 'lodash/get'
import {slugify} from '../../../helpers'

@inject("store") @observer
class LandingCritterImageLink extends Component {
	render() {
		const {poster} = this.props;
		const {windowWidth, windowHeight} = this.props.store.uiStore;

		const image = get(poster, "fields.critter.fields.image");
		if(!poster.fields.landscape) return null;
		const critterSize = 300;
		const x = (windowWidth / 2) - (critterSize / 2);
		const y = (windowHeight / 2) - (critterSize / 2);
		const styles = {
			transform: `translate(${x}px, ${y}px)`
		};

		return (

			<Link to="landscape.view" params={{id: slugify(poster.fields.landscape[0].fields.name)}}>
				<ResponsiveImage 
					onMouseOver={this.props.store.uiStore.setActiveLandingPoster.bind(null, slugify(this.props.poster.fields.title))}
					onMouseLeave={this.props.store.uiStore.setActiveLandingPoster.bind(null, null)}
					className="landing__critter-img-link" 
					image={image} 
					sizes={[300]}/>
			</Link>

		)

	}
}




	/*

		<div style={styles} className="landing__critter-img-wrap">

		</div>

	*/

export default LandingCritterImageLink