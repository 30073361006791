import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

@inject("store") @observer
class LandscapeSaveCoordsTooltip extends Component {

	
	render() {
		if (!this.props.store.positioningStore.showSavedTooltip) return null

		const toolTipSavedPositions = this.props.store.positioningStore.toolTipSavedPositions

		return (
			<div className="positioning-app__saved-tooltip">
				<h3>Saved Positions - x:{toolTipSavedPositions.x}%, y:{toolTipSavedPositions.y}%</h3>
			</div>
		)
	}
}

export default LandscapeSaveCoordsTooltip