import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {QueryParamLink} from '../general/link'

import {
	slugify
} from '../../helpers'

// import chunk from 'lodash/chunk'

@inject("store") @observer
class ReadingRoom extends Component {

	_renderColumns() {
		const essayData = this.props.store.siteDataStore.essayCategoryForReadingRoom;
		const allSections = Object.keys(essayData).map(textCategory=>{
							return (
								<div className="reading-room__section">
									{essayData[textCategory].map(item => {
										return (
											<div>
												<h4 className="reading-room__section__header">{item.fields.name}</h4>
												{item.fields.essays &&
													item.fields.essays.map(essay=> {
														if (essay.fields.slug === "index-of-poems") {
															return null
														}

														if (essay.fields.slug === "list-of-contributors") {
															return (
																<div>
																	<QueryParamLink to={"modal"} params={{text: essay.fields.slug, ttype: 'contributor'}}>{essay.fields.readingRoomDisplayTitle}</QueryParamLink>
																</div>
															)
														} else {
															return (
																<div>
																	<QueryParamLink to={"modal"} params={{text: essay.fields.slug, ttype: essay.sys.contentType.sys.id}}>{essay.fields.readingRoomDisplayTitle}</QueryParamLink>
																</div>
															)
														}
													})
												}
											</div>
										)
									})}
								</div>
							)
						});
		
		return [
		[
			allSections[0],
			allSections[1],
			allSections[2],
			allSections[3]
		],
		[
			allSections[4],
			allSections[5],
			allSections[6]
		],
		[
			
			allSections[7],
			allSections[8],
			allSections[9],
			allSections[10]

			
		]]
		// return chunk(allSections, 4);
	}
	render() {
		if (!this.props.store.uiStore.drawer__showReadingRoom && this.props.store.routerStore.currentName !== "superindex") return null
		const {windowHeight} = this.props.store.uiStore;

		const heightStyles = {
			height: windowHeight - 88
		};

		const columns = this._renderColumns()

		return (
			<div style={heightStyles} className="reading-room">
				<p className="reading-room-title">Reading Room</p>
				<div className="reading-room__wrapper">
					{columns.map(column => {
						return (
							<div className="reading-room__column">
								{column}
							</div>
						)
					})}
				</div>
			</div>
		)
	}
}



const ReadingRoomSection = (props) => {

	return (
		<div className="reading-room__section">
			<h4 className="reading-room__section__header">Reading Room Section</h4>

			<li>Reading Room thing</li>
		</div>
	)
}


export default ReadingRoom
