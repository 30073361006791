import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	QueryParamLink,
	Link
} from '../../general/link'
import {
	RichTextEssays
} from '../../general/text'
// import {
// 	ContentDrawerTextLink
// } from './'

import get from 'lodash/get'

@inject("store") @observer
class EssayEmbeddedInlineEntry extends Component {

	_renderItems(){
		if (!this.props.data) return null;

		return this.props.data.map((text, idx) => {
			let markClasses = '';

      if (text.nodeType === "embedded-entry-inline" && text.data.target.fields.bioSketch) {
        return <RichTextEssays key={`${idx}-${text.nodeType}`} id={text.data.target.fields.slug} data={text.data.target.fields.bioSketch.content[0]}/>
      } else if (text.nodeType === "entry-hyperlink") {
				if (text.data.target.sys.contentType.sys.id === "essay") {
					return <QueryParamLink to="modal" params={{text: text.data.target.fields.slug, ttype: "essay"}}>{text.content[0].value}</QueryParamLink>
				} else if (text.data.target.sys.contentType.sys.id === "poster") {
					return <Link to="critter.view" params={{id: text.data.target.fields.slug}}><span dangerouslySetInnerHTML={{__html: window.marked(text.content[0].value || "", {renderer: window.markedRenderer})}}></span></Link>

					return <a key={`${idx}-${text.nodeType}`} href={text.data.target.fields.slug} dangerouslySetInnerHTML={{__html: text.content[0].value}}></a>
				}

			} else {

				if (text.marks && text.marks.length) {
					 markClasses = text.marks.map((mark) => {
						return mark.type
					})
				}
				return <span className={`${markClasses}`} key={idx} dangerouslySetInnerHTML={{__html: text.value }}></span>
			}
		})
	}

	render() {
		const items = this._renderItems();
		if (!items) return null

		return (
      <div className="indeces__body__section">
        {items}
      </div>
		)
	}
}

export default EssayEmbeddedInlineEntry
