import React, {Component} from 'react'

class AudioPlayer extends Component {
	render() {
		const {file} = this.props;

		return (
			<div className={`audio-player ${this.props.className || ""}`}>
				<audio src={file} autoPlay controls></audio>
			</div>
		)
	}
}

export default AudioPlayer
