import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	ResponsiveImage
} from '../../../components/general/image'

import get from 'lodash/get'

@inject("store") @observer
class PointOfInterestOverlay extends Component{
	
	render() {
		const {uiStore: {windowHeight, windowWidth, landscape__activePoster, landscape__activePoster__displayName, landscape__posterLocation}} = this.props.store
	
		const posterSlug = landscape__activePoster;
		const displayName = landscape__activePoster__displayName;
		const location = landscape__posterLocation;
		if (!posterSlug) return null

		const poster = this.props.store.siteDataStore.posterDataByName[posterSlug];
		// const name = get(poster, "fields.critterLandscapeInfrastructureConnection[0].fields.displayName");
		const image = get(poster, "fields.critter.fields.image");

		if (location.y > (windowHeight / 2)) {
			location.y -= 220
		};

		if (location.x > ((3 * windowWidth)  / 4)) {
			location.x -= 300
		}

		const style = {
			top: location.y,
			left: location.x + 20
		}
		
		return (
			<div style={style} className="landscape__poi-overlay landscape__poi-overlay--local">
				<div className="landscape__text">
					<ResponsiveImage className="landscape__poi__image" image={image} sizes={[300]} useSizes q={90}/>

					<h2 className="landscape__poi__name" dangerouslySetInnerHTML={{__html: window.marked(displayName || "", {renderer: window.markedRenderer})}}></h2>
				</div>
			</div>
		)
	}
}

export default PointOfInterestOverlay