import React, {Component} from 'react'
import {
	inject,
	observer
} from 'mobx-react'

import {
	QueryParamLink
} from '../../general/link'


@inject("store") @observer
class ContentDrawerReadingRoomButton extends Component {
	render() {
		if (this.props.store.routerStore.viewingReadingRoom) return null
		return (
			<QueryParamLink to="reading_room">
				<div className="cd__reading-room-btn">
					Reading Room <img src="/images/arrow-right-cropped.svg" alt=""/>
				</div>
			</QueryParamLink>

		)
	}
}

export default ContentDrawerReadingRoomButton