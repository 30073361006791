import React, {Component} from 'react'
import {observer, inject} from 'mobx-react'
var stringify = require('json-stringify-safe');

@inject("store") @observer
class DataGeneral extends Component {
	render() {
		const {siteDataStore: {getAllSiteData}} = this.props.store;
		
		const data = {
			// globalSiteData: getAllSiteData.globalSiteData,
			// poster: getAllSiteData.poster,
			// globalSiteData: null,
			// poster: [],
			// infrastructure: [],
			// fq: [],
			// landscape: [],
			// essay: [],
			// contributor: [],
		    // essayCategory: []
		};
		

		console.log(stringify(getAllSiteData.contributor));

		return (
			<div className="data-general">				
				<h2>data</h2>
			</div>
		)
	}
}

				// <span dangerouslySetInnerHTML={{__html: stringify(data)}}></span>

export default DataGeneral