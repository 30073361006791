import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	LandingCritterImageLink
} from './'


@inject("store") @observer
class LandingCrittersController extends Component {
	_renderCritters() {
		return this.props.store.siteDataStore.data.poster.map((poster) => {

			return <LandingCritterImageLink poster={poster}/>
			return <h5>{poster.fields.title}</h5>
		})

	}

	render() {
		const critters = this._renderCritters();

		return (
			<div className="">
				{critters}
			</div>
		)
	}
}

export default LandingCrittersController