import { observable, computed, action } from 'mobx';

// import MapStore from './MapStore'

import routerStore from './RouterStore'
import SiteDataStore from './SiteDataStore'
import UIStore from './UIStore'

import PositioningStore from './__PositioningStore__'

import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'


class RootStore {
	constructor() {


		this.routerStore = routerStore;
		this.siteDataStore = new SiteDataStore(this);

		this.isReactSnap = navigator.userAgent === 'ReactSnap';
		
		const preloadedState = window.__PRELOADED_STATE__;
		// Allow the passed state to be garbage-collected
		delete window.__PRELOADED_STATE__;
		
		// const siteDataStore = new SiteDataStore(this, preloadedState);

		window.snapSaveState = () => ({
		  "__PRELOADED_STATE__": this.siteDataStore.getAllSiteData()
		});


		this.uiStore = new UIStore(this);


		this.routerStore.setRoot(this);
		this.routerStore.router.start()

		// position store is used for laying out CLICs on a layout tool (not on the main site)
		// route: /layout (to be deleted later)
		this.positioningStore = new PositioningStore(this);
	}


	@computed get activeProject() {
	    const id = this.routerStore.current.params.id;
	}


	@computed get pageData() {
		if (!this.siteDataStore.allDataReady || !this.routerStore.current || !this.routerStore.current.params) return {};

		const {viewingPoster, viewingInfrastructure, viewingLandscape, current} = this.routerStore;

		if (viewingPoster) {
			const poster = cloneDeep(this.siteDataStore.posterDataByName[current.params.id])

			if (this.siteDataStore.extraDataExists.poster[current.params.id]) {
				const {textContent, acknowledgments, references, feralQualityReferences} = this.siteDataStore.extraData.poster[current.params.id];
				
				poster.fields.textContent = textContent;
				poster.fields.acknowledgments = acknowledgments;
				poster.fields.references = references;
				poster.fields.feralQualityReferences = feralQualityReferences;
			}

			return poster
		}

		if (viewingInfrastructure) {
			return this.siteDataStore.infrastructureDataByName[current.params.id]
		}

		if (viewingLandscape) {
			return this.siteDataStore.landscapeDataByName[current.params.id]
		}
	}

	@computed get essayData() {
		const {viewingEssay} = this.routerStore;

		if (viewingEssay) {
			const {uiStore: {drawer__activeBodyText}, siteDataStore: {extraData, essayDataByName, extraDataExists}} = this;

			
			let activeEssay = essayDataByName[drawer__activeBodyText] ;
			if (extraDataExists.essay[drawer__activeBodyText]) {
				const {text, acknowledgments, references} = extraData.essay[drawer__activeBodyText];

				activeEssay = cloneDeep(activeEssay);
				activeEssay.fields.text = text;
				activeEssay.fields.acknowledgments = acknowledgments;
				activeEssay.fields.references = references;
			}

			return activeEssay
		}

		return null
	}

	@computed get essayDataModal() {
		const {viewingEssay} = this.routerStore;

		if (viewingEssay) {
			const {uiStore: {drawer__activeModalText}, siteDataStore: {extraData, essayDataByName, extraDataExists}} = this;
			
			let activeEssay = essayDataByName[drawer__activeModalText.slug] 

			if (extraDataExists.essay[drawer__activeModalText.slug]) {
				const {text, acknowledgments, references} = extraData.essay[drawer__activeModalText.slug];
				activeEssay = cloneDeep(activeEssay);
				activeEssay.fields.text = text;
				activeEssay.fields.acknowledgments = acknowledgments;
				activeEssay.fields.references = references;
			}

			return activeEssay
		}

		return null
	}

	@computed get activePosterData() {
		const {posterDataByName} = this.siteDataStore;
		const {activePoster} = this.uiStore;
		if (!activePoster) return null

		return posterDataByName[activePoster];
	}

	@action updatePageControls(state) {
		this.uiStore.closeDrawerStageOne()
		this.uiStore.closeDrawerStageTwo()
		this.uiStore.removeActiveDrawerBodyText()
		this.uiStore.hideReadingRoom();
		this.uiStore.closePosterReferences();
		this.uiStore.closePosterFQReferences()

		this.uiStore.clearEssayReferenceScrollLocation() 
		this.uiStore.clearReferenceScrollLocaation() 
		this.uiStore.clearFQReferenceScrollLocaation() 

		const routeName = get(state, "name");

		if (routeName !== "infrastructure.view") {
			this.uiStore.setActivePoster(null);
			this.uiStore.setActiveLandscapePoster(null, null, null);
		}
	}

	scrollToTop() {
		window.scrollTo(0, 0);
	}

}

export default RootStore
