import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	LeftArrow,
	RightArrow
} from './subcomponents'

import {
	Credits
} from '../general/text'

import {
	slugify
} from '../../helpers'

import {
	Video
} from './'

import get from 'lodash/get'

@inject("store") @observer
class VideoCarousel extends Component {

	constructor(props) {
		super(props);
		this.isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

		this.state = {
			index: 0,
			playing: false
		};
	}


	_decrement = () => {
		var index  = this.state.index - 1;

		const min = 0
		if (index < min) {
			index = min
		};


		this.setState({
			index,
			playing: false
		})
	}

	_increment = () => {
		var index  = this.state.index + 1;

		const max = this.props.videos.length - 1;

		if (index > max) {
			index = max
		};

		this.setState({
			index,
			playing: false
		})
	}

	_onClick = (id) => {
		this.setState({
			index: id,
			playing: false
		})
	}

	playVideo() {
		this.setState({
			playing: true
		});

		this.refs.video.play()
	}

	renderVideos() {
		const {videos} = this.props;
		const {index: carouselIndex} = this.state;
		
		return videos.map((vid, idx) => {
		    const src = slugify(get(vid, "fields.media.fields.title", ""));

	      // const src = vid.fields.media.fields.file.url

			return (
				<Video click={this._onClick} key={idx} id={idx} carouselIndex={carouselIndex} src={`/images/archive-images/${src}.mp4`} isChrome={this.isChrome} />
			)
		})
	}



	render() {
		if (!this.props.videos) return null
		const {index: carouselIndex, playing} = this.state;
		const videos = this.renderVideos();
		

		const currentVideo = this.props.videos[carouselIndex];

		const src = slugify(get(currentVideo, "fields.media.fields.title", ""));
		const caption = get(currentVideo, "fields.caption");
		const description = get(this.props, "description");

		return (
			<div>
				<div className="main-video-section">
					<video ref="video" src={`/images/archive-images/${src}.mp4`} loop controls controlsList="nodownload" preload={this.isChrome ? "metadata" : null}></video>
					<div className="video__caption" dangerouslySetInnerHTML={{__html: window.marked(caption || "", {renderer: window.markedRenderer})}}></div>
				</div>

				
				<div className="other-video-section">
					<div className="video-description-section">
						<h6 className="video-description">{description}</h6>
					</div>
					<div className="video__carousel__inner">
						<div>
							{videos}
						</div>
					</div>
				</div>


			</div>
		)
	}
}


const PlayButton = (props) => {
	return (
		<div className="video__play-button" onClick={props.onClick}>
			<img src="/images/play-button.svg" alt=""/>
		</div>
	)
}
export default VideoCarousel
