import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	InfrastructureActivePosterLinks
} from './subcomponents'

// import {
// 	// InfrastructureLeft,
// 	// InfrastructureRight,
// 	// InfrastructureCritterError
// } from './subcomponents'

// import {
// 	LockedContentView
// } from '../../components/lockedcontent'

import {
	InfrastructureVideos,
	InfrastructureOtherCritters,
	// InfrastructureBodyImage
} from './subcomponents'

import {
	ResponsiveImage
} from '../../components/general/image'

import {
	slugify
} from '../../helpers'

import get from 'lodash/get'

@inject("store") @observer
class Infrastructure extends Component {
	componentDidMount() {
		this.props.store.uiStore.registerPageVisit("infrastructure", this.props.store.routerStore.current.params.id);
	}

	render() {

		const {pageData,activePosterData, uiStore: {landscape__activePoster__clicID}, siteDataStore: {CLICDataByID}} = this.props.store;
		const activeCLIC = CLICDataByID[landscape__activePoster__clicID];

		const critterName = get(activeCLIC, "fields.displayName");
		const critterImage = slugify(get(activePosterData, "fields.critter.fields.image.fields.title", ""));
		const thoughtBomb = get(activePosterData, "fields.title")
		const longDescription = get(pageData,"fields.longDescription");
		const shortDescription = get(pageData,"fields.shortDescription");

		const infrastructureImage = slugify(get(activeCLIC, "fields.infrastructureImage.fields.title", ""));
		const videoDescriptionText = get(pageData,"fields.videoIntroductionText");
		const poem = slugify(get(pageData, "fields.poemImage[0].fields.title", ""));
		const allVideos = get(pageData,"fields.videos", []);

		
		return (
			<div className="page infrastructure">
				<InfrastructureActivePosterLinks/>

				<div className="infrastructure__info-section">
					<div className="infrastructure__general-info">
						<h5 className="infrastructure__name">{get(pageData,"fields.name")}</h5>
						<h5 className="infrastructure__short-description" dangerouslySetInnerHTML={{__html: window.marked(shortDescription || "", {renderer: window.markedRenderer})}}></h5>
					</div>

					<div className="infrastructure__poi-info">
						<img className="infrastructure__poi-image" src={`/images/archive-images/${infrastructureImage}-1000.jpg`} alt=""/>
						
						<div className="infrastructure__poi-critter">
							<img src={`/images/archive-images/${critterImage}-100.png`} alt=""/>
							{/*<ResponsiveImage image={critterImage} sizes={[200]} useSizes q={90}/>*/}
							<h5 dangerouslySetInnerHTML={{__html: window.marked(critterName || "", {renderer: window.markedRenderer})}}>{}</h5>
						</div>
					</div>
				</div>

				<div className="infrastructure__video-section">
					<InfrastructureVideos description={videoDescriptionText} videos={allVideos} />
				</div>

				<div className="infrastructure__misc-section">
					<img className="infrastructure__poem" src={`/images/archive-images/${poem}-1200.jpg`} alt=""/>

					<div className="infrastructure__critter__container">
						<InfrastructureOtherCritters/>
					</div>
				</div>
			</div>
		)
	}
}





export default Infrastructure
