import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	QueryParamLink,
	Link
} from '../../general/link'

import {
	Credits
} from '../../general/text'

import {
	GatedLinkHOC
} from '../../gatedlink'

import {
	ResponsiveImage
} from '../../general/image'

import get from 'lodash/get'
import {slugify} from '../../../helpers'

const ContentDrawerBodySection = (props) => {
	let data = "";
	let feralQuality = null;
	let feralQualityType = null;
	let refFlag = false;
	let colorWrapper = false;
	let colorWrapperColor = "";
	let inlineImage = null;

	let typeTwoFlag = false;
	let isTypeTwo = false;
	let currentSections = {};

	let dataArr = [];
	let isBlockQuote = "";

	if (props.data.nodeType === "embedded-entry-block") {
		if (props.data && props.data.data.target) {
			const type = props.data.data.target.sys.contentType.sys.id;
			if (type === "mediaFile") {
				if (props.data.data.target.fields.media.fields.file.contentType === "audio/mpeg") {
					return <Audio data={props.data.data}/>
				}	else if (props.data.data.target.fields.media.fields.file.contentType === "video/mp4") {
						return <BlockVideo data={props.data.data}/>
					}  else {
					return <BlockImage data={props.data.data}/>
				}
			}
		}

	}

	const paragraph = props.data.content.forEach((node, idx) => {

		if (node.nodeType === "text") {
			let blockquotemarker = /^>/g;
			const blockQuoteFound = node.value.match(blockquotemarker);

			if (blockQuoteFound) {
				isBlockQuote = 'is-blockquote';
			}

			if (!node.value) return
			typeTwoFlag = false;

			if (refFlag) {
				dataArr.push(<ReferenceTag key={idx} node={node}/>)
			} else if (colorWrapper) {
				let color = {
					color: `rgb(${colorWrapperColor.r}, ${colorWrapperColor.g}, ${colorWrapperColor.b})`,
					borderColor: `rgb(${colorWrapperColor.r}, ${colorWrapperColor.g}, ${colorWrapperColor.b})`
				};
				dataArr.push(<span className="color-wrapper" style={color}>{node.value}</span>)
			} else {
				dataArr.push(<span dangerouslySetInnerHTML={{__html: window.marked(node.value || "", {renderer: window.markedRenderer})}}></span>)
			}
		}


		if (node.nodeType === "embedded-entry-inline") {
			if (node.data && node.data.target) {
				const type = node.data.target.sys.contentType.sys.id;

				if (type === "referenceTag") {
					refFlag = !refFlag;
					colorWrapper = false;
				}

				if (type === "colorWrapper") {
					colorWrapperColor = node.data.target.fields.color;

					colorWrapper = !colorWrapper;
				}
			}
		}

		if (node.nodeType === "entry-hyperlink") {
			const type = node.data.target.sys.contentType.sys.id;

			if (type === "contributor") {
				const name = get(node.data, "target.fields.name", "");

				dataArr.push(<QueryParamLink to="modal" params={{text: slugify(name), ttype: type}}>{node.content[0].value}</QueryParamLink>)

			} else if (type === "poster") {
				const slug = node.data.target.fields.slug;
				if (colorWrapper) {
					let color = {
						color: `rgb(${colorWrapperColor.r}, ${colorWrapperColor.g}, ${colorWrapperColor.b})`,
						borderColor: `rgb(${colorWrapperColor.r}, ${colorWrapperColor.g}, ${colorWrapperColor.b})`
					};
					dataArr.push(
						<span className="color-wrapper" style={color}>
							<Link to="critter.view" params={{id: slug}}>
								<span dangerouslySetInnerHTML={{__html: window.marked(node.content[0].value || "", {renderer: window.markedRenderer})}}></span>
							</Link>
						</span>
					)
				} else {
					dataArr.push(
						<Link to="critter.view" params={{id: slug}}>
							<span dangerouslySetInnerHTML={{__html: window.marked(node.content[0].value || "", {renderer: window.markedRenderer})}}></span>
						</Link>
					)
				}
			} else if (type === "essay") {
				const title = get(node.data, "target.fields.title", "");
				const slug = get(node.data, "target.fields.slug", "");

				if (colorWrapper) {
					let color = {
						color: `rgb(${colorWrapperColor.r}, ${colorWrapperColor.g}, ${colorWrapperColor.b})`,
						borderColor: `rgb(${colorWrapperColor.r}, ${colorWrapperColor.g}, ${colorWrapperColor.b})`
					};
					dataArr.push(
						<span className="color-wrapper" style={color}>
							<QueryParamLink to="modal" params={{text: slug, ttype: type}}>{node.content[0].value}</QueryParamLink>
						</span>
					)
				} else {
					dataArr.push(
						<QueryParamLink to="modal" params={{text: slug, ttype: type}}>{node.content[0].value}</QueryParamLink>
					)
				}
			}
		}
	})

	return (
		<div className={`cd__body__section ${isBlockQuote}`}>
			<p>
				{dataArr}
			</p>
		</div>
	)
}



const BlockImage = (props) => {
	const imageSrc = get(props.data, "target.fields.media.fields.file.url")
	const credits = get(props.data, "target.fields.attribution")
	const caption = get(props.data, "target.fields.caption");
	const size = get(props.data, "target.fields.embeddedImageSize");

	return (
		<div className={`essay__image poster__body__block__image-wrap ${size ? `essay__image poster__body__block__image-wrap--${size}` : ""}`}>
			<span class="image-wrapper">
				<ResponsiveImage className="poster__body__block__image" image={props.data.target.fields.media} sizes={[600, 800, 1000, 1200, 1400]} multiplier={.5} q={90}/>
				<Credits data={props.data} type="target" location="body" />
			</span>
		</div>
	)
}

const Audio = (props) => {
	const audioSrc = slugify(get(props.data, "target.fields.media.fields.title", ""));
	const credits = get(props.data, "target.fields.attribution")
	const caption = get(props.data, "target.fields.caption");
	const size = get(props.data, "target.fields.embeddedImageSize");

	return (
		<div className={`essay__image poster__body__block__image-wrap ${size ? `essay__image poster__body__block__image-wrap--${size}` : ""}`}>
			<audio src={`/images/archive-images/${audioSrc}.mp3`} controls></audio>
			<Credits data={props.data} type="target" location="body" />
		</div>
	)
}

const BlockVideo = (props) => {
	const videoSrc = get(props.data, "target.fields.media.fields.file.url")
	const credits = get(props.data, "target.fields.attribution")
	const caption = get(props.data, "target.fields.caption");
	const size = get(props.data, "target.fields.embeddedImageSize");

	return (
		<div className={`essay__image poster__body__block__image-wrap ${size ? `essay__image poster__body__block__image-wrap--${size}` : ""}`}>
			<div className={`essay__image poster__body__block__image-wrap ${size ? `poster__body__block__image-wrap--${size}` : ""}`}>
				<video className="poster__body__block__image" src={videoSrc} controls></video>
				<Credits data={props.data} type="target" location="body" />
			</div>
		</div>
	)
}

@inject("store") @observer
class ReferenceTag extends Component {

	render() {
		const {node} = this.props;

		return (
			<sup onClick={this.props.store.uiStore.openAndScrollToRefEssays} className="poster__body__reference" dangerouslySetInnerHTML={{__html: window.marked(node.value || "", {renderer: window.markedRenderer})}}></sup>
		)
	}
}

export default ContentDrawerBodySection
