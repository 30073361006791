import React, {Component} from 'react'
import {
	LeftArrow,
	RightArrow
} from './subcomponents'

class Video extends Component {
  _onClick = () => {
    this.props.click(this.props.id);
	}

	video = element => {
	  if (element !== null) {
	    element.currentTime = 15;
	  }
	};

	render() {
		return (
	      <div ref="videowrap" onClick={this._onClick} id={this.props.id} className={`video__carousel__image-wrap ${this.props.id > this.props.carouselIndex + 2 || this.props.id < this.props.carouselIndex ? "" : ""}`}>
	        <div className="video-carousel-video" >
	          <video ref={this.video} src={`${this.props.src}`} preload={this.props.isChrome ? "metadata" : null}></video>
	        </div>
	      </div>
		)
	}
}


export default Video
