import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

@inject("store") @observer
class LandscapeDropdown extends Component {
	_handleChange = (e) => {
		this.props.store.positioningStore.setActiveLandscape(e.target.value)
	}

	render() {
		const landscapes = this.props.store.siteDataStore.data.landscape;
		const landScapeOptions = landscapes.map((landscape) => {
			const landscapeName = landscape.fields.name
			return <option value={`${landscapeName}`}>{landscapeName}</option>
		})

		return (
			<select onChange={this._handleChange}>
				<option value="">Select a landscape: </option>
				{landScapeOptions}
			</select>
		)
	}
}

export default LandscapeDropdown