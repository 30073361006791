
import {action, observable, computed} from "mobx";
import {State} from 'router5';
import {createRouter} from 'router5';
import routes from '../router/routes.js'
import {mobxRouterPlugin} from '../router/mobxPlugin.js';
import browserPlugin from 'router5/plugins/browser';

import {
	executeAtLeast
} from '../helpers'

class RouterStore {
	constructor() {
		this.rootStore = null

		const router = createRouter(routes, {
		  strictTrailingSlash: false,
		  queryParamsMode: 'loose'
		});

		router.usePlugin(browserPlugin(), mobxRouterPlugin);

		this.previousState = null;
		this.router = router;

		this.initialLoad = true;
	}

	setRoot(rootStore) {
		this.rootStore = rootStore;
	}


	@observable current;
	@observable routeTransitioning = false;

	@computed get currentName() {
		return this.current.name;
	}

	@computed get viewingLanding() {

		return this.currentName === "landing";

	}

	@computed get viewingPoster() {

		return this.currentName === "critter.view";

	}


	@computed get viewingInfrastructure() {

		return this.currentName === "infrastructure.view";

	}

	@computed get viewingLandscape() {

		return this.currentName === "landscape.view";

	}

	@computed get viewingEssay() {
		return this.current.params.text || this.current.params.bdtext;
	}

	@computed get viewingReadingRoom() {
		return !!this.current.params.rr
	}



	@action setCurrent(state) {
		// if (this.current && (state.name !== this.current.name || state.params.id !== this.current.params.id)) {
		// 	this.rootStore.scrollToTop();
		// }

		this.rootStore.scrollToTop();


		this.rootStore.updatePageControls(state);

		this.handleContentDrawerQueryParams(state)
		this.previousState = this.current;
		this.current = state;
	}

	@action setQueryParams(queryParams) {

		const {params, name} = this.current;

		Object.keys(queryParams).forEach((key) => {
			params[key] = queryParams[key];
		});

		this.rootStore.routerStore.router.navigate(name, params)
	}

	@action clearQueryParams(toRemove = ["cd", "cdex", "rr", "bdtext", "text", "ttype"]) {
		const {params, name} = this.current;

		toRemove.forEach(param => {
			if (params[param]) {
				delete params[param]
			}
		})


		this.router.navigate(name, params)
	}

	@action handleContentDrawerQueryParams(state) {
		if (!state.params) return

		if (state.params.cd) {
			this.rootStore.uiStore.toggleDrawer()
		}

		if (state.params.cdex) {
			this.rootStore.uiStore.toggleDrawerStageTwo()
		}

		if (state.params.rr) {
			this.rootStore.uiStore.showReadingRoom()
		}

		if (state.params.bdtext) {
			this.rootStore.uiStore.setActiveDrawerBodyText(state.params.bdtext)
		}

		if (state.params.text && state.params.ttype) {
			this.rootStore.uiStore.setActiveDrawerModalText({slug: state.params.text, type: state.params.ttype});
		}

		if (state.params.text && state.params.ttype) {
			this.rootStore.uiStore.setActiveDrawerModalText({slug: state.params.text, type: state.params.ttype});
		}
	}

	@action.bound navigateBack() {
		this.router.navigate(this.previousState.name, this.previousState.params);
	}

	@action.bound startTransition(toState) {
		// this.rootStore.updatePageControls();
	 	this.rootStore.uiStore.startTransition()


		this.routeTransitioning = true;



		// const TIME = this.initialLoad ? 0 : 500;
		const TIME = 400;

		if (this.initialLoad) {
			this.initialLoad = false;
		}

				// const TIME = this.initialLoad ? 0 : 500;

		executeAtLeast(TIME, this.fetchRouteData, [toState]);

		return new Promise((resolve, reject) => {
			setTimeout(() => resolve(), TIME)
			setTimeout(this.endTransition, TIME)
		});

		// return executeAtLeast(TIME, this.fetchRouteData, [toState])
	}


	@action.bound async fetchRouteData(toState) {

		let func = null;
		const {name, params: {id, bdtext, text}} = toState;

		
		switch(name) {
			case "critter.view":
				func = this.rootStore.siteDataStore.fetchPoster(id);
				if (text || bdtext) {
					const id = text || bdtext;
					this.rootStore.siteDataStore.fetchEssay(id)
				}
				break;

			default:
				if (text || bdtext) {
					// must be in this order, text -> bdtext or broken up to two seperate fetches
					const id = text || bdtext;
					func = this.rootStore.siteDataStore.fetchEssay(id)
				} else {
					func = null;
				}

		}

		return func
	}


	@action.bound endTransition() {

		this.routeTransitioning = false;

		// can use this to open the drawer after transition
		// setTimeout(this.rootStore.uiStore.toggleDrawer, 1000)
	}


}

const routerStore = new RouterStore();

export default routerStore
