import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	CritterCarousel
} from './'

@inject("store") @observer
class FeralQualityOtherCritters extends Component {

	render() {
		const {siteDataStore: {posterDataByFeralQuality}} = this.props.store;
		const {feralQuality} = this.props;

		return(
			<div className="fq__other-critters">
				<CritterCarousel posters={posterDataByFeralQuality[feralQuality]} location={feralQuality}/>
			</div>
		)
	}
}

export default FeralQualityOtherCritters
