import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	ContentDrawerHamburger
} from './'


import get from 'lodash/get'

@inject("store") @observer
class ContentDrawerHeader extends Component {
	render() {
		return (
			<div className="cd__header">
				<ContentName/>
				<ContentDrawerHamburger />
				<ContentType/>
			</div>
		)
	}
}

@inject("store") @observer
class ContentName extends Component{
	_getName() {
		const {pageData, routerStore} = this.props.store;

		if (routerStore.viewingPoster) {
			return get(pageData, "fields.critter.fields.name");
		} else if (routerStore.viewingInfrastructure) {
			return get(pageData, "fields.name");
		} else if (routerStore.viewingLandscape) {
			return get(pageData, "fields.name");
		} else if (routerStore.viewingLanding) {
			return "Open the drawer"
		}

	}

	render() {
		let pageTitle = this._getName();

		return (
			<h3 className="cd__header__content-name" dangerouslySetInnerHTML={{__html: window.marked(pageTitle || "", {renderer: window.markedRenderer})}}></h3>
		)
	}
}


@inject("store") @observer
class ContentType extends Component {
	_getType() {
		const {pageData, routerStore} = this.props.store;

		if (routerStore.viewingPoster) {
			return "Field Report"
		} else if (routerStore.viewingInfrastructure) {
			return "Tippers: Modes of Infrastructure-Mediated State Change"
		} else if (routerStore.viewingLandscape) {
			return "Anthropocene Detonator Landscape"
		} else if (routerStore.currentName === "superindex") {
			return "Super Index"
		}

	}
	render() {
		return <h5 className="cd__header__content-type">{this._getType()}</h5>
	}

}

export default ContentDrawerHeader
