import React, {Component} from 'react'

class AcknowledgmentsSection extends Component {
	render() {
		const {acknowledgments} = this.props;
		if (!acknowledgments) return null
			
		return (
			<div className="" dangerouslySetInnerHTML={{__html: window.marked(acknowledgments || "", {renderer: window.markedRenderer})}}>
			</div>
		)
	}
}

export default AcknowledgmentsSection