import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {slugify} from '../../../helpers'

import {
	ResponsiveImage
} from '../../../components/general/image'

import {
	Link
} from '../../../components/general/link'

// import {
// 	GatedLinkHOC
// } from '../../../components/gatedlink'

import get from 'lodash/get'
import chunk from 'lodash/chunk';

@inject("store") @observer
class SuperIndexCritterCatalogue extends Component {

	_renderCritters() {
		let critterArr = [];
		const critters = this.props.store.siteDataStore.crittersByName;
		 Object.keys(critters).forEach(critter => {
		 	critters[critter].forEach(data => {
		 		critterArr.push(data)
		 	})
		 });

		critterArr = critterArr.sort((a,b) => {

			const nameA = a.fields.critter.fields.name.replace(/[^0-9a-z]/gi, '');
			const nameB = b.fields.critter.fields.name.replace(/[^0-9a-z]/gi, '');
			if ( nameA < nameB ){
			  return -1;
			}
			if ( nameA > nameB ){
			  return 1;
			}
			return 0;
		})

		const critterRows = chunk(critterArr, 8);
			
		return critterRows.map((row) => {
			return (
				<div className="s-i__critter-row">
					{row.map((critterData) => {
						return (
							<CatalogueCritter data={critterData}/>
						)
					})}
				</div>
			)
		})
	}

	render() {

		const critters = this._renderCritters();

		return (
			<div className="s-i__critter-catalogue">
				{critters}

				<h6>*Please see Coronavirus—Jericho Brown et.al. for reports by Lyle Fearnley and Christos Lynteris; Audrey T. Lin and Morgan Myers; and James Hassell et. al.</h6>
			</div>
		)
	}
}


@inject("store") @observer
class CatalogueCritter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hovered: false
		}
	}

	onMouseEnter = () => {
		return
		this.setState({
			hovered: true
		})
	}

	onMouseLeave = () => {
		return
		this.setState({
			hovered: false
		})
	}

	render() {
		const name = get(this.props.data, "fields.critter.fields.name");
		const image = get(this.props.data, "fields.critter.fields.image");
		const author = get(this.props.data, "fields.author", []);
		
		const poster = this.props.data;
		let authors = poster.fields.author[0].fields.name;

		if (poster.fields.author.length > 1) {
			authors = poster.fields.author[0].fields.name + " & " + poster.fields.author[1].fields.name
		}

		if (poster.fields.author.length > 2) {
			authors = poster.fields.author[0].fields.name + " et al."
		}
		
		const landscapeSlug = poster.fields.critterLandscapeInfrastructureConnection[Math.floor(Math.random() * poster.fields.critterLandscapeInfrastructureConnection.length)].fields.landscape.fields.slug;
		const posterSlug = poster.fields.slug;

		if (poster.fields.slug === "coronavirus-stories-are-still-emerging") {
			// authors = "Jericho Brown; Lyle Fearnley and Christos Lynteris; Audrey T. Lin and Morgan Myers; James Hassell et al."
		}

		const visited = get(this.props.store.uiStore.registeredPages.poster,`[${this.props.data.fields.slug}.visited]`, false);

		return (
			<Link to="landscape.view" params={{id: landscapeSlug}} activePoster={posterSlug}> 
				<div  className={visited ? "critter-catalogue__critter critter-catalogue__critter--visited" : "critter-catalogue__critter"}>
					<ResponsiveImage className="critter-catalogue__critter__image" image={image} sizes={[200]} useSizes/>
					<h5 className="critter-catalogue__critter__name" dangerouslySetInnerHTML={{__html: window.marked(name || "", {renderer: window.markedRenderer})}}>
					</h5>
					<h5 className="critter-catalogue__author" >{authors}</h5>
				</div>
			</Link>
		)
	}
}

// const CatalogueCritterDetails = (props) => {
// 	const landscape = get(props.data, "fields.landscape.fields.name");
// 	const infrastructure = get(props.data, "fields.infrastructure.fields.name");
// 	const fq = get(props.data, "fields.feralQuality[0].fields.name");

// 	return (
// 		<div className="critter-catalogue__details">
// 			<div className="critter-catalogue__details__section">
// 				<h6>{landscape}</h6>
// 			</div>
// 			<div className="critter-catalogue__details__section">
// 				<h6>{infrastructure}</h6>
// 			</div>
// 			<div className="critter-catalogue__details__section">
// 				<h6>{fq}</h6>
// 			</div>
// 		</div>
// 	)
// }

export default SuperIndexCritterCatalogue
