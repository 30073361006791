import React, {Component} from 'react'

import {
	InlineAudio,
	InlineImage,
	FeralQualityInline,
	FeralQualityInlineWithText,
	FeralQualityBlock
} from './'

import {
	QueryParamLink,
	Link
} from '../link'

import {
	slugify
} from '../../../helpers'

import get from 'lodash/get'

import {inject, observer} from 'mobx-react'

const FQRichTextSection = (props) => {
	const data = [];
	let fqFlag = props.faFlag || false;
	let refFlag = false;
	let fqData = [];
	let feralQuality = null;
	let inlineImage = null;
	let inlineContent = [];
	let currentSections = {};
	let isBlockQuote = '';
	let key;
	
	const paragraph = props.data.content.forEach((node, idx) => {
		let color = props.color;
		let inlineColor = {color: 'inherit'};


		if (node.nodeType === "text") {
			let markClasses = '';
			if (node.marks && node.marks.length) {
				 markClasses = node.marks.map((mark) => {
					 inlineColor = {color: 'inherit'};
					 if (mark.type === "bold" || mark.type === "qp-link") {
						 inlineColor = color;
					 }
					return mark.type
				})
				markClasses.join(" ");
			} else {
				inlineColor = {color: 'inherit'};
			}

			if (!node.value) return;
			if (refFlag) {
				data.push(<ReferenceTag key={idx} node={node}/>)
			} else {
				data.push(<span key={idx} className={`${markClasses}`} style={inlineColor} dangerouslySetInnerHTML={{__html: window.marked(node.value || "", {renderer: window.markedRenderer})}}></span>)
			}
		} else if (node.nodeType === "embedded-entry-inline") {
			const type = node.data.target.sys.contentType.sys.id;
			if (type === "referenceTag") {
				refFlag = !refFlag;
			}
		} else if (node.nodeType === "entry-hyperlink") {
			const type = node.data.target.sys.contentType.sys.id;
			if (type === "contributor") {
				const name = get(node.data, "target.fields.name", "");
				inlineColor = color;
				data.push(<QueryParamLink key={idx} style={inlineColor} to="modal" params={{text: slugify(name), ttype: type}}><span>{name}</span></QueryParamLink>)
			} else if (type === "poster") {
				const slug = node.data.target.fields.slug;
				inlineColor = {color: color};
				const content = node.content.map(item => {
					let markClasses = null;
					if (item.marks.length) {
						 markClasses = item.marks.map((mark) => {
							 inlineColor = {color: 'inherit'};
							 if (mark.type === "bold" || mark.type === "qp-link") {
								 inlineColor = color;
							 }
							return mark.type
						}).join(" ")
					}
					return <span className={markClasses}>{item.value}</span>
				})

				data.push(<Link to="critter.view" style={inlineColor} params={{id: slug}}><span>{content}</span></Link>)
			} else if (type === "essay") {
				const title = get(node.data, "target.fields.title", "");
				const slug = get(node.data, "target.fields.slug", "");
				inlineColor = {color: color};
				data.push(<QueryParamLink key={idx} style={inlineColor} to="modal" params={{text: slug, ttype: type}}><span>{title}</span></QueryParamLink>)
			} else {
        return null;
      }
		}
	})

	return (
		<div className={feralQuality || inlineContent.length ? "poster__body__section poster__body__section--content" : "poster__body__section"}>
			<div className={`poster__body__text`}>
				{data.map(item => {
					return item;
				})}
			</div>
		</div>
	)
}

@inject("store") @observer
class ReferenceTag extends Component {
	render() {
		const {node} = this.props;

		return (
			<sup onClick={this.props.store.uiStore.openAndScrollToFQRef} className="poster__body__reference fq_reference" dangerouslySetInnerHTML={{__html: window.marked(node.value || "", {renderer: window.markedRenderer})}}></sup>
		)
	}
}

export default FQRichTextSection
