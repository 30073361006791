import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'
import {toJS} from 'mobx'
import {
	ResponsiveImage
} from '../general/image'
@inject("store") @observer
class PosterLightbox extends Component {

	_toggleLB = () => {
		this.props.store.uiStore.togglePosterLightbox();
	}

	render() {
		const {poster__activePosterLightbox} = this.props.store.uiStore;
		if (!poster__activePosterLightbox) return null
		const {uiStore: {poster__activeLightboxContent}} = this.props.store;
		const activeLightboxContent = toJS(poster__activeLightboxContent);
		let captionContents = [];
		const getContent = (node, i) => {
			if (node.sys && node.sys.type === "Asset") {
				return (
					captionContents.unshift(<ResponsiveImage className="poster__body__inline-image" image={node} sizes={[1400]} q={75}/>)
				)
			} else if (node.nodeType === "paragraph") {
				node.content.forEach((n, idx) => {
					if (n.nodeType === "text") {
						return (
							captionContents.push(<span dangerouslySetInnerHTML={{__html: window.marked(n.value || "", {renderer: window.markedRenderer})}}></span>)
						)
					}
				})
			} else if (node.nodeType === "embedded-entry-block") {
				return (
					captionContents.unshift(<ResponsiveImage className="poster__body__inline-image" image={node.data.target.fields.media} sizes={[1400]} q={75}/>)
				)
			} else { return null;}
		}

		if (activeLightboxContent.nodeType) {
			getContent(activeLightboxContent);
		} else {
			activeLightboxContent.forEach((node) => {
				if (node.length > 0) {
					node.forEach((imageNode) => {getContent(imageNode)});
				} else {
					getContent(node);
				}
			})
		}

		return (
			<div className="poster-lightbox">
				<div className="close-button" onClick={this._toggleLB}>CLOSE</div>
				<div className="poster-lightbox-content">
					{captionContents}
				</div>
			</div>
		)
	}
}

export default PosterLightbox
