import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {ContentDrawerBodySection} from './'

import {
	FQReferencesSection,
	ReferencesSection,
	AcknowledgmentsSection
} from '../../general/text'

import {
	ContentDrawerReferences
} from './'

import {
	CritterCarousel
} from '../../slideshow'

import {
	slugify
} from '../../../helpers'


import get from 'lodash/get'

@inject("store") @observer
class ContentDrawerBody extends Component {

	componentDidMount() {
		this.props.store.uiStore.setEssayContentWrapEl(this.refs.contentWrap);
	}

	componentDidUpdate() {
		if (!this.refs.contentWrap) return
			
		this.refs.contentWrap.scrollTop = 0;
	}

	_renderText() {
		const {essayData} = this.props.store;

		const data = get(essayData, "fields.text.content", []);

		return data.map((section) => {
			return <ContentDrawerBodySection data={section}/>
		})
	}

	render() {
		const {essayData, siteDataStore: {posterDataByFeralQuality}} = this.props.store;

		const references = get(essayData, "fields.references");
		const acknowledgments = get(essayData, "fields.acknowledgments");

		const {windowHeight} = this.props.store.uiStore;

		const heightStyles = {
			height: windowHeight + 47
		}

		const text = this._renderText();
		const title = get(essayData, "fields.displayTitle") || get(essayData, "fields.title");
		const authors = get(essayData, "fields.author", []).map(author => author.fields.name).join(" & ");
		const isFeralQualityEssay = get(essayData, "fields.isFeralQualityEssay");

		return (

			<div ref="contentWrap" style={heightStyles} className="cd__body">
				<h3 className="poster__subtitle">{title}</h3>
				<h3 className="poster__author">{authors}</h3>
				{text}
				{/*
					<FQReferencesSection references={references}/>
					<ReferencesSection references={references}/>
				*/}
				<ContentDrawerReferences references={references}/>

				<AcknowledgmentsSection acknowledgments={acknowledgments}/>

				{isFeralQualityEssay ? 
					<div className="cd__fq-carousel-section">
						<h3 className="poster__subtitle">Explore this Feral Quality</h3><br/>
						 <CritterCarousel posters={posterDataByFeralQuality[slugify(title)]}/> 
					</div>
				: null}
			</div>
		)
	}
}

export default ContentDrawerBody
