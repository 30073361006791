import React, {Component} from 'react'

class ReferencesSection extends Component {
	render() {
		const {references} = this.props;
		if (!references) return null
		return (
			<div  dangerouslySetInnerHTML={{__html: window.marked(references || "", {renderer: window.markedRenderer})}}>
			</div>
		)
	}
}

export default ReferencesSection
