import { observable, computed, action } from 'mobx';

import {slugify} from '../helpers'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep';

const contentful = require("contentful");

const contentfulClient = contentful.createClient({
  accessToken:'XDPMBTaruhy8TIEmii1D8nFLgndVKrw1i_bmpVXSVJw',
  space: 'zj7ld17eklg3',
  removeUnresolved: true
});
var stringify = require('json-stringify-safe');


class SiteDataStore {
	constructor(rootStore) {
		this.rootStore = rootStore;

		this.data = {
			// globalSiteData: null,
			// poster: [],
			// infrastructure: [],
			// fq: [],
			// landscape: [],
			// essay: [],
			// contributor: [],
		    // essayCategory: []
			globalSiteData: window.__APPDATA__.globalSiteData,
			poster: window.__APPDATA__.poster,
			infrastructure: window.__APPDATA__.infrastructure,
			fq: window.__APPDATA__.fq,
			landscape: window.__APPDATA__.landscape,
			essay: window.__APPDATA__.essay,
			contributor: window.__APPDATA__.contributor,
			essayCategory: window.__APPDATA__.essayCategory

		}


		this.extraData = {
			poster: {},
			essay: {}
		}
	}

	@observable allDataReady = false;

	@observable extraDataExists = {
		poster: {},
		essay: {}
	}

	@computed get posterDataByName() {
		const data = {};

		this.data.poster.forEach((item, idx) => {
			data[item.fields.slug] = item;

			// if (this.extraDataExists.poster[item.fields.slug]) {
			// 	const {textContent, acknowledgments, references, feralQualityReferences} = this.extraData.poster[item.fields.slug];

			// 	data[item.fields.slug].fields.textContent = textContent;
			// 	data[item.fields.slug].fields.acknowledgments = acknowledgments;
			// 	data[item.fields.slug].fields.references = references;
			// 	data[item.fields.slug].fields.feralQualityReferences = feralQualityReferences;
			// }

			item.idx = idx;
		})

		return data
	}

	@computed get fqDataByName() {
		const data = {};

		this.data.fq.forEach((item) => {
			data[slugify(item.fields.name)] = item;
		})

		return data
	}

	@computed get infrastructureDataByName() {
		const data = {};

		this.data.infrastructure.forEach((item) => {
			data[slugify(item.fields.name)] = item;
		})

		return data
	}

	@computed get landscapeDataByName() {
		const data = {};

		this.data.landscape.forEach((item) => {
			data[item.fields.slug] = item;
		})

		return data
	}

	@computed get crittersByName() {
		const data = {};

		this.data.poster.forEach((poster) => {
			const slug = slugify(get(poster, "fields.critter.fields.name", ""));
			if (!data[slug]) {
				data[slug] = []
			}

			data[slug].push(poster)
		});

		return data;
	}


	@computed get posterDataByLandscape() {
		const data = {};

		this.data.poster.forEach(item => {
			const landscapes = get(item, "fields.landscape", []);

			landscapes.forEach(landscape => {
				const slug = slugify(get(landscape, "fields.name", ""));

				const exists = !!data[slug];

				if (!exists) {
					data[slug] = [];
				}

				data[slug].push(item);

			})


		})

		return data;
	}


	@computed get CLICDataByID() {
		const data = {};

		this.data.poster.forEach(item => {
			const clics = get(item, "fields.critterLandscapeInfrastructureConnection", []);

			clics.forEach(clic => {
				const id = clic.sys.id;
				data[id] = clic;
			});
		});

		return data;
	}

	@computed get posterDataByFeralQuality() {
		const data = {};

		this.data.poster.forEach(item => {
			if (!item.fields.feralQuality) return

			item.fields.feralQuality.forEach((fq) => {
				const slug = slugify(get(fq, "fields.name", ""));

				const exists = !!data[slug];

				if (!exists) {
					data[slug] = [];
				}

				data[slug].push(item);
			})

		})

		return data;
	}

	@computed get posterDataByCritter() {
		const data = {};
		this.data.poster.forEach((item) => {

			const slug = slugify(get(item, "fields.critter.fields.name", ""));

			const exists = !!data[slug];

			if (!exists) {
				data[slug] = [];
			}

			data[slug].push(item);
		})

		return data
	}

	@computed get posterDataByInfrastructure() {
		const data = {};

		this.data.poster.forEach(item => {
			const author = item.fields.author[0].fields.name
			item.fields.critterLandscapeInfrastructureConnection.forEach(clic => {
				const infrastructureSlug = clic.fields.infrastructure.fields.slug;
				const exists = !!data[infrastructureSlug];

				if (!exists) {
					data[infrastructureSlug] = [];
				};

				const copy = {};
				copy.fields = item.fields;
				copy.landscapeConnection = clic.fields.landscape.fields.slug;

				data[infrastructureSlug].push(copy);
			})
		})

		return data;
	}


	@computed get posterDataByInfrastructureByLandscape() {
		const data = {};

		this.data.poster.forEach(item => {
			const infrastructureSlug = slugify(get(item, "fields.infrastructure[0].fields.name", ""));
			const landscapeSlug = slugify(get(item, "fields.landscape[0].fields.name", ""));

			const existsL = !!data[landscapeSlug];

			if (!existsL) {
				data[landscapeSlug] = {};
			}

			const existsI = !!data[landscapeSlug][infrastructureSlug];

			if (!existsI) {
				data[landscapeSlug][infrastructureSlug] = []
			}

			data[landscapeSlug][infrastructureSlug].push(item);
		})

		return data;
	}

	@computed get essayDataByName() {
		const data = {};

		this.data.essay.forEach(item => {
			data[item.fields.slug]= (item);
		})

		return data;
	}

  @computed get essayCategoryForReadingRoom() {
		const data = {};
		this.data.essayCategory.forEach(item => {

			const category = item.fields.order;
			if (!data[category]) {
				data[category] = [];
			}

			data[category].push(item);
		})

		return data;
	}

	@computed get essayDataByCategory() {
		const data = {};

		this.data.essay.forEach(item => {
			const category = item.fields.textCategory;
			if (!data[category]) {
				data[category] = [];
			}

			data[category].push(item);
		})

		return data;
	}

	@computed get contributorDataByName() {
		const data = {};

		this.data.contributor.forEach(item => {
			data[item.fields.slug]= (item);
		})

		return data;
	}

	fetchSiteData() {
		Promise.all([
			// this.fetchGlobalSiteData(),
			// this.fetchPosterData(),
			// this.fetchInfrastructureData(),
			// this.fetchLandscapeData(),
			// this.fetchFQData(),
			// this.fetchEssayData(),
	        // this.fetchEssayCategoryData(),
			// this.fetchContributorData()
		]).then(() => {
			this.allDataReady = true;
			// this.parseAllData()
		})
	}

	fetchGlobalSiteData() {
		return contentfulClient.getEntry("wCTB4OBZBLabYa8ImxX6f")
			.then(this.setGlobalSiteData.bind(this))
	}

 	fetchPosterData() {
		const params = {
			content_type: "poster",
			select: "fields.author,fields.critter,fields.critterLandscapeInfrastructureConnection,fields.feralQuality,fields.flowMapMedia,fields.flowMapType,fields.infrastructure,fields.landscape,fields.posterType,fields.slug,fields.title"
		}

		return contentfulClient.getEntries(params)
					.then(this.registerData.bind(this, "poster"))
					.then(this.setPosterData.bind(this))
					.catch((err) => console.log(err));
	}

	@action.bound fetchPoster(slug) {

		fetch(`/data/posters/${slug}.json`)
			.then(data => {
				return data.json()
			})
			.then(this.setSinglePosterData.bind(this))

		// const params = {
		// 	content_type: "poster",
		// 	"fields.slug": slug,
		// 	"select": "fields.slug,fields.textContent,fields.acknowledgments,fields.references,fields.feralQualityReferences",
		// 	include: 2,
		// 	limit: 1
		// }

		// return contentfulClient.getEntries(params)
		// 			.then(this.setSinglePosterData.bind(this))
		// 			.catch((err, m) => console.log(err));
	}


 	fetchInfrastructureData() {
		const params = {
			content_type: "infrastructure",
			// include: 3
		}

		return contentfulClient.getEntries(params)
					.then(this.setInfrastructureData.bind(this))
					.catch((err) => console.log(err));
	}



	fetchLandscapeData() {
		const params = {
			content_type: "landscape",
			include: 1
		}

		return contentfulClient.getEntries(params)
					.then(this.setLandscapeData.bind(this))
					.catch((err) => console.log(err));
	}

	preloadLandscapeImage(landscapeSlug) {
		// const landscape = this.landscapeDataByName[landscapeSlug];
		// const src = `${landscape.fields.map.fields.file.url}?w=3000&q=92&fm=jpg`;
		const src = `/images/archive-images/${landscapeSlug}.jpg`
		const image = new Image();
		image.src = src;

	}

	fetchFQData() {
		const params = {
			content_type: "feralQuality",
		}

		return contentfulClient.getEntries(params)
					.then(this.setFQData.bind(this))
					.catch((err) => console.log(err));
	}

	registerData(type, data) {
		data.items.forEach(item => {

			const poster = get(item, "fields.slug", "");

			this.rootStore.uiStore.registerData("poster", poster);

      if (item.fields.infrastructure) {
        item.fields.infrastructure.forEach(inf => {
          const infrastructure = slugify(get(inf, "fields.name", ""));
          this.rootStore.uiStore.registerData("infrastructure", infrastructure);
          this.rootStore.uiStore.registerDependency("poster", poster, "infrastructure", infrastructure);
        });
      }

      if (item.fields.landscape) {
  			item.fields.landscape.forEach(lnd => {
  				const landscape = slugify(get(lnd, "fields.name", ""));
  				this.rootStore.uiStore.registerData("landscape", landscape, null);
  				this.rootStore.uiStore.registerDependency("poster", poster, "landscape", landscape);
  			});
      }
		});

		return data;
	}


	fetchEssayData() {
		const params = {
			content_type: "essay",
			select: "fields.title,fields.displayTitle,fields.readingRoomDisplayTitle,fields.slug,fields.subHeading,fields.textCategory,fields.author,fields.isFeralQualityEssay"
		};

		return contentfulClient.getEntries(params)
					.then(this.setEssayData.bind(this))
					.catch((err) => console.log(err));
	}

	@action.bound fetchEssay(slug) {

		fetch(`/data/essays/${slug}.json`)
			.then(data => {
				return data.json()
			})
			.then(this.setSingleEssayData.bind(this))
			// const params = {
			// 	content_type: "essay",
			// 	"fields.slug": slug,
			// 	"select": "fields.slug,fields.text,fields.acknowledgments,fields.references",
			// 	include: 1,
			// 	limit: 1
			// }

			// return contentfulClient.getEntries(params)
			// 			.then(this.setSingleEssayData.bind(this))
			// 			.catch((err, m) => console.log(err));
	}

	@action.bound setSingleEssayData(data) {
		// if (!data.items.length) return
		// const essay = data.items[0];

		if (!data) return;
		const essay = data;
		const slug = essay.fields.slug;
		this.extraData.essay[slug] = {
			text: essay.fields.text,
			acknowledgments: essay.fields.acknowledgments,
			references: essay.fields.references
		};

		this.extraDataExists.essay[slug] = true;

		// const replacePosterAtIndex = this.posterDataByName[slug].idx;
		return data;
		// this.data.poster[replacePosterAtIndex].fields.textContent = poster.fields.textContent;
	}

  fetchEssayCategoryData() {
		const params = {
			content_type: "essayCategory"
		};

		return contentfulClient.getEntries(params)
				.then(this.setEssayCategoryData.bind(this))
				.catch((err) => console.log(err));
	}
	
	fetchContributorData() {
		const params = {
			content_type: "contributor",
			select: "fields.name,fields.lastName,fields.bioSketch,fields.slug,fields.discipline",
			limit: 200
		}

		return contentfulClient.getEntries(params)
					.then(this.setContributorData.bind(this))
					.catch((err) => console.log(err));
	}

	setGlobalSiteData(data) {
		this.data.globalSiteData = data;
	}

	setPosterData(data) {
		this.data.poster = data.items;
	}

	@action.bound setSinglePosterData(data) {
		if (!data) return

		// const poster = data.items[0];

		const poster = data;
		const slug = poster.fields.slug;

		this.extraData.poster[slug] = {
			textContent: poster.fields.textContent,
			acknowledgments: poster.fields.acknowledgments,
			references: poster.fields.references,
			feralQualityReferences: poster.fields.feralQualityReferences
		};


		this.extraDataExists.poster[slug] = true;

		// const replacePosterAtIndex = this.posterDataByName[slug].idx;
		return data;
		// this.data.poster[replacePosterAtIndex].fields.textContent = poster.fields.textContent;
	}

	setInfrastructureData(data) {
		this.data.infrastructure = data.items;
	}

	setLandscapeData(data) {
		this.data.landscape = data.items;
	}

	setFQData(data) {
		this.data.fq = data.items;
	}

	setEssayData(data) {
		this.data.essay = data.items;
	}
	setEssayCategoryData(data) {
		this.data.essayCategory = data.items;
	}
	setContributorData(data) {
		this.data.contributor = data.items;
	}

	// parseAllData() {
	// 	this.parsedData = {};
		
	// 	this.parsedData = cloneDeep(this.data);
	// 	const posterData = stringify(this.parsedData.poster)
		
	// }

	// DELETE BEFORE FINAL UPLOAD

	@observable siteImages = [];
	@action.bound fetchSiteImages() {
		const params = {
			limit: 1000,
			select: "fields"
		}

		contentfulClient.getAssets(params)
			.then(this.setSiteImages)
			.catch(function (e) {
				console.log(e);
			});
	}

	@action.bound setSiteImages(assets) {
		const sorted = assets.items.sort((a,b) => {
			const titleA = a.fields.title;
			const titleB = b.fields.title;
			if (titleA < titleB) return -1;
			if (titleA > titleB) return 1;

			return 0;
		});


		this.siteImages = assets.items;
	}


	@computed get getAllSiteData() {
		
		const parsedData = cloneDeep(this.data);
		

		parsedData.contributor.forEach(contributor => {
			contributor.fields.bioSketch.content.forEach(content => {
				content.content.forEach(node => {
					if (node.nodeType === "entry-hyperlink") {
						const type = node.data.target.sys.contentType.sys.id;
						if (type === "poster") {
							delete node.data.target.fields.textContent
							delete node.data.target.fields.author
							delete node.data.target.fields.acknowledgments
							delete node.data.target.fields.references
							delete node.data.target.fields.feralQualityReferences
							delete node.data.target.fields.thoughtBomb
						} else if (type === "essay") {
							delete node.data.target.fields.text
							delete node.data.target.fields.author
							delete node.data.target.fields.acknowledgments
							delete node.data.target.fields.references
							delete node.data.target.fields.textCategory
						}
					}
					
				})
			})
		})

		parsedData.poster.forEach(poster => {

			poster.fields.author.forEach(author => {
				delete author.fields.bioSketch
			})

			poster.fields.landscape.forEach(landscape => {
				delete landscape.fields.longDescription
				delete landscape.fields.longDescriptionRichText
				delete landscape.fields.map
			})

			poster.fields.infrastructure.forEach(infrastructure => {
				delete infrastructure.fields.longDescription
				delete infrastructure.fields.longDescriptionRichText
				delete infrastructure.fields.poemImage

				delete infrastructure.fields.shortDescription
				delete infrastructure.fields.videoIntroductionText
			})

			poster.fields.feralQuality.forEach(feralQuality => {
				delete feralQuality.fields.shortDescription
				delete feralQuality.fields.shortDescriptionRichText

			})

		})

		parsedData.essayCategory.forEach(essayCategory => {
			essayCategory.fields.essays.forEach(linkedText => {
				delete linkedText.fields.text
			})
		})

		parsedData.infrastructure.forEach(landscape => {
			landscape.fields.linkedTexts.forEach(linkedText => {
				delete linkedText.fields.text
			})
		})

		parsedData.landscape.forEach(landscape => {
			landscape.fields.linkedTexts.forEach(linkedText => {
				delete linkedText.fields.text
			})
		})

		return parsedData;
	}

	// DELETE BEFORE FINAL UPLOAD



}

export default SiteDataStore
