import React, {Component} from 'react'

class FourOhFour extends Component {
	render() {
		return (
			<div className="">
				<h1>404 - Page not found</h1>
			</div>
		)
	}
}

export default FourOhFour