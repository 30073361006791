import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	slugify
} from '../../../helpers'

import get from 'lodash/get'

@inject("store") @observer
class LandscapeMapCanvas extends Component {
	componentDidMount() {
		this.canvas = this.refs.canvas;
		var dpr = window.devicePixelRatio || 1;

		this.canvas.width = this.props.store.uiStore.windowWidth * dpr;
	    this.canvas.height = this.props.store.uiStore.windowHeight * dpr;
 
	    var ctx = this.canvas.getContext('2d');
	    ctx.scale(dpr, dpr);

		this.gkhead = new Image;

		const landscape = this.props.store.siteDataStore.landscapeDataByName[this.props.store.routerStore.current.params.id];

		// const imageSrc = `${landscape.fields.map.fields.file.url}?w=${this.props.store.uiStore.landscapeImageWidths[landscape.fields.slug]}&q=92&fm=jpg`;;
		const imageSrc = `/images/archive-images/${this.props.store.routerStore.current.params.id}.jpg`
		this.gkhead.src = imageSrc

		this.ctx = this.canvas.getContext('2d');
		this.scale = 1;

		this.lastX = this.canvas.width/2;
		this.lastY = this.canvas.height/2;

		this.dragStart = false;
		this.dragged = false;
		this.scaleFactor = 1.02;

		this.imageWidth = 0;
		this.imageHeight = 0;

		this.POIs = [];

		this.ready = false;
		this.navigating = false;

		this.gkhead.onload = () => {
			this._init()
		}
	}

	_setInitialScale() {
		this.scale = 1;
		const windowWidth = this.props.store.uiStore.windowWidth;
		const windowHeight = this.props.store.uiStore.windowHeight
		const imageWidth = this.gkhead.width;
		const imageHeight = this.gkhead.height;
		const drawerHeight = 87;

		let xTranslate, yTranslate;

		if (this.activePOIX && this.activePOIY) {
			xTranslate = (windowWidth / 2) - ((this.activePOIX) * this.scale) //-(imageWidth / 2 * this.scale) // + ((this.activePOIX / 2) * this.scale);
			yTranslate = ((windowHeight - drawerHeight) / 2) - ((this.activePOIY) * this.scale) // - (imageHeight / 2 * this.scale) //+ ((this.activePOIY / 2) * this.scale);
			
			if (xTranslate > 0) {
				xTranslate = 0;
			} else if (Math.abs(xTranslate) > imageWidth - (windowWidth)) {
				xTranslate = (imageWidth - (windowWidth)) * -1
			}

			if (yTranslate > 0 ) {
				yTranslate = 0;
			} else if (Math.abs(yTranslate) > imageHeight - (windowHeight - drawerHeight)) {

				yTranslate = (imageHeight - (windowHeight - drawerHeight)) * -1
			}
		} else {
			xTranslate = (windowWidth / 2) - (imageWidth / 2 * this.scale) // + ((this.activePOIX / 2) * this.scale);
			yTranslate = ((windowHeight - drawerHeight) / 2) - (imageHeight / 2 * this.scale) //+ ((this.activePOIY / 2) * this.scale);
		}		

		this.ctx.translate(xTranslate,yTranslate);

		this.ctx.scale(this.scale,this.scale);
	}

	_init() {

		this.imageWidth = this.gkhead.width;
		this.imageHeight = this.gkhead.height;


		this.trackTransforms(this.ctx);
		this._setPointsOfInterest();
		this._setInitialScale();

		this.redraw();



		this.canvas.addEventListener('DOMMouseScroll',this.handleScroll,false);
		this.canvas.addEventListener('mousewheel',this.handleScroll,false);

		this.ready = true;
	}

	_setPointsOfInterest() {
		const currentCritters = this.props.store.siteDataStore.posterDataByLandscape[this.props.store.routerStore.current.params.id];
		if (!currentCritters) return null
		const landscapeName = this.props.store.routerStore.current.params.id

		currentCritters.forEach((critter) => {
			const landscapeConnection = get(critter, "fields.critterLandscapeInfrastructureConnection")
			if (!landscapeConnection) return null
			
			landscapeConnection.forEach(clic => {
				const name = clic.fields.landscape.fields.slug;
				if (name !== landscapeName) return
				let clicData = clic;

				// if (name === landscapeName) {
				// 	clicData = landscapeConnection[i]
				// 	break;
				// }

				const {x,y} = get(clicData, "fields.landscapeLocation", {})
				const xPos = (x / 100) * this.imageWidth;
				const yPos = (y / 100) * this.imageHeight;
				const slug = get(critter, "fields.slug", "")

				let activePoster = false;
				if (slug === this.props.store.uiStore.landing__activePoster) {
					activePoster = true;
					this.activePOIX = xPos;
					this.activePOIY = yPos;
				}

				this.POIs.push({
					activePoster,
					location: {
						x: xPos,
						y: yPos
					},
					data: {
						slug: critter.fields.slug,
						displayName: clicData.fields.displayName,
						clicID: clic.sys.id
					}
				});
			})


		})

	}

	drawPointsOfInterest() {
		let alpha = ((this.scale - .8) / .3);

		this.POIs.forEach(poi => {
			const {location: {x, y}} = poi;

			if (poi.activePoster || this.scale > .8) {
				this.ctx.beginPath();

				if (poi.activePoster) {
					this.ctx.arc(x, y, 9 / this.scale, 0, 2 * Math.PI);

					this.ctx.globalAlpha = 1;
					this.ctx.fillStyle = "rgb(229,49,52)"
					this.ctx.strokeStyle = "rgb(229,49,52)";
				} else {
					this.ctx.arc(x, y, 7 / this.scale, 0, 2 * Math.PI);

					this.ctx.globalAlpha = alpha;
					this.ctx.fillStyle = "rgb(20,20,20)";
					this.ctx.strokeStyle = "white";
				}

				this.ctx.lineWidth = 2 / this.scale;

				this.ctx.stroke();
				this.ctx.fill();
			}
		});

		this.ctx.globalAlpha = 1;
	}

	checkPointOfInterestIntersection(mousePos) {
		let hit = false;
		this.POIs.forEach(poi => {
			if (hit) return
			const {location: {x: x1, y: y1}} = poi;
			const {x: x2, y: y2} = mousePos;
			

			const a = x1 - x2;
			const b = y1 - y2;

			const c = Math.sqrt( a*a + b*b );
			
			if (c < (12 / this.scale)) {
				this.props.store.uiStore.setActiveLandscapePoster(poi.data.slug, poi.data.displayName, poi.data.clicID);

				this.props.store.uiStore.setActiveLandscapePosterLocation({x: this.lastX, y:this.lastY})
				hit = true;
			}
		})

		if (!hit) {
			this.props.store.uiStore.setActiveLandscapePoster(null, null)
		}
	}

	redraw = () => {

	      // Clear the entire this.canvas
	      var p1 = this.ctx.transformedPoint(0,0);
	      var p2 = this.ctx.transformedPoint(this.canvas.width,this.canvas.height);
	      this.ctx.clearRect(p1.x,p1.y,p2.x-p1.x,p2.y-p1.y);

	      this.ctx.save();
	      this.ctx.setTransform(1,0,0,1,0,0);
	      this.ctx.clearRect(0,0,this.canvas.width,this.canvas.height);
	      this.ctx.restore();

	      this.ctx.drawImage(this.gkhead,0,0);

		  if (this.scale) {
		  	this.drawPointsOfInterest()
		  }

	  }

	handleScroll = (evt) => {
		if (!this.ready) return

	    var delta = evt.wheelDelta ? evt.wheelDelta/40 : evt.detail ? -evt.detail : 0;
	    if (delta) this.zoom(delta);
	    return evt.preventDefault() && false;
	}

	zoom = (clicks) => {
		if (!this.ready) return

		let {a} = this.ctx.getTransform();
		this.scale = a;
		if (a < .2 && clicks < 0){
			return
		}

		if (a > 2.5 && clicks > 0){
			return
		}


	    var pt = this.ctx.transformedPoint(this.lastX,this.lastY);
	    this.ctx.translate(pt.x,pt.y);
	    var factor = Math.pow(this.scaleFactor,clicks);


	    this.ctx.scale(factor,factor);
	    this.ctx.translate(-pt.x,-pt.y);


	    this.redraw();
	}

	_handleMouseDown = (evt) => {
		if (!this.ready) return
		this.canvas.classList.add("grabbed");


		// document.body.style.mozUserSelect = document.body.style.webkitUserSelect = document.body.style.userSelect = 'none';
		this.lastX = evt.offsetX || (evt.pageX - this.canvas.offsetLeft);
		this.lastY = evt.offsetY || (evt.pageY - this.canvas.offsetTop);
		this.dragStart = this.ctx.transformedPoint(this.lastX,this.lastY);
		this.dragged = false;
	}

	_handleMouseMove = (evt) => {
		if (!this.ready) return
		if (this.navigating) return

		this.lastX = evt.offsetX || (evt.pageX - this.canvas.offsetLeft);
		this.lastY = evt.offsetY || (evt.pageY - this.canvas.offsetTop);

		this.dragged = true;
		var pt = this.ctx.transformedPoint(this.lastX,this.lastY);
	
		if (this.dragStart){
		  this.ctx.translate(pt.x-this.dragStart.x,pt.y-this.dragStart.y);
		  this.redraw();
	    } else {
	    	this.checkPointOfInterestIntersection(pt)
	    }
	}

	_handleMouseUp = (evt) => {
		if (!this.ready) return
		this.canvas.classList.remove("grabbed");

		this.dragStart = null;
		const clicID = this.props.store.uiStore.landscape__activePoster__clicID;
		const critterSlug = this.props.store.uiStore.landscape__activePoster;

		if (critterSlug && clicID) {
			this.navigating = true;
			const critter = this.props.store.siteDataStore.posterDataByName[this.props.store.uiStore.landscape__activePoster];
			const clic = this.props.store.siteDataStore.CLICDataByID[clicID];

			const infrastructure = get(clic, "fields.infrastructure.fields.name","");

			this.props.store.uiStore.setActivePoster(critter.fields.slug);
			this.props.store.routerStore.router.navigate("infrastructure.view", {id: slugify(infrastructure)})
		}


		return

		// if (this.props.store.uiStore.landscape__activePoster) {
		// 	const critter = this.props.store.siteDataStore.posterDataByName[this.props.store.uiStore.landscape__activePoster];

		// 	const landscapeConnection = get(critter, "fields.critterLandscapeInfrastructureConnection");

		// 	if (!landscapeConnection) return null

		// 	const landscapeName = this.props.store.routerStore.current.params.id;
		// 	let clicData = null;

		// 	for (var i = 0; i < landscapeConnection.length; i++) {
		// 		const name = landscapeConnection[i].fields.landscape.fields.name.toLowerCase();

		// 		if (name === landscapeName) {
		// 			clicData = landscapeConnection[i]
		// 			break;
		// 		}
		// 	}

		// 	if (!clicData) return null

		// 	const infrastructure = get(clicData, "fields.infrastructure.fields.name","");
		// 	this.props.store.uiStore.setActivePoster(critter.fields.slug);

		// 	this.props.store.routerStore.router.navigate("infrastructure.view", {id: slugify(infrastructure)})

		// }
	}

	trackTransforms = () => {
	      var svg = document.createElementNS("http://www.w3.org/2000/svg",'svg');
	      var xform = svg.createSVGMatrix();
	      const ctx = this.ctx;
	      this.ctx.getTransform = function(){ return xform; };

	      var savedTransforms = [];

	      var save = ctx.save;
	      ctx.save = function(){
	          savedTransforms.push(xform.translate(0,0));
	          return save.call(ctx);
	      };

	      var restore = ctx.restore;
	      ctx.restore = function(){
	        xform = savedTransforms.pop();
	        return restore.call(ctx);
	      };

	      var scale = ctx.scale;
	      ctx.scale = function(sx,sy){
	        xform = xform.scaleNonUniform(sx,sy);
	        return scale.call(ctx,sx,sy);
			      };

	      var rotate = ctx.rotate;
	      ctx.rotate = function(radians){
	          xform = xform.rotate(radians*180/Math.PI);
	          return rotate.call(ctx,radians);
	      };

	      var translate = ctx.translate;
	      ctx.translate = function(dx,dy){
	          xform = xform.translate(dx,dy);
	          return translate.call(ctx,dx,dy);
	      };

	      var transform = ctx.transform;
	      ctx.transform = function(a,b,c,d,e,f){
	          var m2 = svg.createSVGMatrix();
	          m2.a=a; m2.b=b; m2.c=c; m2.d=d; m2.e=e; m2.f=f;
	          xform = xform.multiply(m2);
	          return transform.call(ctx,a,b,c,d,e,f);
	      };

	      var setTransform = ctx.setTransform;
	      ctx.setTransform = function(a,b,c,d,e,f){
	          xform.a = a;
	          xform.b = b;
	          xform.c = c;
	          xform.d = d;
	          xform.e = e;
	          xform.f = f;
	          return setTransform.call(ctx,a,b,c,d,e,f);
	      };

	      var pt  = svg.createSVGPoint();
	      ctx.transformedPoint = function(x,y){
	          pt.x=x; pt.y=y;
	          return pt.matrixTransform(xform.inverse());
	      }

	      ctx.inverseTransformedPoint = function(x,y) {
	      	pt.x=x; pt.y=y;
	      	return pt.matrixTransform(xform);
	      }
	}


	render() {
		const {windowWidth, windowHeight} = this.props.store.uiStore;

		const style = {
			width: windowWidth,
			height: windowHeight
		}

		return (
			<canvas style={style} className={this.props.store.uiStore.landscape__activePoster ? "landscape__canvas landscape__canvas--showCursor" : "landscape__canvas"} ref="canvas"  onMouseMove={this._handleMouseMove} onMouseDown={this._handleMouseDown} onMouseUp={this._handleMouseUp}></canvas>
		)
	}
}

export default LandscapeMapCanvas
