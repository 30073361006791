import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import get from 'lodash/get'

import {
	slugify
} from '../../../helpers'

import {
	BlockVideo,
	BlockImage,
	FQRichTextSection,
	// RichTextSection
} from './'
import {
	QueryParamLink,
	Link
} from '../link'

@inject("store") @observer
class EditorsNote extends Component{

	open = false

	_toggleFQ = () => {
		if (this.open) return
 		if (!this.open) {
			this.open = true;
		}

		const id = this.props.data.target.sys.id;

		this.props.store.uiStore.toggleEditorsNote(id);
	}

	_renderClosed() {
		return (
			<div className="fq-section" onClick={this._toggleFQ}>
				<h3 style={{color: '#000', borderBottom: '1px solid #000'}} className="fq-section__name"><span style={{color: '#000'}}>Editors' Note</span></h3>
			</div>
		)
	}

	_renderOpen() {
		const expandableCaptionPresent = get(this.props.data.target, "fields.expandableAnalysisText") ? true : false;
    const expandableCaption = get(this.props.data.target, "fields.expandableAnalysisText");
		let expandableAnalysisMedia = "";
		let analysisText = [];
		let cutoff = false;

		if (expandableCaptionPresent) {
			expandableCaption.content.map((section) => {
				if (section.nodeType === "embedded-entry-block") {
					if (section.data.target) {
						const type = section.data.target.sys.contentType.sys.id;
						switch (type) {
							case "mediaFile":
								const contentType = section.data.target.fields.media.fields.file.contentType;
								if (section.data.target.fields.isExpandable && !section.data.target.fields.isExpandable) {
									return analysisText.push(<BlockImage data={section.data}/>)
								} else {
										return analysisText.push(<div className="toggle" onClick={() => this._toggleLB(section)}><BlockImage data={section.data}/></div>)
								}
						}
					}
				} else if (section.nodeType === "paragraph") {
					analysisText.push(<FQRichTextSection data={section}/>)
				}
			})
		}

		return (
			<div className="fq-section" onClick={this._toggleFQ}>
				<h3 style={{color: '#000;', borderBottom: '1px solid #000'}}className="fq-section__name"></h3>
				{analysisText}
			</div>
		)
	}

	render() {
    const {poster__activeEditorsNote} = this.props.store.uiStore;
		const posters = this.props.store.uiStore.poster__activeEditorsNotes;
		const contents = poster__activeEditorsNote && posters[0] === this.props.data.target.sys.id ? this._renderOpen() : this._renderClosed();
		return (
			<div className="poster__body__fq block" >
				{contents}
			</div>
		)
	}
}

export default EditorsNote
