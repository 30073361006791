import React, {Component} from 'react'
import {observer, inject} from 'mobx-react'
var stringify = require('json-stringify-safe');


@inject("store") @observer
class DataPosterList extends Component {
	render() {
		const {siteDataStore: {posterDataByName}} = this.props.store;
		const posters = Object.keys(posterDataByName).sort((a,b) => {
			if (a < b) return -1;
			if (a > b) return 1;
			return 0;
		});

		const list = posters.map(slug => {
					return <DataPoster slug={slug}/>
				});

		
		return (
			<div className="data-images">				
				{list}

				<style>{`
					.data-images h2 {
						margin: 20px 4px;
						cursor: pointer;
					}
				`}</style>
			</div>
		)
	}
}

// Helper function
function removeProps(obj){
	console.log(obj)
	// return JSON.parse(stringify(obj, (k,v) => (k === 'sys')? undefined : v));

	obj.fields.textContent.content.forEach(content => {
		console.log("here",)
		content.content.forEach(node => {
			console.log("yeah", node)
			if (node.nodeType === "entry-hyperlink") {
				const type = node.data.target.sys.contentType.sys.id;
				if (type === "poster") {
					delete node.data.target.fields.textContent
					delete node.data.target.fields.author
					delete node.data.target.fields.acknowledgments
					delete node.data.target.fields.references
					delete node.data.target.fields.feralQualityReferences
					delete node.data.target.fields.thoughtBomb
				} else if (type === "essay") {
					delete node.data.target.fields.text
					delete node.data.target.fields.author
					delete node.data.target.fields.acknowledgments
					delete node.data.target.fields.references
					delete node.data.target.fields.textCategory
				}
			}
			
		})
	})
}

@inject("store") @observer
class DataPoster extends Component {
	async componentDidMount() {
		const {siteDataStore: {fetchPoster}} = this.props.store;

		const poster = await fetchPoster(this.props.slug);
		this.data = poster.items[0];
	}

	_logData = () => {
		const removed = removeProps(this.data);
		// console.log(stringify(removed));
		console.log("data:", stringify(this.data));
	}

	render() {

		return (
			<div className="data-images" onClick={this._logData}>				
				<h2>{this.props.slug}</h2>
			</div>
		)
	}
}





export default DataPosterList