import React, {Component} from 'react'
import {observer, inject} from 'mobx-react'
var stringify = require('json-stringify-safe');


@inject("store") @observer
class DataEssayList extends Component {

	render() {
		const {siteDataStore: {essayDataByName}} = this.props.store;

		const posters = Object.keys(essayDataByName).sort((a,b) => {
			if (a < b) return -1;
			if (a > b) return 1;
			return 0;
		});

		const list = posters.map(slug => {
					return <DataEssay slug={slug}/>
				});

		
		return (
			<div className="data-images">				
				{list}
				<style>{`
					.data-images h2 {
						margin: 20px 4px;
						cursor: pointer;
					}
				`}</style>
			</div>
		)
	}
}

// Helper function
function removeProps(obj){
	return JSON.parse(stringify(obj, (k,v) => (k === 'sys')? undefined : v));
}

@inject("store") @observer
class DataEssay extends Component {
	async componentDidMount() {
		const {siteDataStore: {fetchEssay}} = this.props.store;

		const poster = await fetchEssay(this.props.slug);
		this.data = poster.items[0];
	}

	_logData = () => {
		// const removed = removeProps(this.data, ['sys']);
	}

	render() {

		return (
			<div className="data-images" onClick={this._logData}>				
				<h2>{this.props.slug}</h2>
			</div>
		)
	}
}





export default DataEssayList