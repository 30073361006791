import React, {Component} from 'react'
import {observer, inject} from 'mobx-react'

import {slugify} from '../../helpers'

var stringify = require('json-stringify-safe');

@inject("store") @observer
class DataImageList extends Component {
	componentDidMount() {
		const {siteDataStore: {fetchSiteImages}} = this.props.store;
	
		fetchSiteImages()

	}

	render() {
		const {siteDataStore: {siteImages}} = this.props.store;

		return (
			<div className="data-images">				
				<h1>ImageList</h1>
				{siteImages.map((item, idx) => {
					const {file} = item.fields
					if (file.contentType === "video/mp4") return <VideoSection key={`item-${idx}`} data={item}/>
					if (file.contentType === "audio/mpeg") return <AudioSection key={`item-${idx}`} data={item}/>

					return <ImageSection key={`item-${idx}`} data={item}/>
				})}

				<style>{`
					.data-images .section {
						margin: 40px 5px;
					}
					.data-images img {
						display: block;
					}
					.data-images .section * {
						margin: 3px 0;
					}
				`}</style>
			</div>
		)
	}
}

function toDataURL(url) {
    return fetch(url).then((response) => {
            return response.blob();
        }).then(blob => {
            return URL.createObjectURL(blob);
        });
}


function downloadFile(file, sizes, title) {
	sizes.forEach(async (size) => {
		const src = `${file.url}?q=95&fm=jpg&w=${size}`
		let a = document.createElement('a')
		a.href = await toDataURL(src);
		a.download = `${title}-${size}.jpg`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		// let a = document.createElement('a')
		// a.href = src
		// a.download = src.split('/').pop()
		// document.body.appendChild(a)
		// a.click()
		// document.body.removeChild(a)
	})
	// const image
}

const VideoSection = (props) => {
	const {title, file} = props.data.fields;

	return (
		<div className="section">
			<h3>{title}</h3>
			<h6>{file.contentType}</h6>
			<a href={file.url} download={`${slugify(title)}.mp4`} target="_blank"><button>Download</button></a>
		</div>
	)
}

const AudioSection = (props) => {
	const {title, file} = props.data.fields;

	return (
		<div  className="section"> 
			<h3>{title}</h3>
			<h6>{file.contentType}</h6>
			<a href={file.url} download={`${slugify(title)}.mp3`} target="_blank"><button>Download</button></a>
		</div>
	)
}

const ImageSection = (props) => {
	const {title, file} = props.data.fields;
	
	return (
		<div  className="section">
			<h3>{title}</h3>
			<h6>{file.contentType}</h6>
			<img src={`${file.url}?w=120&fm=jpg`} alt=""/>

			<button onClick={() => downloadFile(file, [1800, 1600, 1400, 1200, 1000, 800], slugify(title))}>Download Standard Image</button>
			<button onClick={() => downloadFile(file, [300, 100], slugify(title))}>Download Critter Image</button>
		</div>
	)
}


export default DataImageList