import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

import {
	QueryParamLink
} from '../../general/link'

import {
	slugify
} from '../../../helpers'

@inject("store") @observer
class ContentDrawerSubMenu extends Component {
	_renderEssayCategoryItems(){
		const links  = [];
		const {essayDataByCategory, essayDataByName} = this.props.store.siteDataStore;
		const essayData = this.props.store.siteDataStore.essayCategoryForReadingRoom;
		const {drawer__activeModalText} = this.props.store.uiStore;
		const essay = essayDataByName[drawer__activeModalText.slug];
		if (!essay) return null

		const tc = essay.fields.textCategory;

		const allEssays = essayDataByCategory[tc];

		return Object.keys(essayData).map(textCat=>{
			return (
				<span>
					{essayData[textCat].map(item => {
						if (item.fields.essays[0].fields.textCategory === tc) {
							return (
								<div>
									{item.fields.essays &&
										item.fields.essays.map(essay=> {
											if (essay.fields.slug === "list-of-contributors") {
												return (
													<div>
														<QueryParamLink to={"modal"} params={{text: essay.fields.slug, ttype: 'contributor'}}>{essay.fields.readingRoomDisplayTitle}</QueryParamLink>
													</div>
												)
											} else {
												return (
													<div>
														<QueryParamLink to={"modal"} params={{text: essay.fields.slug, ttype: essay.sys.contentType.sys.id}}>{essay.fields.readingRoomDisplayTitle}</QueryParamLink>
													</div>
												)
											}
										})
									}
								</div>
							)
						}
					})}
				</span>
			)
		})
	}

	_renderContributers() {
		const {drawer__activeModalText} = this.props.store.uiStore;

		const alphaContributors = this.props.store.siteDataStore.data.contributor.sort((a, b) => (a.fields.lastName > b.fields.lastName) ? 1 : -1)

		return alphaContributors.map((contributor) => {
			return <QueryParamLink to="modal" params={{text: contributor.fields.slug, ttype: drawer__activeModalText.type}}><h5>{contributor.fields.name}</h5></QueryParamLink>
		})
	}

	render() {
		const {drawer__activeModalText} = this.props.store.uiStore;

		const links = drawer__activeModalText.type === "essay" ? this._renderEssayCategoryItems() : this._renderContributers();

		return (
			<div className="cd__menu">

				<div className="cd__menu__inner">
					{drawer__activeModalText.type === "contributor" &&
						<h3>Contributors</h3>
					}
					{links}
				</div>
			</div>
		)
	}
}

export default ContentDrawerSubMenu
